import React from 'react';
import ConsultationFlow from '../components/ConsultationWeightLoss';

const ExistingUserPage = () => {
  return (
    <div>
      <ConsultationFlow />
    </div>
  );
};

export default ExistingUserPage;
