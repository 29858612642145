import React, { useState } from "react";
import "./index.css";
import { HeaderForm } from "../../pages/consultation/HeaderForm";
import { post } from "../../services/ApiService";
import { Link } from "react-router-dom";
import { notification } from "antd";
import moment from "moment";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";

const PatientRegister = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };


  const validateField = (name, value) => {
    let newErrors = { ...errors };

    switch (name) {
      case "firstName":
      case "lastName":
        newErrors[name] = value.trim() === "" ? "This field is required." : "";
        break;

      case "email":
        newErrors.email = /^\S+@\S+\.\S+$/.test(value) ? "" : "Invalid email address.";
        break;

        case "dob":
            const cleanedValue = value.replace(/\D/g, '');
            if (!cleanedValue || cleanedValue.length < 8) {
                newErrors.dob = "Date of Birth is required.";
            } else {
                const formattedDate = moment(value, "MM/DD/YYYY").format("YYYY-MM-DD");
                if (!moment(formattedDate, "YYYY-MM-DD", true).isValid()) {
                    newErrors.dob = "Invalid date.";
                } else {
                    const age = moment().diff(moment(formattedDate, "YYYY-MM-DD"), "years");
                    newErrors.dob = age < 16 ? "You must be at least 16 years old." : "";
                }
            }
            break;
        

      case "password":
        newErrors.password = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value)
          ? ""
          : "Password must be at least 8 characters with one letter & one number.";
        break;

      case "confirmPassword":
        newErrors.confirmPassword = value === formData.password ? "" : "Passwords do not match.";
        break;

      default:
        break;
    }

    setErrors(newErrors);
  };

  const preventNonNumeric = (e) => {
    if (!/[0-9\/]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Tab") {
      e.preventDefault();
    }
  };

  const isFormValid = () => {
    return Object.values(errors).every((err) => err === "") &&
           Object.values(formData).every((val) => val !== "");
  };

  const onSubmit = async (e) => {

    e.preventDefault();
    if (!isFormValid()) return;
    const formattedDob = moment(formData.dob, "MM/DD/YYYY").startOf('day').toISOString();
    const updatedFormData = { ...formData, dob: formattedDob };
    setLoading(true);
    try {
      const response = await post("api/auth/patient/register", updatedFormData);
      if (response.success) {
        notification.success({
            message: "Signup Successful",
            description: "Signup successful! Please verify your email.",
            placement: "topRight",
        });
      } else {
        notification.error({
            message: "Signup Failed",
            description: response.error || "Signup failed. Please try again.",
            placement: "topRight", 
        });
      }
    } catch (err) {
      notification.error({
        message: "Signup Failed",
        description:"An error occurred. Please try again.",
        placement: "topRight", 
    });

    } finally {
      setLoading(false);
    }
  };

  const onBack = () => {
    // window.history.back();
    navigate('/login');
  };

  return (
    <div className="min-height">
      <HeaderForm />
      <div className="justify-content-md-center row g-3 m-0">
        <div className="col-12">
          <form className="w-form fade-in" onSubmit={onSubmit}>
            <div className="row g-4">
              <div className="col-12">
                <h5 className="mb-5">Create Your Account</h5>
                <p className="text-muted">
                  Join us to access consultations, medical history, and more.
                </p>
              </div>

              {errors.form && <p className="text-danger error-text">{errors.form}</p>}

              <div className="col-lg-12 d-flex gap-3">
                <div className="w-50">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.firstName && <p className="text-danger error-text">{errors.firstName}</p>}
                </div>

                <div className="w-50">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.lastName && <p className="text-danger error-text">{errors.lastName}</p>}
                </div>
              </div>

              <div className="col-lg-12">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                {errors.email && <p className="text-danger error-text">{errors.email}</p>}
              </div>

              <div className="col-lg-12">
                <InputMask
                    mask="99/99/9999"
                    className="form-control"
                    placeholder="MM/DD/YYYY"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    onKeyDown={preventNonNumeric}
                    required
                />
                {errors.dob && <p className="text-danger error-text">{errors.dob}</p>}
              </div>

              <div className="col-lg-12">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                {errors.password && <p className="text-danger error-text">{errors.password}</p>}
              </div>

              <div className="col-lg-12">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                />
                {errors.confirmPassword && <p className="text-danger error-text">{errors.confirmPassword}</p>}
              </div>

              <div className="col-12">
                <Link to="/login" style={{ textDecoration: "none" }}>Already have an account? Login</Link>
              </div>

              <div className="col-12 d-flex justify-content-between align-items-center">
                <button
                  type="button"
                  className="border-0 bg-transparent"
                  onClick={onBack}
                >
                  <img
                    src="/assets/images/backicon.jpg"
                    width={35}
                    alt="Back"
                  />
                </button>
                <button
                  type="submit"
                  className={`${isFormValid() ? 'btncust activebg cursor-pointer': 'btn-gray'} w-auto`}
                  disabled={!isFormValid() || loading}
                >
                  {loading ? "Please wait..." : "Signup"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PatientRegister;
