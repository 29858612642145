
import React from 'react';
import './index.css'
function TimeSlots({ selectedTimeSlot, selectedDate, handleTimeSlotSelect,timeSlots }) {
    const formatTime = (timeString) => {
        // if (timeString){
            const [hours, minutes] = timeString.split(':');
            return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
        // }
    };
 
    return (
        <>
        {timeSlots.length > 0 ? (
            <div data-testid="timeTableContainer" className="slots-container">
                {timeSlots.map(timeSlot => (
                    <button type="button"  className={`btn-slot metropill ${selectedTimeSlot === timeSlot ? "active" : ""}`}  onClick={() => handleTimeSlotSelect(timeSlot)}>{formatTime(timeSlot)}</button>
                ))}
            </div>
        ) : (
            <div className="no-time-slots-message   text-center text-dark">
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/info-icon.png`} alt="info" className="info-icon" style={{width:'25px'}} /> <span>No time slot available for <span className='metropill text-danger'>{selectedDate}</span></span>.
            </div>
        )}
      </>
    );
}

export default TimeSlots;
