import React, { useEffect, useState } from 'react'
import { Drawer } from 'antd'
import { CloseOutlined, FacebookOutlined, InstagramOutlined, SearchOutlined, TwitterOutlined } from '@ant-design/icons'
import './index.css'
import { NavLink } from 'react-router-dom'
import { get } from '../../../services/ApiService'

const MenuMobile = ({ visible, onClose }) => {

  return (
    <Drawer
      title={null}
      placement={'top'}
      onClose={onClose}
      open={visible}
      height={'100%'}
      closeIcon={false}
      className='bg-drawer'
    >
      <button className="closeicons" onClick={onClose}>
        <CloseOutlined />
      </button>
      <div >
        <div className='d-flex justify-content-center logo-mbl'>
          <NavLink to={'https://www.beta.myprivatechemist.com/'}>
            <img src="/logo.png" alt="" width={130} />
          </NavLink>
        </div>
        <div className='w-28rems'>
          <ul className='ul-menus'>
            <li>
              <NavLink to={'https://www.beta.myprivatechemist.com/'} className='text-uppercase metropill'>
                home
              </NavLink>
            </li>
            <li>
              <div class="accordion bg-transparent accordion-flush cust-accords" id="aboutaccord">
                <div class="accordion-item bg-transparent">
                  <h2 class="accordion-header" id="flush-abouthead">
                    <div className='w-100 hover-accord'>
                      <button className="accordion-button d-flex justify-content-between align-items-center w-100 collapsed bg-transparent border-0 p-0 metropill"
                        type="button" data-bs-toggle="collapse" data-bs-target="#flush-about" aria-expanded="false" aria-controls="flush-about">
                        <NavLink to={''} className='text-uppercase metropill d-block w-auto'>
                          Blood Testing
                        </NavLink>
                        <div>
                          <div className='d-flex justify-content-end plus-div'>
                            <img src="/assets/icons/plus-w.png" alt="" className='white-ic' width={10} />
                            <img src="/assets/icons/plus-p.png" alt="" className='pink-ic text-end' width={10} />
                          </div>
                          <div className='d-flex justify-content-end'>
                            <img src="/assets/icons/minus-w.png" alt="" className='minus-w-ic' width={10} />
                            <img src="/assets/icons/minus-p.png" alt="" className='minus-p-ic text-end' width={10} />
                          </div>
                        </div>
                      </button>
                    </div>
                  </h2>
                  <div id="flush-about" class="accordion-collapse collapse" aria-labelledby="flush-abouthead" data-bs-parent="#aboutaccord">
                    <div class="accordion-body py-2 pe-0">
                      <ul className='ul-menuinner m-0'>
                        <li>
                          <NavLink to={'https://www.beta.myprivatechemist.com/blood-testing/'} className='text-uppercase metropill inner-navlink' onClick={onClose}>
                            Blood Testing
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={'/a-z-tests'} className='text-uppercase metropill inner-navlink' onClick={onClose}>
                            A-Z Tests
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={'/build-my-package'} className='text-uppercase metropill nav inner-navlink' onClick={onClose}>
                            build my package
                          </NavLink>
                        </li>
                        <li>
                          <div class="accordion-item bg-transparent">
                            <h2 class="accordion-header" id="flush-healthhead">
                              <div className='hover-accord'>
                                <button className="accordion-button d-flex justify-content-between align-items-center w-100 collapsed bg-transparent border-0 p-0 metropill" type="button"
                                  data-bs-toggle="collapse" data-bs-target="#flush-health" aria-expanded="false" aria-controls="flush-health">
                                  <NavLink to={''} className='text-uppercase metropill d-block w-auto inner-navlink cursor-auto'>
                                    health concern
                                  </NavLink>
                                  <div>
                                    <div className='d-flex justify-content-end plus-div'>
                                      <img src="/assets/icons/plus-w.png" alt="" className='white-ic' width={10} />
                                      <img src="/assets/icons/plus-p.png" alt="" className='pink-ic text-end' width={10} />
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                      <img src="/assets/icons/minus-w.png" alt="" className='minus-w-ic' width={10} />
                                      <img src="/assets/icons/minus-p.png" alt="" className='minus-p-ic text-end' width={10} />
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </h2>
                            <div id="flush-health" class="accordion-collapse collapse" aria-labelledby="flush-healthhead" data-bs-parent="#healthaccord">
                              <div class="accordion-body py-2">
                                <ul className='ul-menuinner m-0'>
                                  <li>
                                    <NavLink to={'/a-z-tests?category=general-health'} className='text-uppercase metropill innermost-link' onClick={onClose}>
                                      general health tests
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to={'/a-z-tests?category=mens-health'} className='text-uppercase metropill innermost-link' onClick={onClose}>
                                      mens health
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to={'/a-z-tests?category=mood'} className='text-uppercase metropill innermost-link' onClick={onClose}>
                                      mood tests
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to={'/a-z-tests?category=nutrition'} className='text-uppercase metropill innermost-link' onClick={onClose}>
                                      nutrition
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to={'/a-z-tests?category=sexual-health-fertility'} className='text-uppercase metropill innermost-link' onClick={onClose}>
                                      sexual health/fertility
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to={'/a-z-tests?category=sports-fitness'} className='text-uppercase metropill innermost-link' onClick={onClose}>
                                      sports/fitness
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to={'/a-z-tests?category=tiredness-fatigue'} className='text-uppercase metropill innermost-link' onClick={onClose}>
                                      tiredness & fatigue tests
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to={'/a-z-tests?category=weight'} className='text-uppercase metropill innermost-link' onClick={onClose}>
                                      weight
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink to={'/a-z-tests?category=womens-health'} className='text-uppercase metropill innermost-link' onClick={onClose}>
                                      women's health
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <NavLink to={'https://www.beta.myprivatechemist.com/travel-clinic/'} className='text-uppercase metropill'>
                travel clinic
              </NavLink>
            </li>
            {/* <li>
                  <NavLink to={''} className='text-uppercase metropill'>
                    treatment & medication
                  </NavLink>
                </li> */}
            {/* <li>
                  <NavLink to={''} className='text-uppercase metropill'>
                    our mission
                  </NavLink>
                </li> */}
            <li>
              <NavLink to={'/weight-loss'} className='text-uppercase metropill'>
                Weight Loss
              </NavLink>
            </li>
            <li>

              <NavLink to={'https://www.beta.myprivatechemist.com/contact/'} className='text-uppercase metropill'>
                contact
              </NavLink>
            </li>
          </ul>
          {/* <div class="input-group custom-search mb-3">
                <input type="text" className="form-control border-0 p-0 fs-16 bg-transparent" placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                <span className="input-group-text border-0 p-0 bg-transparent" id="basic-addon2">
                  <SearchOutlined />
                </span>
              </div> */}
          <div className='d-flex align-items-center justify-content-center gap-3 pt-4'>
            <NavLink to={''} className='social-icons'>
              <FacebookOutlined />
            </NavLink>
            <NavLink to={''} className='social-icons'>
              <TwitterOutlined />
            </NavLink>
            <NavLink to={''} className='social-icons'>
              <InstagramOutlined />
            </NavLink>
            {/* <div className="cart-nav-1">
                    <span className='counterCart'>
                      <span>{counter}</span>
                    </span>
                    <NavLink to="/cart" >
                      <img src="/assets/icons/cart.png" style={{filter:'invert(1)'}} className='cartone' width={24} alt="" />
                    </NavLink>
                  </div> */}
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default MenuMobile