import React, { useState, useEffect } from "react";
import "./index.css";
import { HeaderForm } from "../../pages/consultation/HeaderForm";
import { post } from "../../services/ApiService";
import { Link, useLocation, useHistory } from "react-router-dom";
import PageLoader from "../../components/loader/PageLoader";


const VerifyEmail = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const location = useLocation();

  // Get the token from the URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (token) {
      handleVerifyEmail(token);
    }
  }, [token]);

  const handleVerifyEmail = async (token) => {
    setLoading(true);
    setError("");

    try {
      const response = await post("api/auth/verify-email", { token });
      if (response.success) {
        setIsVerified(true);
      } else {
        setError(response.error || "Verification failed.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await post("api/auth/resend-verification", { email: "user@example.com" }); // Replace with actual email if needed
      if (response.success) {
        alert("Verification email resent successfully!");
      } else {
        setError(response.error || "Failed to resend email.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <PageLoader />}
      <div className="min-height">
        <HeaderForm />
        <div className="justify-content-md-center row g-3 m-0">
          <div className="w-form fade-in">
          <div className="col-12">
            {isVerified ? (
              <>
                <h5 className="mb-5 text-success">Email Verified Successfully!</h5>
                <p className="text-muted">
                  Your email has been successfully verified. You can now log in to access your consultations and services.
                </p>
                <div className="col-12">
                <Link to="/login" className="btncust activebg cursor-pointer ">
                  Go to Login
                </Link>
                </div>

              </>
            ) : (
              <>
                <h5 className="mb-5 text-danger">Verification Failed!</h5>
                <p className="text-muted">
                  There was an issue with verifying your email. Please check the link or try again later.
                </p>
                {error && <p className="text-danger">{error}</p>}
                {/* <button className="btn btncust" onClick={handleResendVerification}>
                  Resend Verification Email
                </button> */}
              </>
            )}
          </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
