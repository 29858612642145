import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './index.css';
import Spinner from 'react-bootstrap/Spinner';
import { loadStripe } from '@stripe/stripe-js'; // Properly import loadStripe
import {  Elements } from '@stripe/react-stripe-js';
import { post } from '../../services/ApiService.js';
import PaymentComponent from './PaymentComponent';
import Modal from 'react-bootstrap/Modal';

function OrderSummary({
  handleSubmit,
  cartItems,
  subtotal,
  totalDiscount,
  total,
  isSubmit,
  isBloodDraw,
  selectedDate,
  selectedTimeSlot,
  validateCheckoutForm
}) {
 
  return (
    <>
      <div className='card order-summary'>
        <div className='card-body'>
          {/* Order details */}
          <div className='row'>
            <div className='col-12'>
              <h4 style={{ marginBottom: '20px' }}>Order Summary</h4>
            </div>
            <div className='col-4 text-dark mb-4'>Date:</div>
            <div className='col-8 mb-4  text-body-emphasis text-end metropill' >
              {selectedDate} {selectedTimeSlot}
            </div>
            <div className='col-4 mb-4 text-dark'>Provider:</div>
            <div className='col-8 mb-4  text-body-emphasis text-end metropill'>
              My Private Chemist<br/> 
              Longcraft House, 2-8 Victoria<br/>
              EC2M 4NS
            </div>
          </div>
          <hr />
          {/* Cart items */}
          {cartItems.length > 0 && (
            <>
              {cartItems.map((item, index) => (
                <div className='d-flex flex-grow-1' key={index}>
                  <div className="pe-6 theme-font text-dark">
                    * {item.name} 
                  </div>
                  <div className="ms-auto">
                    <p className="fs-14px text-body-emphasis mb-0 fw-normal theme-font">
                      £{item.price}
                    </p>
                  </div>
                </div>
              ))}
              <hr />
            </>
          )}
          {/* Subtotal */}
          <div className="d-flex align-items-center mb-2 theme-font">
            <span className="theme-font  text-dark">Subtotal:</span>
            <span className="d-block ms-auto text-body-emphasis fw-normal">
              £{subtotal}
            </span>
          </div>
          {/* Blood Draw fee (if applicable) */}
          {isBloodDraw && (
            <div className="d-flex align-items-center mb-2 theme-font">
              <span className="theme-font  text-dark">Blood Draw fee:</span>
              <span className="d-block ms-auto text-body-emphasis fw-normal ">
                £30
              </span>
            </div>
          )}
        </div>
        {/* Total */}
        <div className="card-footer bg-transparent py-5 px-0 mx-10">
          <div className="d-flex align-items-center fw-bold mb-6">
            <span className="text-body-emphasis p-0 theme-font fw-bold" style={{ fontSize: '20px' }}>
              Total price:
            </span>
            <span className="d-block ms-auto text-body-emphasis fw-bold " style={{ fontSize: '20px' }}>
              £{total}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderSummary;
