import React, { useEffect, useState, useRef } from "react";
import { CardItem } from "./CardItem.jsx";
import { DrawerBiomarker } from "./BiomarkerDrawer.jsx";
import { get, post } from '../../../services/ApiService.js';
import Slider from "react-slick";

const TabAzTest = ({paramCategory}) => {

  const [biomarkerData, setBiomarkers] = useState([]);
  const [isNotFound, setNotFound] = useState(false);
  const [data, setData] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [ activeCategory, setActiveCategory ] = useState("0");
  const sliderRef = useRef(null); 
    const tabContentRef = useRef(null);
  

  var tabsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    initialSlide: 0,
    centerMode: false,
    autoplay: false,
    arrows: true,
    swipeToSlide: true,
    swipe: true,
    draggable: true,
    touchMove: true,        // Enable touch movement
    touchThreshold: 5,      // Reduced threshold to make it more sensitive
    useCSS: true,
    useTransform: true,
    waitForAnimate: false,  // Don't wait for animation to finish before responding to swipe
    pauseOnHover: true,     // Pause autoplay on hover
    accessibility: true,    // Enable keyboard navigation
    nextArrow: (
      <div>
        <img src="/assets/icons/next.png" width={25} alt="next" />
      </div>
    ),
    prevArrow: (
      <div>
        <img src="/assets/icons/prev.png" width={25} alt="prev" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };


  useEffect(() => {
    const loadData = async () => {
      setIsLoad(true);
      try {
        await fetchData();
        await fetchBiomarkers();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoad(false);
      }
    };


    loadData();
    
  }, []);



  const fetchBiomarkers = async () => {
    try {
      const res = await get("api/blood-tests/biomarkers");
      if (res.success) {
        setBiomarkers(res.biomarkers);
      } else {
        setBiomarkers([]);
      }
    } catch (error) {
      setBiomarkers([]);
    }
  };

  const fetchData = async () => {
    try {
      setNotFound(false);
      const res = await get("api/blood-tests/all");
      console.log(res);
      if (res.success) {
        setData(res.data); 
      } else {
        setData([]); 
        setNotFound(true);
      }
    } catch (error) {
      setData([]);
      setNotFound(true);
    }
  };



  const categoryTab = [
    {
      _id: "0",
      imgdef:'alltest-b.png',
      imgactive:'alltest-w.png',
      heading: 'All Tests',
      slug: 'all',
      description: "A comprehensive list of all the blood tests we offer, organised alphabetically. It allows you to easily browse through and search available tests to help select the one that best suits your needs. Use the filter to search by all blood tests or by health categories above. If you can not find a test you are looking for, please contact us - we may be able to offer it!",
      description_2: "A biomarker is a specific indicator in your blood, while a profile combines multiple biomarkers to provide a broader, more comprehensive test."
    },
    {
      _id: "67ab62a57f2a122e8bd55ca2",
      imgdef:'heartpulse-b.png',
      imgactive:'heartpulse-w.png',
      heading: 'General Health Tests',
      slug: "general-health",
      description: "Comprehensive blood tests to assess overall health, including vital organ function and key markers."
    },
    {
      _id: "67ab62a57f2a122e8bd55ca3",
      imgdef:'user-b.png',
      imgactive:'user-w.png',
      heading: 'Mens Health',
      slug: "mens-health",
      description: "Blood tests designed to monitor testosterone levels, prostate health, and overall well-being for men."
    },
    {
      _id: "67ab62a57f2a122e8bd55ca4",
      imgdef:'smile-b.png',
      imgactive:'smile-w.png',
      heading: 'Mood Tests',
      slug: "mood",
      description: "Tests to check hormone and vitamin levels that impact mood, stress, and mental well-being."
    },
    {
      _id: "67ab62a57f2a122e8bd55ca5",
      imgdef:'apple-b.png',
      imgactive:'apple-w.png',
      heading: 'Nutrition',
      slug: "nutrition",
      description: "Assess key vitamins, minerals, and nutrients to ensure optimal dietary balance and health."
    },
    {
      _id: "67ab62a57f2a122e8bd55ca6",
      imgdef:'hold-b.png',
      imgactive:'hold-w.png',
      heading: 'Sexual Health/Fertility',
      slug: "sexual-health-fertility",
      description: "Blood tests to check hormone levels, reproductive health, and fertility status for men and women."
    },
    {
      _id: "67ab62a57f2a122e8bd55ca7",
      imgdef:'fitness-b.png',
      imgactive:'fitness-w.png',
      heading: 'Sports/Fitness',
      slug: "sports-fitness",
      description: "Tests to track fitness levels, muscle recovery, and key health markers for performance optimization."
    },
    {
      _id: "67ab62a67f2a122e8bd55ca8",
      imgdef:'tired-b.png',
      imgactive:'tired-w.png',
      heading: 'Tiredness & Fatigue Tests',
      slug: "tiredness-fatigue",
      description: "Identify possible causes of low energy, including iron levels, thyroid function, and vitamin deficiencies."
    },
    {
      _id: "67ab62a67f2a122e8bd55ca9",
      imgdef:'weight-b.png',
      imgactive:'weight-w.png',
      heading: 'Weight',
      slug: "weight",
      description: "Check hormone and metabolic markers that influence weight gain, loss, and overall metabolism."
    },
    {
      _id: "67ab62a67f2a122e8bd55caa",
      imgdef:'women-b.png',
      imgactive:'women-w.png',
      heading: "Women's Health",
      slug: "womens-health",
      description: "Comprehensive tests for hormonal balance, reproductive health, and overall well-being in women."
    },
  ]

  
  useEffect(() => {
    if (paramCategory) {
      const foundCategory = categoryTab.find((cat) => cat.slug === paramCategory);
      if (foundCategory) {
        setActiveCategory(foundCategory._id);
      }
    }else{
      setActiveCategory("0")
    }
  }, [paramCategory]);

  useEffect(() => {
    if (sliderRef.current) {
      const activeIndex = categoryTab.findIndex((cate) => cate._id === activeCategory);
      if (activeIndex !== -1) {
        sliderRef.current.slickGoTo(activeIndex); // Scroll to active category
      }
    }
  }, [activeCategory]);

    useEffect(() => {
      if (activeCategory && activeCategory !== "0") {
        if (tabContentRef.current) {
          tabContentRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [activeCategory]);

  return (
    <div className="pt-lg-17 pt-10" ref={tabContentRef}>
      <ul
        className="nav-pills mb-3 w-100 pill-cust hoverarrow p-0"
        id="pills-tab"
        role="tablist"
      >
        <div >
          <Slider ref={sliderRef} {...tabsSlider}>
            {
              categoryTab?.map((cate,index)=>
              <li className="nav-item flex-grow-1 slickli h-100" role="presentation" key={index}>
                <button
                  className={`nav-link w-100 d-flex flex-column align-items-center ${activeCategory === cate._id ? 'active': ''}`}
                  type="button"
                  onClick={() =>  setActiveCategory(cate._id)}
                >
                  <span>
                    <img
                      src={"/assets/icons/"+cate?.imgdef}
                      className="icon-b"
                      width={30}
                      alt=""
                    />
                    <img
                      src={"/assets/icons/"+cate?.imgactive}
                      className="icon-w"
                      width={30}
                      alt=""
                    />
                  </span>
                  <span className="metropill pt-5 pb-3">{cate?.heading}</span>
                </button>
              </li>
              )
            }
          </Slider>
        </div>
      </ul>
      <div className="tab-content">
        <div>
          <CardItem 
            bloodTests={data}
            biomarkerData={biomarkerData}
            isNotFound={isNotFound} 
            isLoad={isLoad}
            categories={categoryTab}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        </div>
      </div>
      
    </div>
  );
};

export { TabAzTest };
