import React from 'react'
import { NavLink } from 'react-router-dom'
import './index.css'

const NewFooter = () => {

    const data = [
        {
            title: 'Contact',
            id: 1,
            content: [
                {
                    icon: "my-pin.jpg",
                    desc: "Longcroft House,<br> 2-8 victoria Avenue<br> London, EC2M 4NS",
                    isHTML: true
                },
                {
                    icon: "my-mail.jpg",
                    desc: '<a href="mailto:info@myprivatechemist.com">info@myprivatechemist.com</a>',
                    isHTML: true
                },
                {
                    icon: "my-phone.jpg",
                    desc: '<a href="tel:0203 987 9202">0203 987 9202</a>',
                    isHTML: true
                },
            ]
        },
        {
            title: 'Quick Links',
            id: 2,
            content: [
                // {
                //     path:'',
                //     desc:"Home"
                // },
                // {
                //     path:'',
                //     desc:"Our Mission"
                // },
                {
                    path: 'https://www.beta.myprivatechemist.com/blood-testing/',
                    desc: "Blood Testing"
                },
                {
                    path: '/build-my-package/',
                    desc: "Build My Package"
                },
                {
                    path: '/a-z-tests/',
                    desc: "A-Z Test"
                },
                {
                    path: 'https://www.beta.myprivatechemist.com/contact/',
                    desc: "Contact Us"
                },
                {
                    path: '/weight-loss/',
                    desc: "Weight Loss"
                },
                {
                    path:'https://www.beta.myprivatechemist.com/travel-clinic/',
                    desc:"Travel Clinic"
                },
                // {
                //     path:'',
                //     desc:"Treatment & Medication"
                // },
                // {
                //     path:'',
                //     desc:"Consultation"
                // },
                // {
                //     path:'',
                //     desc:"GP Services"
                // },
                // {
                //     path:'',
                //     desc:"Health Checks"
                // },
                // {
                //     path:'',
                //     desc:"Corporate"
                // },
                // {
                //     path:'',
                //     desc:"Sexual Health"
                // },
            ]
        },
        {
            title: 'Opening',
            id: 3,
            content: [
                {
                    desc: "Monday 9:00 - 17:00"
                },
                {
                    desc: "Tuesday 9:00 - 17:00"
                },
                {
                    desc: "Wednesday 9:00 - 17:00"
                },
                {
                    desc: "Thursday 9:00 - 17:00"
                },
                {
                    desc: "Friday Closed"
                },
                {
                    desc: "Saturday Closed"
                },
                {
                    desc: "Sunday Closed"
                },
            ]
        },
    ]

    const socialdata = [
        {
            title: 'Follow Us or Contact',
            content: [
                {
                    path: 'https://www.facebook.com/myprivatechemist',
                    img: 'my-fb.jpg'
                },
                {
                    path: 'https://www.linkedin.com/company/my-private-chemist/?originalSubdomain=uk',
                    img: 'my-tw.png'
                },
                {
                    path: 'https://www.instagram.com/myprivatechemist/',
                    img: 'my-insta.jpg'
                },
            ]
        },
        // {
        //     title: 'Payment Accepted',
        //     id: 2,
        //     content: [
        //         {
        //             path: '',
        //             img: 'my-visa.jpg'
        //         },
        //         {
        //             path: '',
        //             img: 'my-card.jpg'
        //         },
        //     ]
        // },

    ]
    const cardsdata = [
        {
            title: 'Payment Accepted',
            id: 2,
            content: [
                {
                    img: 'my-visa.jpg'
                },
                {
                    img: 'my-card.jpg'
                },
            ]
        },

    ]
    return (
        <div className='footer-new'>
            <div className='container'>
                <div className='row g-3'>
                    {
                        data?.map((data, index) =>
                            <div className={data?.id === 2 ? 'col-xl-6 col-lg-4' : 'col-xl-3 col-lg-4'} key={index}>
                                <div className={
                                    data?.id === 2 ? 'mar-list' : null
                                }>
                                    <h4 className='fw-bold title metropill footer-title'>{data?.title}</h4>
                                    {
                                        data.id === 1 ? (
                                            data?.content?.map((cont, i) => (
                                                <div className="d-flex gap-3 align-items-start mb-3" key={i}>
                                                    <div className="flex-shrink-0 d-flex pt-3">
                                                        <img
                                                            src={`/assets/icons/${cont?.icon}`}
                                                            width={16}
                                                            alt=""
                                                            className="img-fluid img-mails"
                                                        />
                                                    </div>
                                                    <p className="m-0 metropill desc desc-new" dangerouslySetInnerHTML={{ __html: cont?.desc }}></p>
                                                </div>
                                            ))
                                        )
                                            :
                                            data.id === 2 ? (
                                                <div className="list">
                                                    <ul>
                                                        {data?.content?.map((cont, i) => (
                                                            <li key={i}>
                                                                <NavLink to={cont?.path}>
                                                                    <div className="d-flex gap-10p align-items-start mb-3">
                                                                        <div className="flex-shrink-0 d-flex pt-3">
                                                                            <img
                                                                                src="/assets/icons/my-plus.png"
                                                                                width={16}
                                                                                alt=""
                                                                                className="img-fluid"
                                                                            />
                                                                        </div>
                                                                        <p className="m-0 metropill desc link-new">{cont?.desc}</p>
                                                                    </div>
                                                                </NavLink>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ) : (
                                                <div>
                                                    {data?.content?.map((cont, i) => (
                                                        <p className="m-0 metropill desc desc-new">{cont?.desc}</p>
                                                    ))}
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        )
                    }

                </div>
                <div className='row mt-5 pt-5'>
                    <div className='col-lg-4 col-xl-3'>
                        <div className='reg-area'>
                            <p className='metropill fs-13 mb-3'>
                                <strong className='metropill fw-bold'>Company: </strong>
                                MI Health Ltd
                            </p>
                            <p className='metropill fs-13 mb-3'>
                                <strong className='metropill fw-bold'>Premisess GPhC Number: </strong>
                                9011548
                            </p>
                            <p className='metropill fs-13 mb-3'>
                                <strong className='metropill fw-bold'>Superintendent: </strong>
                                Imran Tailor (221614)
                            </p>
                            <img src="/assets/icons/gen.png" width={146} className='img-fluid' alt="" />
                        </div>
                    </div>
                    <div className='col-lg-4 col-xl-6 my-auto'>
                        <div className='d-flex justify-content-center my-5'>
                            <NavLink to={''}>
                                <img src="/logo-footer.png" className='img-fluid logo-footer' width={180} height={87} alt="" />
                            </NavLink>
                        </div>
                    </div>
                    <div className='col-lg-4 col-xl-3'>
                        {
                            socialdata?.map((social, i) =>
                                <div className='mb-5 social-icons' key={i}>
                                    <h4 className='fw-bold mt-4 mb-5 title footer-title metropill'>
                                        {social?.title}
                                    </h4>
                                    <div className='d-flex align-items-center gap-3 justify-content-end py-4'>
                                        {
                                            social?.content?.map((listsocial, a) =>
                                                <NavLink to={listsocial?.path} key={a} target='_blank'>
                                                    <img src={'/assets/icons/' + listsocial?.img} width={social?.id === 2 ? 33 : 18} />
                                                </NavLink>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            cardsdata?.map((payment, i) =>
                                <div className='mb-5 social-icons' key={i}>
                                    <h4 className='fw-bold mt-4 mb-5 title footer-title metropill'>
                                        {payment?.title}
                                    </h4>
                                    <div className='d-flex align-items-center gap-3 justify-content-end py-4'>
                                        {
                                            payment?.content?.map((listpayment) =>
                                                <span>
                                                    <img src={'/assets/icons/' + listpayment?.img} width={payment?.id === 2 ? 33 : 18} />
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
                <div className="row">
                    <div className='col-lg-6'>
                        <div className='text-start'>
                            <p className='m-0 metropill copy-right-para'>
                                Copyright © by MPC 2025. All rights reserved.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-6 my-auto'>
                        <div className='text-end footer-links'>
                            <ul className='metropill d-flex justify-content-end align-items-center'>
                                <li> <NavLink className='metropill' to="https://www.beta.myprivatechemist.com/about/">About</NavLink></li>
                                <li><NavLink className='metropill' to="https://www.beta.myprivatechemist.com/complaints/">Complaints</NavLink></li>
                                <li><NavLink className='metropill' to="https://www.beta.myprivatechemist.com/cookie-policy/">Cookie Policy</NavLink></li>
                                <li><NavLink className='metropill' to="https://www.beta.myprivatechemist.com/privacy-policy/">Privacy Policy</NavLink></li>
                                <li><NavLink className='metropill' to="https://www.beta.myprivatechemist.com/terms-and-conditions/">Terms and Conditions</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { NewFooter }