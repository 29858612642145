import React, { Component, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import  './index.css'
import MobileMenu from './MobileMenu';
import { get } from '../../services/ApiService';

const Navbar = () => {

    const [ visible, setVisible ] = useState(false);
    const [ counter, setCounter ] = useState(0);



    useEffect(() => {
        const navbar = document.getElementsByClassName("chemist-navbar")[0];
        const navbarLeft = document.getElementsByClassName("chemist-navbar-left");
        const navbarSmall = document.getElementsByClassName("chemist-navbar-small")[0];

        const responsive = () => {
            // Add responsive logic here if necessary
        };

        responsive();
        window.addEventListener("resize", () => {
            responsive();
            scrol();
        });

        let upDown;

        const scrol = () => {
  
            if (window.innerWidth > 970) {
                if (window.pageYOffset > upDown) {
                    navbar.setAttribute("class", "chemist-navbar animation chemist-navbar-move");
                } else if (window.pageYOffset < 11) {
                    navbar.setAttribute("class", "chemist-navbar animation bg-transparent");
                } else {
                    navbar.setAttribute("class", "chemist-navbar animation secondary-bg chemist-navbar-top0");
                }
            } else {
                navbar.setAttribute("class", "chemist-navbar");
                if (window.pageYOffset > upDown) {
                    navbarSmall.setAttribute("class", "chemist-navbar-small secondary-bg animation chemist-navbar-small-move");
                } else if (window.pageYOffset < 11) {
                    navbarSmall.setAttribute("class", "chemist-navbar-small transparent-bg animation");
                } else {
                    navbarSmall.setAttribute("class", "chemist-navbar-small secondary-bg animation");
                }
            }
            upDown = window.pageYOffset;
        };

        scrol();
        window.onscroll = function() { scrol(); };

        return () => {
            window.removeEventListener("resize", () => {
                responsive();
                scrol();
            });
            window.onscroll = null;
        };
    }, []);

    useEffect(()=>{
        CartCounter();
    },[])

    const CartCounter = async () => {

        try {
            const res = await get(`api/cart/count`);
            if (res.success) {
                setCounter(res.count)
            } 
        } catch (error) {

            console.error(error.message);
        }
      };

        
        return  (
            <>
           <div  className='chemist-navbar-container' style={{position:'relative'}}>
                <div className='w-100'>
                    <div className="chemist-navbar-small secondary-bg">
                        <div className="chemist-navbar-inner">
                            <div className='chemist-navbar-left'><img src={'/logo.png'} width={'93px'} alt=''/></div>
                            <div className="chemist-navbar-right">
                                <div className="sp-cover" onClick={()=>setVisible(true)}>
                                    <span className="sp"></span>
                                </div>
                            <span className='cart_counter_mob counterCart'>{counter}</span>

                                <NavLink to="/cart" className="cart-nav">
                                    <img src="/assets/images/header/cart-ic.png" className='cartone' width={24} alt="" />
                                    <img src="/assets/images/header/cart-ic-hover.png" className='carttwo' width={24} alt="" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='chemist-navbar'>
                    <div className="chemist-navbar-inner">
                        <div className='chemist-navbar-left'>
                            <img src='/logo.png' width={'93px'} preview={false} className='one' alt=''/>
                            <img src='/assets/close.png' preview={false} className='two' alt=''/>
                        </div>
                        <ul className="chemist-navbar-middle" style={{height:'100%'}}>
                            <li className='items'>
                                <NavLink to="/" className="nav-item">
                                    <span className='item-name metropill'>HOME</span>
                                </NavLink>
                            </li>
                            <li className='items'>
                                <NavLink to="https://www.beta.myprivatechemist.com/blood-testing" className="nav-item">
                                    <span className='item-name metropill'>BLOOD TESTING</span>
                                </NavLink>
                            </li>
                             <li className='items'>
                                <NavLink to="https://www.beta.myprivatechemist.com/travel-clinic/" className="nav-item">
                                    <span className='item-name metropill'>TRAVEL CLINIC</span>
                                </NavLink>
                            </li>
                          {/*}  <li className='items first-hide'>
                                <NavLink to="https://www.beta.myprivatechemist.com/treatment-medication" className="nav-item">
                                    <span className='item-name metropill'>TREATMENT MEDICATION</span>
                                </NavLink>
                            </li> */}
                            <li className='items first-hide'>
                                <NavLink to="https://www.beta.myprivatechemist.com/contact/" className="nav-item">
                                    <span className='item-name metropill'>CONTACT</span>
                                </NavLink>
                            </li>
                            {/* <li className='items second-hide'>
                                <NavLink to="/" className="nav-item">
                                    <span className='item-name metropill'>OUR MISSION</span>
                                </NavLink>
                            </li> */}

                            {/* <li className='items drop-none'>
                                <div class="dropdown pe-5">
                                    <button class="bg-transparent border-0 p-0 dropdown-b" type="button" data-bs-toggle="dropdown">
                                        <img src="/assets/images/header/drop-dots.png" width={'22px'} alt="" />
                                    </button>
                                    <ul class="dropdown-menu bg-dark nav-item">
                                        <li className='d-xl-none'>
                                            <a href="#" className='text-white text-capitalize text-decoration-none fw-normal fs-12'>
                                                treatment
                                            </a>
                                        </li>
                                        <li className='d-xl-none'>
                                            <a href="#" className='text-white text-capitalize text-decoration-none fw-normal fs-12'>
                                                medication
                                            </a>
                                        </li>
                                        <li className='d-xl-none'>
                                            <a href="#" className='text-white text-capitalize text-decoration-none fw-normal fs-12'>
                                                our mission
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className='text-white text-capitalize text-decoration-none fw-normal fs-12'>
                                                contact
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li> */}
                            <li className='items'>
                                <span className='cart_counter counterCart'>{counter}</span>
                                <NavLink to="/cart" className="cart-nav">
                                    <img src="/assets/images/header/cart-ic.png" className='cartone' width={24} alt="" />
                                    <img src="/assets/images/header/cart-ic-hover.png" className='carttwo' width={24} alt="" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <MobileMenu 
                visible={visible}
                onClose={()=>setVisible(false)}
            />
        </>
        )
    }
export default Navbar;
