import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const WeightLossGraph = ({ currentWeight, bmiCategory }) => {
    const targetWeight = Math.round(currentWeight * 0.8); // Round to whole number
    const months = ['Now', '3m', '6m', '9m', '1yr'];
    
    const weightPoints = [
        currentWeight,
        Math.round(currentWeight * 0.95),
        Math.round(currentWeight * 0.90),
        Math.round(currentWeight * 0.85),
        targetWeight
    ];

    const data = {
        labels: months,
        datasets: [
            {
                label: 'Projected Weight Loss',
                data: weightPoints,
                borderColor: '#357550',
                backgroundColor: 'rgba(53, 117, 80, 0.1)',
                tension: 0.4,
                fill: true,
                pointRadius: 6,
                pointHoverRadius: 8
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                backgroundColor: '#202f32',
                titleFont: {
                    size: 14,
                    family: "'metropillthin', sans-serif"
                },
                bodyFont: {
                    size: 14,
                    family: "'metropillthin', sans-serif"
                },
                callbacks: {
                    label: (context) => `Weight: ${context.parsed.y}kg` // Removed .toFixed(1)
                }
            }
        },
        scales: {
            y: {
                beginAtZero: false,
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                },
                ticks: {
                    stepSize: 10, // This will show whole numbers with steps of 10
                    callback: (value) => `${Math.round(value)}kg`, // Round to whole numbers
                    font: {
                        family: "'metropillthin', sans-serif",
                        size: 12
                    }
                }
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: "'metropillthin', sans-serif",
                        size: 12
                    }
                }
            }
        }
    };

    return (
        <div className="graph-wraps">
            <h3 className="graph-title fs-21">Your Weight Loss Journey</h3>
            <p className="graph-subtitle metropill">Projected weight loss over 12 months</p>
            
            <div className="weight-target">
                <div className="weight-stat">
                    <div className="weight-label metropill">Current Weight</div>
                    <div className="weight-value metropill">{Math.round(currentWeight)}kg</div>
                </div>
                <div className="weight-stat">
                    <div className="weight-label metropill">Target Weight</div>
                    <div className="weight-value target">{targetWeight}kg</div>
                </div>
            </div>

            <div className="graph-container">
                <Line data={data} options={options} />
            </div>

            <div className="graph-legend">
                {/* <div className="legend-item">
                    <span className="legend-color current"></span>
                    <span className="legend-text">Current Weight</span>
                </div> */}
                <div className="legend-item">
                    <span className="legend-color target"></span>
                    {/* <span className="legend-text">Target Weight</span> */}
                    <span className="legend-text">Your Weight Loss Journey</span>
                </div>
            </div>
        </div>
    );
};

export default WeightLossGraph; 