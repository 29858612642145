
import React from 'react';
import './index.css';
import DatePicker from 'react-datepicker';
function BillingDetail({ formData, handleInputChange, inputErrors, isOver18,validateAge,firstErrorRef }) {

 
    return (
        <>
            <div className='row mb-4'>
                <div className='col-lg-6 col-md-6 col-sm-12 input-section'>
                    <label className='fw-normal metropill'>First name <span className='text-danger'>*</span></label>
                    <input type='text' className={`input_text ${inputErrors.firstname ? 'error-border':''}`} name='firstname' placeholder='Enter your first name'  
                        value={formData.firstname} onChange={handleInputChange} ref={(el) => {firstErrorRef.current = {...firstErrorRef.current,firstname: el};}} 
                    />
                    {inputErrors.firstname &&
                        <span className="text-danger" style={{fontSize:'10px'}}>
                        <i class="far fa-circle-exclamation"></i> This field is required
                        </span>
                    }
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 input-section'>
                    <label className='fw-normal metropill'>Last name <span className='text-danger'>*</span></label>
                    <input type='text' className={`input_text ${inputErrors.lastname ? 'error-border':''}`} name='lastname' placeholder='Enter your last name' 
                        value={formData.lastname} onChange={handleInputChange} ref={(el) => {firstErrorRef.current = {...firstErrorRef.current,lastname: el};}} 
                    />
                    {inputErrors.lastname &&
                        <span className="text-danger" style={{fontSize:'10px'}}>
                        <i class="far fa-circle-exclamation"></i> This field is required
                        </span>
                    }
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 input-section'>
                    <label className='fw-normal metropill'>Email address <span className='text-danger'>*</span></label>
                    <input type='email' className={`input_text ${inputErrors.email ? 'error-border':''}`} name='email' placeholder='example@example.com' 
                        value={formData.email} onChange={handleInputChange} ref={(el) => {firstErrorRef.current = {...firstErrorRef.current,email: el};}} />
                        {inputErrors.email &&
                            <span className="text-danger" style={{fontSize:'10px'}}>
                            <i class="far fa-circle-exclamation"></i> {inputErrors.email}
                            </span>
                        }
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 input-section'>
                    <label className='fw-normal metropill'>Phone number <span className='text-danger'>*</span></label>
                    <input type='number' className={`input_text ${inputErrors.phone ? 'error-border':''}`} name='phone' placeholder='Enter phone number' 
                        value={formData.phone} onChange={handleInputChange} ref={(el) => {firstErrorRef.current = {...firstErrorRef.current,phone: el};}} />
                    {inputErrors.phone &&
                        <span className="text-danger" style={{fontSize:'10px'}}>
                        <i class="far fa-circle-exclamation"></i> {inputErrors.phone}
                        </span>
                    }
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 input-section'>
                    <label className='fw-normal metropill'>Gender <span className='text-danger'>*</span></label>
                    <select className={`input_select ${inputErrors.gender ? 'error-border':''}`} name="gender" value={formData.gender} onChange={handleInputChange}>
                        <option val="" >Choose gender</option>
                        <option val="Male">Male</option>
                        <option val="Female">Female</option>
                    </select>
                    {inputErrors.gender &&
                        <span className="text-danger" style={{fontSize:'10px'}}>
                        <i class="far fa-circle-exclamation"></i> This field is required
                        </span>
                    }
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 input-section'>
                    <label className='fw-normal metropill'>Date of birth <span className='text-danger'>*</span></label>
                    <input type='date' className={`input_text ${inputErrors.dob ? 'error-border':''}`} value={formData.dob} name='dob' onChange={validateAge} placeholder='dd/mm/yyyy'  ref={(el) => {firstErrorRef.current = { ...firstErrorRef.current,dob: el};}}/>
                    {inputErrors.dob &&
                        <span className="text-danger" style={{fontSize:'10px'}}>
                        <i class="far fa-circle-exclamation"></i> This field is required
                        </span>
                    }
                    {!isOver18 &&
                        <p className="text-danger">You must be over 18 years old to proceed.</p>
                    }
                </div>
            </div>

        </>
    );
}

export default BillingDetail;
