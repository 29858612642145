import React from 'react';
import './index.css';
import { HeaderForm } from '../../pages/consultation/HeaderForm';

const SignUpForm = ({ formData, handleInputChange, onBack, onSubmit }) => {
  return (
    <div className="min-height">
      <HeaderForm />
      <div className="justify-content-md-center row g-3 m-0">
        <div className="col-12">
    <form className="w-form fade-in" onSubmit={onSubmit}>
      <div className="row g-4">
        <div className="col-12">
          <h5 className="mb-5">Let us know a bit about you.</h5>
        </div>
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control metropill"
            placeholder="Full Name *"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-lg-6">
          <input
            type="email"
            className="form-control metropill"
            placeholder="Email Address *"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-lg-12">
          <input
            type="tel"
            className="form-control metropill"
            placeholder="Phone Number *"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="col-lg-12">
          <textarea
            className="form-control metropill"
            placeholder="Address *"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            rows="3"
            required
          ></textarea>
        </div>
        <div className="col-12 d-flex justify-content-between align-items-center">
          <button 
            type="button"
            className="border-0 bg-transparent" 
            onClick={onBack}
          >
            <img src="/assets/images/backicon.jpg" width={35} alt="Back" />
          </button>
          <button 
            type="submit" 
            className="btncust w-auto activebg cursor-pointer"  
            disabled={!formData.name || !formData.email || !formData.phone || !formData.address}
          >
            Next
          </button>
        </div>
      </div>
    </form>
    </div>
    </div>
    </div>
  );
};

export default SignUpForm; 