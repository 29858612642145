import React, { useState } from 'react';
import './WeightLossCalculator.css';

const WeightLossCalculator = () => {
    const [weight, setWeight] = useState(100);
    const [isKg, setIsKg] = useState(true);
    const minWeight = isKg ? 40 : 88;  // 40kg or 88lbs
    const maxWeight = isKg ? 200 : 440; // 200kg or 440lbs

    const calculatePotentialLoss = (currentWeight) => {
        const lossPercentage = 0.20; // 20% potential loss
        const potentialLoss = currentWeight * lossPercentage;
        return Math.round(potentialLoss * 10) / 10; // Round to 1 decimal place
    };

    const handleWeightChange = (e) => {
        const newWeight = parseFloat(e.target.value);
        setWeight(newWeight);
    };

    const toggleUnit = () => {
        const newWeight = isKg 
            ? Math.round(weight * 2.20462) // kg to lbs
            : Math.round(weight / 2.20462); // lbs to kg
        setWeight(newWeight);
        setIsKg(!isKg);
    };

    const potentialLoss = calculatePotentialLoss(weight);
    const unit = isKg ? 'kg' : 'lbs';

    return (
        <div className="weight-loss-calculator">
            <div className="calculator-card">
            <button 
                onClick={toggleUnit} 
                className="unit-toggle"
                data-kg={isKg}
                type="button"
            >
                <div className="unit-toggle-slider"></div>
                <div className="unit-toggle-options">
                    <span className="unit-toggle-option">kg</span>
                    <span className="unit-toggle-option">lb</span>
                </div>
            </button>
                <div className="weight-input-section">
                    <label className='fs-21'>Select your current weight:</label>
                    <div className="weight-display">
                        <span className="weight-number">{weight}</span>
                        <span className="weight-unit">{unit}</span>
                    </div>
                    <input
                        type="range"
                        min={minWeight}
                        max={maxWeight}
                        value={weight}
                        onChange={handleWeightChange}
                        className="weight-slider"
                    />
                </div>

                <div className="potential-loss-section">
                    <p className='fs-21'>You could lose up to</p>
                    <div className="loss-amount">
                        <span className="loss-number">{potentialLoss}</span>
                        <span className="loss-unit">{unit}</span>
                    </div>
                    <p className="timeframe fs-21">in one year</p>
                </div>
            </div>
        </div>
    );
};

export default WeightLossCalculator; 