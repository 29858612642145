import React, { useEffect, useState } from 'react'
import './index.css'
import { NavLink } from 'react-router-dom'
import { Flex } from 'antd'
import MenuMobile from './MenuMobile';
import { get } from '../../services/ApiService';
import { useSelector } from 'react-redux';

const NewNavbar = () => {
    const [visible, setVisible] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [scrollDirection, setScrollDirection] = useState(null);
    const [lastScrollY, setLastScrollY] = useState(0);
    const cartItems = useSelector((state) => state.cart?.cartItems) || [];
    
    //   scrolling functionality

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // Show fixed navbar when scrolling up and above a certain range
            if (currentScrollY > 500 && currentScrollY < lastScrollY) {
                setIsScrolled(true);
            } else if (currentScrollY <= 500 || currentScrollY > lastScrollY) {
                setIsScrolled(false);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);
    return (
        <>
            <div className='mt-3'>
                <Flex gap={0} align='center' className='mbl-flex ' justify='space-between'>
                    <div className='logo'>
                        <NavLink to={'https://www.beta.myprivatechemist.com/'}>
                            <img src={'/logo.png'} id='logo' alt='' />
                        </NavLink>
                    </div>
                    <ul className='menu-list'>
                        <li >
                            <NavLink to="https://www.beta.myprivatechemist.com/" className="nav-item">
                                <span className='metropill'>home</span>
                            </NavLink>
                        </li>
                        <li className='position-relative dropdown-parent'>
                            <NavLink to="https://www.beta.myprivatechemist.com/blood-testing/" className="nav-item">
                                <span className='metropill'>blood testing</span>
                            </NavLink>
                            {/* 1st dropdown */}
                            <ul className='dropdown-cust droponeshow'>
                                <li className='nav-li'>
                                    <NavLink to="/a-z-tests" className="nav-itemd">
                                        <span className='metropill-thin'>A-Z Tests</span>
                                    </NavLink>
                                </li>
                                <li className='nav-li'>
                                    <NavLink to="/build-my-package" className="nav-itemd">
                                        <span className='metropill-thin'>build my package</span>
                                    </NavLink>
                                </li>
                                <li className='position-relative dropdown-parenttwo nav-li'>
                                    <NavLink className="nav-itemd plus cursor-auto">
                                        <span className='metropill-thin'>health concern</span>
                                    </NavLink>
                                    {/* 2nd dropdown */}
                                    <ul className='dropdown-cust droptwoshow'>
                                        <li>
                                            <NavLink to="/a-z-tests?category=general-health" className="nav-itemd">
                                                <span className='metropill-thin'>
                                                    general health
                                                </span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=mens-health" className="nav-itemd">
                                                <span className='metropill-thin'>mens health</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=mood" className="nav-itemd">
                                                <span className='metropill-thin'>mood tests</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=nutrition" className="nav-itemd">
                                                <span className='metropill-thin'>nutrition</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=sexual-health-fertility" className="nav-itemd">
                                                <span className='metropill-thin'>sexual health/fertility</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=sports-fitness" className="nav-itemd">
                                                <span className='metropill-thin'>sports/fitness</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=tiredness-fatigue" className="nav-itemd">
                                                <span className='metropill-thin'>tiredness & fatigue tests</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=weight" className="nav-itemd">
                                                <span className='metropill-thin'>weight</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=womens-health" className="nav-itemd">
                                                <span className='metropill-thin'>women's health</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className='position-relative dropdown-parent'>
                            <NavLink to="https://www.beta.myprivatechemist.com/travel-clinic/" className="nav-item">
                                <span className='metropill'>travel clinic</span>
                            </NavLink>
                            <ul className='dropdown-cust droponeshow'>
                                <li className='nav-li'>
                                    <NavLink to="/travel-clinic/vaccinations-by-diseases" className="nav-itemd">
                                        <span className='metropill-thin'>Search by Vaccination</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to="/weight-loss" className="nav-item">
                                <span className='metropill'>Weight Loss</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/" className="nav-item">
                                <span className='metropill'>treatment & medication</span>
                            </NavLink>
                        </li> */}
                        {/* <li>
                            <NavLink to="/" className="nav-item">
                                <span className='metropill'>our mission</span>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="https://www.beta.myprivatechemist.com/contact/" className="nav-item">
                                <span className='metropill'>contact</span>
                            </NavLink>
                        </li>
                        <li>
                            <div className="cart-nav-1">
                                <NavLink to="/cart" >
                                <span className='counterCart'>
                                    <span>{cartItems?.length}</span>
                                </span>
                                    <img src="/assets/icons/cart.png" className='cartone' width={24} alt="" />
                                </NavLink>
                            </div>
                        </li>
                    </ul>
                    <div className='mbl-icon-show'>
                        <Flex gap={10}>
                            <div className="sp-cover" onClick={() => setVisible(true)}>
                                <span className="sp"></span>
                            </div>
                            <div className="cart-nav-1">
                                <NavLink to="/cart" >
                                <span className='counterCart'>
                                    <span>{cartItems?.length}</span>
                                </span>
                                    <img src="/assets/icons/cart.png" className='cartone' width={24} alt="" />
                                </NavLink>
                            </div>
                        </Flex>
                    </div>
                </Flex>
                <MenuMobile
                    visible={visible}
                    onClose={() => setVisible(false)}
                />
            </div>
            <div className={`mt-3 navbar-fixed w-100 ${isScrolled ? 'active' : ''}`}>
                <Flex gap={0} align='center' className='mbl-flex ' justify='space-between'>
                    <div className='logo'>
                        <img src={'/logo.png'} id='logo' alt='' />
                    </div>
                    <ul className='menu-list'>
                        <li >
                            <NavLink to="https://www.beta.myprivatechemist.com/" className="nav-item">
                                <span className='metropill'>home</span>
                            </NavLink>
                        </li>
                        <li className='position-relative dropdown-parent'>
                            <NavLink to="https://www.beta.myprivatechemist.com/blood-testing/" className="nav-item">
                                <span className='metropill'>blood TESTING</span>
                            </NavLink>
                            {/* 1st dropdown */}
                            <ul className='dropdown-cust droponeshow'>
                                <li className='nav-li'>
                                    <NavLink to="/a-z-tests" className="nav-itemd">
                                        <span className='metropill-thin'>A-Z Tests</span>
                                    </NavLink>
                                </li>
                                <li className='nav-li'>
                                    <NavLink to="/build-my-package" className="nav-itemd">
                                        <span className='metropill-thin'>build my package</span>
                                    </NavLink>
                                </li>
                                <li className='position-relative dropdown-parenttwo nav-li'>
                                    <NavLink className="nav-itemd plus cursor-auto">
                                        <span className='metropill-thin'>health concern</span>
                                    </NavLink>
                                    {/* 2nd dropdown */}
                                    <ul className='dropdown-cust droptwoshow'>
                                        <li>
                                            <NavLink to="/a-z-tests?category=general-health" className="nav-itemd">
                                                <span className='metropill-thin'>
                                                    general health tests
                                                </span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=mens-health" className="nav-itemd">
                                                <span className='metropill-thin'>mens health</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=mood" className="nav-itemd">
                                                <span className='metropill-thin'>mood tests</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=nutrition" className="nav-itemd">
                                                <span className='metropill-thin'>nutrition</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=sexual-health-fertility" className="nav-itemd">
                                                <span className='metropill-thin'>sexual health/fertility</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=sports-fitness" className="nav-itemd">
                                                <span className='metropill-thin'>sports/fitness</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=tiredness-fatigue" className="nav-itemd">
                                                <span className='metropill-thin'>tiredness & fatigue tests</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=weight" className="nav-itemd">
                                                <span className='metropill-thin'>weight</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/a-z-tests?category=womens-health" className="nav-itemd">
                                                <span className='metropill-thin'>women's health</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className='position-relative dropdown-parent'>
                            <NavLink to="https://www.beta.myprivatechemist.com/travel-clinic/" className="nav-item">
                                <span className='metropill'>travel clinic</span>
                            </NavLink>
                            <ul className='dropdown-cust droponeshow'>
                                <li className='nav-li'>
                                    <NavLink to="/vaccinations/" className="nav-itemd">
                                        <span className='metropill-thin'>Search by Vaccination</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to="/weight-loss" className="nav-item">
                                <span className='metropill'>Weight Loss</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/" className="nav-item">
                                <span className='metropill'>treatment & medication</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/" className="nav-item">
                                <span className='metropill'>our mission</span>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="https://www.beta.myprivatechemist.com/contact/" className="nav-item">
                                <span className='metropill'>contact</span>
                            </NavLink>
                        </li>
                        <li>
                            <div className="cart-nav-1">
                                <NavLink to="/cart" >
                                <span className='counterCart'>
                                    <span>{cartItems?.length}</span>
                                </span>
                                    <img src="/assets/icons/cart.png" className='cartone ww' width={24} alt="" />
                                </NavLink>
                            </div>
                        </li>
                    </ul>
                    <div className='mbl-icon-show'>
                        <Flex gap={10}>
                            <div className="sp-cover" onClick={() => setVisible(true)}>
                                <span className="sp"></span>
                            </div>
                            <div className="cart-nav-1">
                                <NavLink to="/cart" >
                                <span className='counterCart'>
                                    <span>{cartItems?.length}</span>
                                </span>
                                    <img src="/assets/icons/cart.png" className='cartone' width={24} alt="" />
                                </NavLink>
                            </div>
                        </Flex>
                    </div>
                </Flex>
                <MenuMobile
                    visible={visible}
                    onClose={() => setVisible(false)}
                />

            </div>
        </>
    )
}

export { NewNavbar }