import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './index.css';

const NotEligible = ({ onReview }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className="w-form fade-in">
      <div className="not-eligible-container">
        <div className="error-icon">
          <i className="bi bi-exclamation-circle-fill"></i>
        </div>
        <div className="content-wrapper">
          <h5 className="titles">Not Eligible for Collection</h5>
          <div className="message-box">
            <p className="main-message">
              Based on our assessment, collection from clinic is not available for this treatment. 
              Please choose home delivery option for a safer and more convenient service.
            </p>
            <div className="warning-box">
              <p className="warning-text">
                For your safety and optimal treatment outcomes, we recommend proceeding with home delivery.
              </p>
            </div>
          </div>
          <div className="action-box">
            <div className="checkbox-wrapper">
              <input 
                type="checkbox" 
                id="agree" 
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                className="custom-checkbox"
              />
              <label htmlFor="agree" className="checkbox-label">
                I understand and wish to review my options
              </label>
            </div>
            <button 
              className="review-button"
              onClick={onReview}
              disabled={!isChecked}
            >
              Go Back & Review Options
            </button>
            <Link to="/" className="home-link">
              Return to Homepage
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotEligible; 