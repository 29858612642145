import React from "react";
import QuestionOptions from "./QuestionOptions";

const QuestionInput = ({ question, answer, handleAnswerChange}) => {
  return (
    <>
      {(question.inputType === "radio" ||  question.inputType === "checkbox" || question.inputType === "select") &&
        <QuestionOptions 
          question = {question}
          answer = {answer}
          handleAnswerChange = {handleAnswerChange}
        />
      }

      {question.inputType === "text" && (
        <div className="col-lg-12">
          <input
            type="text"
            onChange={(e) => handleAnswerChange("", e.target.value, "")}
            value={answer?.description}
          />
        </div>
      )}
    </>
  );
};

export default QuestionInput;
