import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ProductSelection.css";
import { HeaderForm } from "../consultation/HeaderForm";
import PageLoader from "../../components/loader/PageLoader";
import Mounjaro from "../../assets/images/product-card-maunjaro.webp";
import Wegovy from "../../assets/images/product-card-wegovy.webp";
import { post, get } from "../../services/ApiService";
import { addToCart, deleteFromCartById } from "../../redux/slices/CartSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useSelector } from "react-redux";

const ProductSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingCart, setIsAddingCart] = useState(false);
  const [MounjaroProducts, setMounjariProducts] = useState([]);
  const [WegovyProducts, setWegovyProducts] = useState([]);
  const { cartItems } = useSelector((state) => state.cart);
  const weightLossItem = cartItems.find(item => item.section === "weight-loss");
  


  useEffect(() => {
    if (weightLossItem) {
      const preselectedItem = weightLossItem;
      if (MounjaroProducts.length > 0 || WegovyProducts.length > 0) {
        let foundProduct =
          MounjaroProducts.find((item) => item._id === preselectedItem._id) ||
          WegovyProducts.find((item) => item._id === preselectedItem._id);
  
        if (foundProduct) {
          setSelectedProduct(
            MounjaroProducts.some((item) => item._id === preselectedItem._id)
              ? "Mounjaro"
              : "Wegovy"
          );
          setSelectedVariation(foundProduct);
        } else {
          // Reset selection if preselected item is not found in the products list
          setSelectedProduct(null);
          setSelectedVariation(null);
        }
      }
    } else {
      setSelectedProduct(null);
      setSelectedVariation(null);
    }
  }, [MounjaroProducts, WegovyProducts, weightLossItem]);
  

  const handleProductSelect = (selectedItem, variation) => {
      const existingWeightLossItem = cartItems.find(
        (item) => item.section === "weight-loss"
      );

      if (existingWeightLossItem) {
        dispatch(deleteFromCartById(existingWeightLossItem._id));
      }

      const product = {
        _id: variation._id,
        name:  `${variation.name} ${variation.strength}mg`,
        unique_code: "",
        price: variation.price,
        type: "",
        section: "weight-loss",
        serviceId: variation?.serviceId,
        serviceAdonsId: "",
      };

      dispatch(addToCart(product));
      setSelectedProduct(selectedItem);
      setSelectedVariation(variation);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await get("api/drugs/weight-loss");
      console.log(res);
      if (res.success) {
        setMounjariProducts(res.data.mounjaro);
        setWegovyProducts(res.data.wegovy);
      } else {
        setMounjariProducts([]);
        setWegovyProducts([]);
        setNotFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      setMounjariProducts([]);
      setWegovyProducts([]);
      setNotFound(true);
      setIsLoading(false);
    }
  };

  const AddingToCart = () => {
    try {
      setIsAddingCart(true);

      const existingWeightLossItem = cartItems.find(
        (item) => item.section === "weight-loss"
      );

      if (existingWeightLossItem) {
        dispatch(deleteFromCartById(existingWeightLossItem._id));
      }

      const product = {
        _id: selectedVariation._id,
        name:  `${selectedVariation.name} ${selectedVariation.strength}mg`,
        unique_code: "",
        price: selectedVariation.price,
        type: "",
        section: "weight-loss",
        serviceId: selectedVariation?.serviceId,
        serviceAdonsId: "",
      };

      dispatch(addToCart(product));

      navigate("/checkout");
    } catch (error) {
      console.error(error);
      message.error("Failed to add to cart");
    } finally {
      setIsAddingCart(false);
    }
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="product-selection-page">
        <HeaderForm />
        {/* Main Content */}
        <section className="weight-loss-products">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-center m-0 p-0">
                  OUR PRODUCTS FOR WEIGHT LOSS
                </h1>
                <p className="text-center mb-8">
                  Please choose the appropriate strength of Mounjaro or Wegovy
                  before proceeding to checkout.
                </p>
              </div>
            </div>
            <div className="row justify-content-center flex-nowraps">
              {MounjaroProducts.length > 0 && (
                <div className="col-xxl-4 col-lg-5 col-md-6">
                  <div className="product-card">
                    <div className="product-image">
                      <img src={Mounjaro} alt={`Mounjaro`} />
                    </div>
                    <div className="product-content">
                      <h3>Mounjaro®</h3>
                      <ul class="product-features">
                        <li>Lose up to 20% of body weight</li>
                        <li>Convenient weekly injection</li>
                        <li>Targets weight loss by mimicking GLP-1 and GIP hormones</li>
                      </ul>
                      <p className="product-description">Targets two hunger-regulating hormones for enhanced appetite control and improved metabolism, often leading to greater weight loss.</p>
                      {selectedProduct === "Mounjaro" && selectedVariation && (
                        <div className="product-pricing mb-8">
                          <div className="price-block">
                            <span className="label text-center">FIRST MONTH:</span>
                            <span className="cricle-amount">
                              <h4 className="text-white text-center m-0 fs-25">£{selectedVariation.price}</h4>
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="product-tags">
                        {MounjaroProducts.map((product) => (
                          <button
                            key={product._id}
                            className={`tag ${
                              selectedProduct === "Mounjaro" &&
                              selectedVariation?._id === product._id
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleProductSelect("Mounjaro", product)}
                          >
                            {product.strength}mg
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {WegovyProducts.length > 0 && (
                <div className="col-xxl-4 col-lg-5 col-md-6">
                  <div className="product-card">
                    <div className="product-image">
                      <img src={Wegovy} alt={`Wegovy`} />
                    </div>
                    <div className="product-content">
                      <h3>Wegovy®</h3>
                      <ul class="product-features">
                        <li>Lose up to 15% of body weight</li>
                        <li>Convenient weekly injection</li>
                        <li>Targets weight loss by mimicking GLP-1 hormones</li>
                      </ul>
                      <p className="product-description">Works by reducing appetite and slowing digestion, leading to gradual weight loss. </p>
                      {selectedProduct === "Wegovy" && selectedVariation && (
                        <div className="product-pricing mb-8">
                          <div className="price-block">
                            <span className="label text-center">FIRST MONTH:</span>
                            <span className="cricle-amount">
                              <h4 className="text-white text-center m-0 fs-25">£{selectedVariation.price}</h4>
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="product-tags">
                        {WegovyProducts.map((product) => (
                          <button
                            key={product._id}
                            className={`tag ${
                              selectedProduct === "Wegovy" &&
                              selectedVariation?._id === product._id
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleProductSelect("Wegovy", product)}
                          >
                            {product.strength}mg
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Fixed Checkout Bar */}
        <div className="checkout-bar">
          <div className="selected-product">
            {selectedProduct && selectedVariation && (
              <div className="selection-info">
                <img
                  src={selectedProduct === "Mounjaro" ? Mounjaro : Wegovy}
                  alt={selectedVariation.name}
                />
                <div className="d-flex flex-wrap">
                  <span>
                    {selectedVariation.name} - {selectedVariation.strength}
                  </span>
                  <span className="w-100">£{selectedVariation.price}</span>
                </div>
              </div>
            )}
          </div>
          <button
            className={`checkout-btn ${!selectedVariation ? "disabled" : ""}`}
            disabled={!selectedVariation}
            onClick={() => AddingToCart()}
          >
            Proceed to Checkout
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductSelection;
