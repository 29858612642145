import React, { useState } from 'react';
import { HeaderForm } from '../../pages/consultation/HeaderForm';
import NotEligible from './NotEligible';
import './index.css';

const DeliveryOptions = ({ deliveryOption, setDeliveryOption, onBack, onSubmit }) => {
  const [showNotEligible, setShowNotEligible] = useState(false);

  const handleOptionChange = (value) => {
    setDeliveryOption(value);
    if (value === 'clinic') {
      setShowNotEligible(true);
    } else {
      setShowNotEligible(false);
    }
  };

  const handleReview = () => {
    setShowNotEligible(false);
    setDeliveryOption('');
  };

  if (showNotEligible) {
    return <NotEligible onReview={handleReview} />;
  }

  return (
    <div className="min-height">
      <HeaderForm />
      <div className="justify-content-md-center row g-3 m-0">
        <div className="col-12">
          <form className="w-form fade-in" onSubmit={onSubmit}>
            <div className="row g-5">
              <div className="col-lg-12">
                <h5 className="mb-5">How would you like to receive your medication?</h5>
                <p>Please select your preferred delivery option.</p>
              </div>
              <div className="col-lg-12">
                <input 
                  type="radio" 
                  id="option-clinic" 
                  name="delivery" 
                  value="clinic"
                  checked={deliveryOption === 'clinic'}
                  onChange={(e) => handleOptionChange(e.target.value)} 
                  className='d-none'
                />
                <label 
                  className={`padc bullet w-100 d-flex align-items-center gap-3 rounded-3 ${
                    deliveryOption === 'clinic' ? 'active-check' : ''
                  }`}
                  htmlFor="option-clinic"
                >
                  <div className="label-cs"></div>
                  <span className="metropill">Collect from Clinic</span>
                </label>
              </div>
              <div className="col-lg-12">
                <input 
                  type="radio" 
                  id="option-delivery" 
                  name="delivery" 
                  value="delivery"
                  checked={deliveryOption === 'delivery'}
                  onChange={(e) => handleOptionChange(e.target.value)} 
                  className='d-none'
                />
                <label 
                  className={`padc bullet w-100 d-flex align-items-center gap-3 rounded-3 ${
                    deliveryOption === 'delivery' ? 'active-check' : ''
                  }`}
                  htmlFor="option-delivery"
                >
                  <div className="label-cs"></div>
                  <span className="metropill">Home Delivery</span>
                </label>
                {deliveryOption === 'delivery' && (
                  <div className="alert alert-infos justify-content-center text-center d-flex align-items-center">
                    <span className="small">Click for more info</span>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-30 p-0 card-footer bg-transparent border-0 d-flex align-items-center justify-content-between gap-2">
              <button 
                type="button"
                className="border-0 bg-transparent" 
                onClick={onBack}
              >
                <img src="/assets/images/backicon.jpg" width={35} alt="Back" />
              </button>
              <button 
                type="submit" 
                className="btncust w-auto activebg cursor-pointer"
                disabled={!deliveryOption}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeliveryOptions;