import React, { useEffect, useState } from "react";
import { NewNavbar, BreadCrumbsNew, NewFooter } from "../../components";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "./index.css";
import weightlossbanner from "../../assets/images/weight-loss.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import quoteIcon from "../../assets/images/quote-icon.jpg";
import Maunjaro from "../../assets/images/product-card-maunjaro.webp";
import Wegovy from "../../assets/images/product-card-wegovy.webp";
import { BloodTestContactForm } from "../BloodTesting/BloodTestcomponents/BloodTestContactForm";
import WeightLossCalculator from "../../components/WeightLossCalculator/WeightLossCalculator";
import { Bmi } from "../consultation/BmiStep/Bmi.jsx";
import WeightLossGraph from "../../components/WeightLossGraph/WeightLossGraph";
import { Link } from "react-router-dom";
import WeightLossImg from '../../assets/images/weight-loss-man.jpg';
import phoneIcon from '../../assets/images/phone-icon.jpg';
import weightlossvideo from '../../assets/images/video-weightloss.mp4';
import weightlossSoul from '../../assets/images/weightloss-soul.png';

const testimonials = [
  {
    quote:
      "My experience at My Private Chemist was nothing short of amazing. From the moment I arrived, I was warmly welcomed by the attentive staff at the reception and enveloped in a serene, calming atmosphere of the office managed by a very professional and warm-welcoming Pharmacists.",
    author: "Shem Wadowski",
  },
  {
    quote:
      "Great experience. Professional and speedy. Got an appointment for the the next day and I was in and out of the building in 5 minutes including adding a vaccine that wasn't in my original booking.",
    author: "Ian Bright",
  },
  {
    quote:
      "My Private Chemist provides an excellent service for anyone wanting to obtain prescriptions for regular medicines, where going to the doctor may take time effort that is not practical. I can fully recommend the quick and friendly service.",
    author: "Robin Myerscough-Walker",
  },
  {
    quote:
      "I had a medical issue while traveling and was recommended to go to this clinic. My experience was amazing! I got seen right away and all the staff were lovely - Sana was amazing. Thank you!!",
    author: "Rose Cranmer",
  },
];

const faqData = [
  {
    question: "What is Mounjaro (Tirzepatide)?",
    answer:
      "Mounjaro (tirzepatide) is a prescription medication used to help with weight loss. It works by regulating blood sugar levels and reducing appetite, promoting weight loss in individuals who are overweight or obese.",
  },
  {
    question: "What is Wegovy (Semaglutide)?",
    answer:
      "Wegovy (semaglutide) is another prescription medication designed to support weight management. It mimics a natural hormone in the body that regulates appetite, helping individuals eat less and lose weight. It is approved by the NHS for weight loss when combined with a healthy lifestyle.",
  },
  {
    question: "How will I get my medication?",
    answer:
      "You must collect your medication in person from our clinic in Liverpool Street, London. This is necessary so that we can verify your weight before starting your supply. For follow-up prescriptions, we can offer home delivery to make the process more convenient for you.",
  },
  {
    question: "How do I get started with Mounjaro or Wegovy?",
    answer:
      "To get started, you'll need to complete an online consultation with our healthcare team. During the consultation, we'll discuss your health history and weight loss. Once approved, you will be provided with an appointment, and you can collect your medication in person at our clinic.",
  },
  {
    question: "Do you offer home delivery for medications?",
    answer:
      "Yes, we offer next-day delivery for Mounjaro and Wegovy medications. If you prefer to have your medication delivered, please email us, and we will arrange for a prompt delivery to your home. We typically ask, first time users to visit our clinic.",
  },
  {
    question: "Do I need a prescription for Mounjaro or Wegovy?",
    answer:
      "Yes, both Mounjaro and Wegovy require a prescription. After your online consultation, if the medication is deemed suitable for you, we will create a prescription and dispense your medication.",
  },
  {
    question: "How does the consultation process work?",
    answer:
      "The consultation is conducted online, as a questionaire. Our team then review your medical history, and assess whether Mounjaro or Wegovy is right for you.",
  },
  {
    question: "Is the consultation covered by insurance?",
    answer:
      "We recommend checking with your insurance provider to confirm coverage for online consultations. We strive to keep costs transparent, and our healthcare team will provide a detailed breakdown of fees during your consultation.",
  },
  {
    question: "How should I take Mounjaro or Wegovy?",
    answer:
      "Mounjaro and Wegovy are injectable medications, typically administered once a week. Our healthcare provider will give you clear instructions on how to properly administer the injections and guide you through the process to ensure your comfort and safety.",
  },
  {
    question: "Are there any side effects to Mounjaro or Wegovy?",
    answer:
      "As with any medication, there may be side effects, although not everyone experiences them. Common side effects include nausea, fatigue, or stomach discomfort. If you experience any issues, our healthcare team will assist you in managing side effects.",
  },
  {
    question: "Who is eligible for Mounjaro or Wegovy?",
    answer:
      "These medications are typically prescribed to individuals who are overweight or obese and are seeking to lose weight as part of a comprehensive weight management plan. Eligibility will be determined during your online consultation with our healthcare provider.",
  },
  {
    question: "Do I need to follow a specific diet or exercise plan while using Mounjaro or Wegovy?",
    answer:
      "While Mounjaro and Wegovy can help with weight loss, following a healthy diet and regular exercise plan is recommended to achieve the best results. Our team can offer guidance based on your individual goals.",
  },
  {
    question: "How much do Mounjaro and Wegovy cost?",
    answer:
      "Prices for Mounjaro and Wegovy start from £139, with the price increasing depending on the strength of the medication. Our healthcare team will provide specific pricing details during your consultation, based on the medication and dosage prescribed.",
  },
];

const howItWorksSteps = [
  {
    number: "01",
    title: "Choose your test",
    description:
      "Select your blood test either as specific tests or explore through health concerns. Schedule your convenient time for the clinic visit. Get your detailed results and expert analysis",
  },
  {
    number: "02",
    title: "Book clinic visit",
    description: "Schedule your convenient time for the clinic visit. Get your detailed results and expert analysis. Select your blood test either as specific tests or explore through health concerns.",
  },
  {
    number: "03",
    title: "Receive your results",
    description: "Get your detailed results and expert analysis. Select your blood test either as specific tests or explore through health concerns. Schedule your convenient time for the clinic visit.",
  },
];

const statsData = [
  {
    number: "20",
    unit: "%",
    title: "Weight Loss",
    description: "Patients achieve an average steady weight loss of one fifth of their starting weight.",
  },
  {
    number: "15",
    unit: "kg",
    title: "Lost",
    description: "Studies show patients can lose up to 15 kg within 6 months.",
  },
  {
    number: "6",
    unit: "",
    title: "Months for Results",
    description: "Significant weight loss is typically seen within half a year.",
  },
  {
    number: "90",
    unit: "%",
    title: "Health Improvements",
    description:
      "Patients see improvements in blood pressure, cholesterol, and blood sugar.",
  },
];

const WeightLoss = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTab, setActiveTab] = useState("step1");
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [isBmi, setIsBmi] = useState(true);
  const [currentWeight, setCurrentWeight] = useState(0);
  const [currentBmiCategory, setCurrentBmiCategory] = useState(null);
  const [isPricingVisible, setIsPricingVisible] = useState(false);

  const handleBMIValueChange = (bmiValue, heightCm, weightKg, category) => {
    console.log('BMI Value Changed - Full Details:', {
      bmiValue,
      heightCm,
      weightKg,
      category,
    });
    setCurrentWeight(weightKg);
    setCurrentBmiCategory(bmiValue >= 30 ? 'Obese' : bmiValue >= 25 ? 'Overweight' : bmiValue >= 18.5 ? 'Healthy' : 'Underweight');
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="weightloss-wraper">
      <div className="breadcrumnb-img-bg-weightloss pb-0">
        <div className="container">
          <NewNavbar />
          {/* <BreadCrumbsNew 
                        breadcrumbs={[
                            { label: 'Home', path: '/' },
                            { label: 'Weight Loss', path: '' }
                        ]} 
                        title="Weight Loss" 
                    /> */}

          <div className="weight-loss-page pb-0">

            <div className="row">
              <div className="col-lg-6 col-md-7 my-auto">
                <div className="banner-content">
                  <h1 className="text-white">
                    Weight <span>Loss</span>
                  </h1>
                  {/* <h4>Personalized Care at Your Fingertips <span>(GP Services)</span></h4> */}
                  <p className="mb-10 text-white">
                  From personalised weight loss programs to expert-led healthcare solutions, we provide tailored support to help you achieve your goals.
                  </p>
                  <Link to={`/consultation/weight-loss`} className="btn btn-primary">
                    Start Consultation
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-5 text-end">
                <div className="img-banner-wrap">
                  <img src={weightlossbanner} alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* intro sectoin */}


      <section className="intro-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="intro-content text-center">
                <h2>Prescription Weight Loss Treatments with Proven Results</h2>
                <p className="intro-description">Discover our prescription weight loss treatments backed by medical research and clinical testing. These evidence-based solutions are designed to support your weight loss journey with guaranteed effectiveness, helping you achieve lasting results under expert guidance.</p>
              </div>
            </div>
          </div>
        </div>


        <div className="intro-sec-inner">
          <div className="container">
            <div className="intro-banner">
              <div className="shadow-intro"></div>
              <div className="row h-100">
                <div className="col-lg-6 my-auto">
                  <div className="banner-content">
                    <h2><span>Empower your journey.</span><span>Trusted and proven.</span></h2>
                    <Link to={`/consultation/weight-loss`} className="btn btn-primary btn-appointment mb-0">
                      Start Consultation
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="video-wrap">
                    <video src={weightlossvideo} autoPlay muted loop playsInline />
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-accordion">
              <button
                className="accordion-header"
                onClick={() => setIsPricingVisible(!isPricingVisible)}
              >
                {isPricingVisible ? 'Hide' : 'Show'} Pricing
                <span className={`accordion-arrow ${isPricingVisible ? 'open' : ''}`}>▼</span>
              </button>

              {isPricingVisible && (
                <div className="pricing-content">
                  <div className="pricing-table">
                    <div className="pricing-row header">
                      <div className="service-col">Services</div>
                      <div className="price-col">Price</div>
                    </div>

                    <div className="pricing-row">
                      <div className="service-col">Mounjaro</div>
                      <div className="price-col">£165</div>
                    </div>

                    <div className="pricing-row">
                      <div className="service-col">Wegovy</div>
                      <div className="price-col">£130</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* stats Banner */}


      {/* pen products section */}

      <section className="weight-loss-products">
        <div className="container">
          <div className="row justify-content-center flex-nowraps">
            <div className="col-xxl-4 col-lg-5 col-md-6">
              <div className="product-card">
                <div className="product-image">
                  <img src={Maunjaro} alt="Mounjaro Pen" />
                </div>
                <div className="product-content">
                  <h3>Mounjaro®</h3>
                  <div className="product-tags">
                    <span className="tag">GIP-1/GIP</span>
                    <span className="tag">Tirzepatide</span>
                  </div>
                  <ul className="product-features">
                    <li>Lose up to 20% of body weight</li>
                    <li>Convenient weekly injection</li>
                    <li>
                    Targets weight loss by mimicking GLP-1 and GIP hormones
                    </li>
                  </ul>
                  <div className="product-pricing">
                    <div className="price-block">
                      <span className="label text-center">FROM</span>
                      <span className="cricle-amount">
                        <h4 className="text-white text-center m-0 fs-25">£165</h4>
                      </span>
                    </div>
                  </div>
                  <a
                    href="#"
                    className="quick-btn text-decoration-none metropill text-transform-uppercase text-green d-block text-center px-0"
                  >
                    Learn more
                  </a>

                </div>
              </div>
            </div>

            <div className="col-xxl-4 col-lg-5 col-md-6">
              <div className="product-card">
                <div className="product-image">
                  <img src={Wegovy} alt="Wegovy Pen" />
                </div>
                <div className="product-content">
                  <h3>Wegovy®</h3>
                  <div className="product-tags">
                    <span className="tag">GLP-1</span>
                    <span className="tag">Semaglutide</span>
                  </div>
                  <ul className="product-features">
                    <li>Lose up to 15% of body weight</li>
                    <li>Convenient weekly injection</li>
                    <li>Targets weight loss by mimicking GLP-1 hormones</li>
                  </ul>
                  <div className="product-pricing">
                    <div className="price-block">
                      <span className="label text-center">FROM</span>
                      <span className="cricle-amount">
                        <h4 className="text-white text-center m-0 fs-25">£130</h4>
                      </span>
                    </div>
                  </div>
                  <a
                    href="#"
                    className="quick-btn text-decoration-none metropill text-transform-uppercase d-block text-center px-0 text-green"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="stats-banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-10 col-xl-11 col-lg-12">
              <div className="stats-wrapper">
                <div className="stat-item">
                  <h4 className="stat-number me-3">6x</h4>
                  <p className="stat-text">
                    more effective than traditional methods
                  </p>
                </div>
                <div className="stat-item">
                  <h4 className="stat-number me-3">20%</h4>
                  <p className="stat-text">
                    potential weight loss after a year
                  </p>
                </div>
                <div className="stat-item">
                  <h4 className="stat-number me-3">
                    29<span className="unit">kg</span>
                  </h4>
                  <p className="stat-text">
                    (4st 8lbs) lost on average after 84 weeks
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* How It Works Section with Tabs */}
      <div className="how-it-works-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12">
              <h2 className="text-center">How Our Service Works</h2>
            </div>
            <div className="col-lg-5 col-md-6">
              {/* Add the calculator section */}

              <section className="calculator-section">
                <WeightLossCalculator />
              </section>
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-link ${activeTab === "step1" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("step1")}
                    role="tab"
                  >
                    <span>01</span>
                    <div>Complete Online Consultation</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${activeTab === "step2" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("step2")}
                    role="tab"
                  >
                    <span>02</span>
                    <div>Visit Our Clinic</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${activeTab === "step3" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("step3")}
                    role="tab"
                  >
                    <span>03</span>
                    <div>
                    Your Weight Loss Journey
                    </div>
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div
                  className={`tab-pane ${activeTab === "step1" ? "active" : ""
                    }`}
                  role="tabpanel"
                >
                  <div className="p-4 text-center">
                    <p>
                    Begin by filling out an online consultation, where we'll assess your needs and recommend the most suitable weight loss treatment for you. Once you select the treatment option that fits your goals, make your payment and choose an appointment to collect your medication. We make it simple to get started.
                    </p>
                  </div>
                </div>
                <div
                  className={`tab-pane ${activeTab === "step2" ? "active" : ""
                    }`}
                  role="tabpanel"
                >
                  <div className="p-4 text-center">
                    <p>When you arrive at our clinic, our expert team will meet with you for a face-to-face consultation. You'll receive your prescribed medication and go through the treatment process. Our team will guide you through each step, ensuring you're comfortable and fully informed. We're here to provide support throughout your journey.</p>
                  </div>
                </div>
                <div
                  className={`tab-pane ${activeTab === "step3" ? "active" : ""
                    }`}
                  role="tabpanel"
                >
                  <div className="p-4 text-center">
                    <p>Once your treatment begins, we'll help you prepare for your transformation by supporting lifestyle and dietary changes. We offer ongoing guidance to ensure you stay on track, adjust your treatment as needed, and reach your weight loss goals successfully. Our team is committed to helping you achieve long-term success.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* BMI Calculator Section */}
      <section 
        className="bmi-section-main bmi-section-main-weightloss-page bg-white">
      <div 
        className={`bmi-section-bg ${
          !(currentBmiCategory && (currentBmiCategory === 'Overweight' || currentBmiCategory === 'Obese')) 
          ? 'img-banner-wl my-0' 
          : 'my-0'
        }`}
        style={
          !(currentBmiCategory && (currentBmiCategory === 'Overweight' || currentBmiCategory === 'Obese'))
          ? { backgroundImage: `url(${WeightLossImg})` }
          : {}
        }
      >
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 my-auto">
              {currentBmiCategory && (currentBmiCategory === 'Overweight' || currentBmiCategory === 'Obese') ? (
                <div className="graph-wrap">
                  <WeightLossGraph
                    currentWeight={currentWeight}
                    bmiCategory={currentBmiCategory}
                  />
                </div>
              ) : (
                <div className="img-wrap-graph">
                  {/* <img src={WeightLossImg} alt="" /> */}
                </div>
              )}
            </div>
            <div className="col-lg-6">
            <h2 className="">BMI Calculator</h2>
              <section className="consultation-bmi-section">
                <Bmi
                  setIsBmi={setIsBmi}
                  onBmiValueChange={handleBMIValueChange}
                  fadeClass={fadeClass}
                  setFadeClass={setFadeClass}
                  redirectTo={'consultation/weight-loss'}
                />
              </section>
            </div>
          </div>
        </div>
      </section>

      {/* vide sec */}

      <section className="unique-objects-section">
      <div className="unique-objects-image">
                <img src={weightlossSoul} alt="Ceramic Vase" />
              </div>
        <div className="unique-objects-shadow"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="unique-objects-content">
                <h2>
                  <span className="highlight">The Essence</span> of <br />Weight Loss
                </h2>
                <div className="stats-grid">
                  {statsData.map((stat, index) => (
                    <div key={index} className="stat-item">
                      <div className="stat-header">
                        <span className="stat-number">
                          {stat.number}
                          <span className="unit">{stat.unit}</span>
                        </span>
                        <h3 className="stat-title">{stat.title}</h3>
                      </div>
                      <p className="stat-description">{stat.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="unique-objects-image">
                <img src={weightlossbanner} alt="Ceramic Vase" />
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* contact form */}
      <div className="form_wrap">
        <h3 className="text-center">Still Unsure About the Right Treatment?</h3>
        <p className="text-center">Contact our healthcare team for guidance on selecting the best weight loss option.</p>
        <p className="text-center"><a href="tel:0203 987 9202"><img src={phoneIcon} alt="phone icon" /> <span>0203 987 9202</span></a></p>
        <div className="form_wrap_inner">
          <BloodTestContactForm />
        </div>


      </div>

      {/* faqs */}
      <section className="faq-section">
        <div className="container">
          <h2 className="faq-title">Frequently Asked Questions</h2>
          <div className="accordion-container">
            {faqData.map((faq, index) => (
              <div
                key={index}
                className={`accordion-item ${activeIndex === index ? "active" : ""
                  }`}
              >
                <button
                  className="accordion-header"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="accordion-title">{faq.question}</span>
                  <span className="accordion-icon">{activeIndex === index ? '-' : '+'}</span>
                </button>
                <div className="accordion-content">
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* testimonials section */}

      <section className="testimonials-section">
        <div className="container-fluid p-0">
          <div className="row m-0">
            {/* <div className="col-lg-4 ps-0">
                      <div className="background-image-wrap">

                      </div>
                  </div> */}

            <div className="col-lg-12">
              <div className="testi-wrap-content-inner">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="testimonials-header">
                        <h2>What people say about My Private Chemist</h2>
                      </div>

                      <div className="testimonials-slider">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <Slider {...sliderSettings}>
                              {testimonials.map((testimonial, index) => (
                                <div key={index} className="testimonial-card-wrap">
                                  <div className="testimonial-card">
                                    <span className="quote-mark">
                                      <img src={quoteIcon} alt="" />
                                    </span>
                                    <p className="testimonial-text">
                                      {testimonial.quote}
                                    </p>
                                  </div>
                                  <div className="testimonial-bottom-content">
                                    <div className="testimonial-author">
                                      <span className="author-name">
                                        {testimonial.author},
                                      </span>
                                      <span className="author-location">
                                        {testimonial.location}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Slider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <NewFooter />
    </div>
  );
};

export { WeightLoss };
