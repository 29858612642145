import { Collapse, Typography } from 'antd'
import React from 'react';
import DOMPurify from "dompurify";

const { Panel } = Collapse
const { Text } = Typography
const SingleViewTabs = ({data}) => {
  const isHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str);
  return (
    <div className='mt-20'>
        <nav>
          <div className="nav nav-tabs nav-tabs-cust flex-md-row flex-column" id="nav-tab" role="tablist">
            <button className="nav-link active metropill-thin" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">DESCRIPTION</button>
            <button className="nav-link metropill-thin" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">BIOMARKER</button>
            <button className="nav-link metropill-thin" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">TURNAROUND</button>
          </div>
        </nav>
        <div className="tab-content tab-cont" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
            <h2 className='fs-56 font-400-weight mb-10'>Description</h2>
            <>
              {isHTML(data?.longDescription) ? (
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.longDescription) }} />
              ) : (
                <p className='metropill-thin text-black'>{data?.longDescription}</p> // Render as plain text if no HTML
              )}
            </>
          </div>
          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
            <h2 className='fs-56 font-400-weight mb-10'>Biomarkers</h2>
            {data?.biomarkers  && data?.biomarkers.length > 0 ?
              <Collapse className="custom-collapse collapse-single">
                {
                    data?.biomarkers.map((bio,index)=> (
                    <Panel 
                        className='custom-panel' 
                        header={<span className='metropill-thins fs-18'>{bio.name}</span>}
                        key={index}
                        >
                        <p className='metropill-thins fs-18'>{bio.shortDescription}</p>
                    </Panel>
                ))}
              </Collapse>
              :
                <>
                  <Collapse className="custom-collapse collapse-single">
                    <Panel 
                        className='custom-panel' 
                        header={<span className='metropill-thins fs-18'>{data.name}</span>}
                        >
                        <p className='metropill-thin fs-18'>{data.shortDescription}</p>
                    </Panel>
              </Collapse>
                </>
            }
          </div>
          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
            <h2 className='fs-56 font-400-weight mb-10'>Turnaround</h2>
            <p className='metropill-thin font-span text-black'>
              {data?.turnaroundSummary}
            </p>
          </div>
        </div>
    </div>
  )
}

export {SingleViewTabs}