import React, { useEffect, useState } from "react";
import { NewNavbar, BreadCrumbsNew, NewFooter } from "../../components";
import { useParams, useLocation, Link } from "react-router-dom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import PageLoader from "../../components/loader/PageLoader";
import { BloodTestContactForm } from "../BloodTesting/BloodTestcomponents/BloodTestContactForm";
import { Collapse, Typography } from "antd";
import { SingleViewTabsTravel } from "../../components/SingleViewComponents/SingleViewTabs/SingleViewTabsTravel";
import { get } from "../../services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import {addToCart, deleteFromCartById } from "../../redux/slices/CartSlice";
import { message } from "antd";

const { Panel } = Collapse;
const { Text } = Typography;

const TravelClinicDetail = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart?.cartItems) || [];

  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [data, setData] = useState(null);
  const [isAddingCart, setIsAddingCart] = useState(false);
  const [isItemInCart, setIsItemInCart] = useState(false);

  const breadcrumbs = [
    { label: "Home", path: "https://www.beta.myprivatechemist.com/" },
    {
      label: "Travel Clinic",
      path: "https://www.beta.myprivatechemist.com/travel-clinic/",
    },
    {
      label: 'Vaccination By Diseases',
      path: `https://www.beta.myprivatechemist.com/travel-clinic/vaccinations-by-diseases`,
    },
    { label: data?.name },
  ];

  useEffect(() => {
    fetchData();
  }, [slug]);
  useEffect(() => {
    console.log("Data State:", data);
  }, [data]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsNotFound(false);

    try {
      const res = await get(`api/travel-clinic/search-by/${slug}`);
      console.log(res);
      if (res.success) {
        setData(res.data);
        setIsItemInCart(
          cartItems.some((item) => item._id === res.data._id)
        );
      setIsLoading(false);
      setIsNotFound(false);

      } else {
        setData(null);
        setIsLoading(false);
        setIsNotFound(true);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
      setData(null);
      setIsNotFound(true);
    } 
  };

  const AddingToCart = (item) => {
    try {
      setIsAddingCart(true);
      const product = {
        _id: item._id,
        name: item.name,
        unique_code: "",
        price: item.price,
        type: "",
        section: "travel-clinic",
        serviceId: item?.serviceId,
        serviceAdonsId: "",
      };
      dispatch(addToCart(product));
      setIsItemInCart(true);
      message.success("Added to cart");
    } catch (error) {
      console.error(error);
      message.error("Failed to add to cart");
    } finally {
      setIsAddingCart(false);
    }
  };

  const removeFromCart = (cartItemId) => {
    dispatch(deleteFromCartById(cartItemId));
    setIsItemInCart(false);
  };

  return (
    <div className="">
      {isLoading && <PageLoader />}
      <div className="breadcrumnb-img-bg">
        <div className="container">
          <NewNavbar />
          <BreadCrumbsNew breadcrumbs={breadcrumbs} title={data?.name} />
        </div>
      </div>
      {!isLoading && data && (
      <div className="container travel-detail-container my-lg-20 my-10">
        <div className="row">
          <div className="col-lg-5">
            <div className="h-575">
              <img
                src="/assets/icons/single.jpg"
                className="img-fluid h-100 w-100"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="Card border-0 bg-transparent h-100">
              <div className="card-body">
                <h1 className="main-text fs-45 m-lg-0 mt-8 text-center text-lg-start">
                  £ {data?.price}
                </h1>
                <p className="metropill fs-6 my-10 my-md-13">{data?.shortDescription}</p>
              </div>
              <div className="card-add-wrap-travel">
                {/* <label htmlFor="">Dose:</label> */}
                <div className="card-footer justify-content-center justify-content-lg-start d-flex">
                  {/* <div className="input-wrap-quant">
                    <input type="number" className="form-control" value="1" />
                  </div> */}

                  <button
                    type="button"
                    className="btncust btn-pad w-auto metropill m-0"
                    disabled={isAddingCart}
                    onClick={() =>
                      isItemInCart
                        ? removeFromCart(data?._id)
                        : AddingToCart(data)
                    }
                  >
                    {isAddingCart
                      ? "PLEASE WAIT..."
                      : isItemInCart
                      ? "REMOVE FROM BASKET"
                      : "ADD TO BASKET"
                    }
                  </button>
                </div>
              </div>
              <div className="mt-md-15 mt-10">
                <ul className="navbar-nav flex-column gap-2 align-items-md-start align-items-center list-detail-page">
                  <li>
                    <div className="d-flex gap-5 align-items-center">
                      <img
                        src="/assets/icons/plus-thin.png"
                        width={10}
                        alt=""
                      />
                      <span className="fs-16 metropill-thin">
                        {data.points.point1}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex gap-5 align-items-center">
                      <img
                        src="/assets/icons/plus-thin.png"
                        width={10}
                        alt=""
                      />
                      <span className="fs-16 metropill-thin">
                      {data.points.point2}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex gap-5 align-items-center">
                      <img
                        src="/assets/icons/plus-thin.png"
                        width={10}
                        alt=""
                      />
                      <span className="fs-16 metropill-thin">
                        {data.points.point3}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-12 tabs-travel-detail">
            <SingleViewTabsTravel data={data} />
          </div>
        </div>
      </div>
      )}
        <BloodTestContactForm />
      

      <NewFooter />
    </div>
  );
};

export { TravelClinicDetail };
