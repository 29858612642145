import React, { useEffect, useState } from "react";
import { NewNavbar, BreadCrumbsNew, NewFooter } from "../../components";
import { SingleViewTabs } from "../../components/SingleViewComponents";
import { useParams, useLocation, Link } from "react-router-dom";
import { get } from "../../services/ApiService";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { BloodTestContactForm } from "./BloodTestcomponents/BloodTestContactForm";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, deleteFromCartById } from "../../redux/slices/CartSlice";
import { message } from "antd";
import PageLoader from "../../components/loader/PageLoader";

const BloodTestDetail = () => {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);
  const [isItemInCart, setIsItemInCart] = useState(false);

  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart?.cartItems) || [];
  const [updatedCartItems, setUpdatedCartItems] = useState(cartItems);

  const [isAddingCart, setIsAddingCart] = useState(false);

  const breadcrumbs = [
    { label: "Home", path: "https://www.beta.myprivatechemist.com/" },
    {
      label: "Blood Testing",
      path: "https://www.beta.myprivatechemist.com/blood-testing/",
    },
    { label: "A-Z Tests", path: "/a-z-tests" },
    { label: data?.name },
  ];

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const res = await get(`api/blood-tests/${slug}`);
      if (res.success) {
        setData(res.data);
        setIsItemInCart(
          updatedCartItems.some((item) => item._id === res.data._id)
        );
        setIsLoading(false);
        setNotFound(false);
      } else {
        setIsLoading(false);
        setNotFound(true);
      }
    } catch (error) {
      setIsLoading(false);
      setNotFound(true);
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [slug]);

  function truncateText(text, wordLimit) {
    if (typeof text !== "string" || typeof wordLimit !== "number") {
      return "";
    }

    if (text.length > wordLimit) {
      let truncated = text.slice(0, wordLimit);
      const lastSpaceIndex = truncated.lastIndexOf(" "); // Find the last space
      if (lastSpaceIndex !== -1) {
        truncated = truncated.slice(0, lastSpaceIndex); // Remove the incomplete word
      }
      return truncated;
    }
    return text.trim(); // Trims even if it's shorter than the limit
  }

  const AddingToCart = (item) => {
    try {
      setIsAddingCart(true);
      const product = {
        _id: item._id,
        name: item.name,
        unique_code: item.unique_code,
        price: item.price,
        type: item.type,
        section: "blood-test",
        serviceId: item?.serviceId,
        serviceAdonsId: item?.serviceAdonsId,
      };
      dispatch(addToCart(product));
      setIsItemInCart(true);
      message.success("Added to cart");
    } catch (error) {
      console.error(error);
      message.error("Failed to add to cart");
    } finally {
      setIsAddingCart(false);
    }
  };

  const removeFromCart = (cartItemId) => {
    dispatch(deleteFromCartById(cartItemId));
    setIsItemInCart(false);
  };

  return (
    <div className="">
      {isLoading && <PageLoader />}
      <div className="breadcrumnb-img-bg">
        <div className="container">
          <NewNavbar />
          <BreadCrumbsNew breadcrumbs={breadcrumbs} title={data?.name} />
        </div>
      </div>
      {!isLoading && !isNotFound && data && (
        <div className="container my-lg-20 my-10">
          <div className="row">
            <div className="col-lg-5">
              <div className="h-575">
                <img
                  src="/assets/icons/single.jpg"
                  className="img-fluid h-100 w-100"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="Card border-0 bg-transparent h-100">
                <div className="card-body">
                  <h1 className="main-text fs-45 m-lg-0 mt-8 text-center text-lg-start">
                    £ {data?.price}
                  </h1>
                  <p className="metropill fs-6 my-10 my-md-13 text-justify">
                    {truncateText(data?.shortDescription, 250)}
                  </p>
                </div>
                <div className="card-footer justify-content-center justify-content-lg-start d-flex">
                  <button
                    type="button"
                    className="btncust btn-pad w-auto metropill m-0"
                    disabled={isAddingCart}
                    onClick={() =>
                      isItemInCart
                        ? removeFromCart(data?._id)
                        : AddingToCart(data)
                    }
                  >
                    {isAddingCart
                      ? "PLEASE WAIT..."
                      : isItemInCart
                      ? "REMOVE FROM BASKET"
                      : "ADD TO BASKET"}
                  </button>
                </div>
                <div className="mt-md-15 mt-10">
                  <ul className="navbar-nav flex-column gap-2 align-items-md-start align-items-center">
                    <li>
                      <div className="d-flex gap-5 align-items-center">
                        <img
                          src="/assets/icons/plus-thin-p.png"
                          width={10}
                          alt=""
                        />
                        <span className="fs-16 metropill-thin">
                            Turnaround: {data.turnaround} 
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex gap-5 align-items-center">
                        <img
                          src="/assets/icons/plus-thin-p.png"
                          width={10}
                          alt=""
                        />
                        <span className="fs-16 metropill-thin">
                            Categories: {data.category && data.category.length > 0 
                                ? data.category.map((cat, index) => (
                                    <span key={index}>
                                        <Link className="metropill-thin" to={`/a-z-tests?category=${cat.slug}`} style={{textDecoration:'none'}}>{cat.name}</Link>
                                        {index < data.category.length - 1 ? ', ' : ''}
                                    </span>
                                    ))
                                : <Link className="fs-16 metropill-thin" to="/a-z-tests" style={{textDecoration:'none'}}>All Blood Tests</Link>}
                            

                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex gap-5 align-items-center">
                        <img
                          src="/assets/icons/plus-thin-p.png"
                          width={10}
                          alt=""
                        />
                        <span className="fs-16 metropill-thin">
                          Biomarkers: {data.type === "biomarker" ? '1':data.biomarkers.length}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <SingleViewTabs data={data} />
            </div>
          </div>
        </div>
      )}

      <BloodTestContactForm />
      <NewFooter />
    </div>
  );
};

export { BloodTestDetail };
