import React, { useState } from "react";
import "./index.css";
import { HeaderForm } from "../../pages/consultation/HeaderForm";
import { post } from "../../services/ApiService";
import { Link } from "react-router-dom";
import { notification } from "antd";

const ForgetPassword = () => {
  const [formData, setFormData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if the form has been submitted

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await post("api/auth/forget-password", { email: formData.email });
      if (response.success) {
        // notification.success({
        //   message: "Reset Link Sent",
        //   description: "A password reset link has been sent to your email address.",
        //   placement: "topRight",
        // });
        setIsSubmitted(true); // Update state to show the success message
      } else {
        notification.error({
          message: "Error",
          description: response.error || "Failed to send reset link. Please try again.",
          placement: "topRight",
        });
      }
    } catch (err) {
      notification.error({
        message: "Error",
        description: "An error occurred. Please try again.",
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const onBack = () => {
    window.history.back();
  };

  return (
    <div className="min-height">
      <HeaderForm />
      <div className="justify-content-md-center row g-3 m-0">
        <div className="col-12">
          {!isSubmitted ? (
            <form className="w-form fade-in" onSubmit={onSubmit}>
              <div className="row g-4">
                <div className="col-12">
                  <h5 className="mb-5">Forgot Password</h5>
                  <p className="text-muted">
                    Enter your email to receive a password reset link.
                  </p>
                </div>
                <div className="col-lg-12">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    style={{ fontFamily: "metropillthin" }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <button
                    type="button"
                    className="border-0 bg-transparent"
                    onClick={onBack}
                  >
                    <img src="/assets/images/backicon.jpg" width={35} alt="Back" />
                  </button>
                  <button
                    type="submit"
                    className="btncust w-auto activebg cursor-pointer"
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "Send Reset Link"}
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <div className="w-form fade-in success-message">
              <h5>Password Reset Request Sent</h5>
              <p className="text-muted">
                We've sent a password reset link to your email. Please check your inbox and follow the instructions to reset your password.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
