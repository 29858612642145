import React, { useState } from 'react';
import ConsultationCheck from './ConsultationCheck';
import SignUpForm from './SignUpForm';
import DeliveryOptions from './DeliveryOptions';
import NoticeNewStep from './NoticeNewStep';
import { HeaderForm } from '../../pages/consultation/HeaderForm';

const ConsultationFlow = () => {
  const [step, setStep] = useState(1);
  const [consultation, setConsultation] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: ''
  });
  const [deliveryOption, setDeliveryOption] = useState('');
  const [previousStep, setPreviousStep] = useState(1);

  const handleNext = () => {
    if (consultation === 'yes') {
      setPreviousStep(2);
      setStep(2);
    } else {
      setPreviousStep(3);
      setStep(3);
    }
  };

  const handleBack = () => {
    if (step === 4) {
      setStep(previousStep);
    } else if (step === 3 || step === 2) {
      setStep(1);
      setConsultation('');
    } else {
      setStep(step - 1);
    }
  };

  const handleNoticeNext = () => {
    setPreviousStep(2);
    setStep(4);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setPreviousStep(3);
    setStep(4);
  };

  const handleDeliverySubmit = (e) => {
    e.preventDefault();
    // Handle final submission
    console.log({
      consultation,
      formData,
      deliveryOption
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="min-height">
      {/* <HeaderForm /> */}
      <div className="justify-content-md-center row g-3 m-0">
        <div className="col-12">
          {step === 1 && (
            <ConsultationCheck 
              consultation={consultation}
              setConsultation={setConsultation}
              onNext={handleNext}
            />
          )}
          
          {step === 2 && consultation === 'yes' && (
            <NoticeNewStep 
              onBack={handleBack}
              onSubmit={handleNoticeNext}
            />
          )}
          
          {step === 3 && (
            <SignUpForm 
              formData={formData}
              handleInputChange={handleInputChange}
              onBack={handleBack}
              onSubmit={handleFormSubmit}
            />
          )}
          
          {step === 4 && (
            <DeliveryOptions 
              deliveryOption={deliveryOption}
              setDeliveryOption={setDeliveryOption}
              onBack={handleBack}
              onSubmit={handleDeliverySubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsultationFlow; 