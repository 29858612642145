import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  heightUnit: 'imperial',
  weightUnit: 'metric',
  heightCm:"0",
  heightFeet: "0",
  heightInches: "0",
  weightKg:"0",
  weightStones: "0",
  weightPounds: "0",
  bmiCategory: null,
  bmiValue: 0,
}

const bmiSlice = createSlice({
  name: 'bmi',
  initialState,
  reducers: {
    setBmiValues: (state, action) => {
      state.heightUnit = action.payload.heightUnit;
      state.weightUnit = action.payload.weightUnit;
      state.heightCm = action.payload.heightCm;
      state.heightFeet = action.payload.heightFeet;
      state.heightInches = action.payload.heightInches;
      state.weightKg = action.payload.weightKg;
      state.weightStones = action.payload.weightStones;
      state.weightPounds = action.payload.weightPounds;
      state.bmiCategory = action.payload.bmiCategory;
      state.bmiValue = action.payload.bmiValue;
    },
    resetBmiValues: (state) => {

      state.heightUnit = "imperial";
      state.weightUnit = "metric";
      state.heightCm = "0";
      state.heightFeet = "0";
      state.heightInches = "0";
      state.weightKg = "0";
      state.weightStones = "0";
      state.weightPounds = "0";
      state.bmiCategory = null;
      state.bmiValue = 0;
    },
  },
});

export const { setBmiValues, resetBmiValues } = bmiSlice.actions;

// Export selector for getting the BMI data
export const bmiData = (state) => state.bmi;

export default bmiSlice.reducer;