import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ProductsGuard = ({ children }) => {
  const consultation = useSelector((state) => state.consultation);
  const navigate = useNavigate();

  useEffect(() => {
    if (!consultation.answers || Object.keys(consultation.answers).length === 0) {
      navigate("/consultation/weight-loss"); 
    }
  }, [consultation, navigate]);

  return consultation.answers && Object.keys(consultation.answers).length > 0 ? children : null;
};

export default ProductsGuard;
