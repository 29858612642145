import React, { useState } from "react";
import "./index.css";

const DeliveryOption = ({setDeliveryType, deliveryType, setIsConsultationEnable, setIsDeliveryOpEnable,setIsAttachmentEnable, setFadeClass, onSubmit}) => {

  const handleBack = () => {
    setFadeClass("fade-out");
    setTimeout(() => {
      setIsDeliveryOpEnable(false);
      setIsConsultationEnable(true);
      setFadeClass("fade-in");
    }, 300);
  };

  const handleNext = () => {
    if(deliveryType === "delivery"){
        setFadeClass("fade-out");
        setTimeout(() => {
          setIsDeliveryOpEnable(false);
          setIsConsultationEnable(false);
          setIsAttachmentEnable(true);
          setFadeClass("fade-in");
        }, 300);
    }else{
        onSubmit();
    }

  };

  return (
    <div>
      <div className="row g-5">
        <div className="col-lg-12">
          <h5 className="mb-5">
            How would you like to receive your medication?
          </h5>
          <p>Please select your preferred delivery option.</p>
        </div>
        <div className="col-lg-12">
          <input
            type="radio"
            id="option-clinic"
            name="delivery"
            value="clinic"
            checked={deliveryType === "clinic"}
            onChange={(e) => setDeliveryType(e.target.value)}
            className="d-none"
          />
          <label
            className={`padc bullet w-100 d-flex align-items-center gap-3 ${
                deliveryType === "clinic" ? "active-check" : ""
            }`}
            htmlFor="option-clinic"
          >
            <div className="label-cs"></div>
            <span className="metropill">Collect from Clinic</span>
          </label>
        </div>
        <div className="col-lg-12">
          <input
            type="radio"
            id="option-delivery"
            name="delivery"
            value="delivery"
            checked={deliveryType === "delivery"}
            onChange={(e) => setDeliveryType(e.target.value)}
            className="d-none"
          />
          <label
            className={`padc bullet w-100 d-flex align-items-center gap-3 ${
                deliveryType === "delivery" ? "active-check" : ""
            }`}
            htmlFor="option-delivery"
          >
            <div className="label-cs"></div>
            <span className="metropill">Home Delivery</span>
          </label>
          {deliveryType === "delivery" && (
                    <div className="col-12">
                    <div className="pre-message-box" >
                        <p>Due to regulations, you'll need to upload verification images as required in the next step.</p>
                    </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-30 p-0 card-footer bg-transparent border-0 d-flex align-items-center justify-content-between gap-2">
        <button
          type="button"
          className="border-0 bg-transparent"
          onClick={handleBack}
        >
          <img src="/assets/images/backicon.jpg" width={35} alt="Back" />
        </button>
        <button
          type="button"
          className={
            !deliveryType
              ? "btn-disable w-auto activebg"
              : "btncust w-auto activebg cursor-pointer"
          }
          disabled={!deliveryType}
          onClick={handleNext}
        >
          {deliveryType === "clinic" ? 'Submit':'Next'}
        </button>
      </div>
    </div>
  );
};

export default DeliveryOption;
