import React from "react";

const QuestionOptions = ({
  question,
  answer,
  handleAnswerChange
}) => {
  return question.options.map((opt, index) => {
    return (
      <div className="col-lg-12 " key={index}>
        <input
          type={question.inputType}
          id={`option-${index}-${question._id}`}
          name={`question-${question._id}`}
          value={opt._id}
          className="d-none"
        />
        <label
          className={
            answer?.options?.includes(opt._id)
              ? "padc bullet active-check w-100 d-flex align-items-center gap-3"
              : "padc bullet w-100 d-flex align-items-center gap-3"
          }
          onClick={() => handleAnswerChange(opt._id, "", "")}
          htmlFor={`option-${index}-${question._id}`}
        >
          <div
            className={`${
              question.inputType === "radio" || question.inputType === "select"
                ? "label-cs"
                : "label-check flex-shrink-0"
            }`}
          ></div>
          <span className="metropill">{opt.optionText}</span>
        </label>
      </div>
    );
  });
};

export default QuestionOptions;
