import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../../utils/Helper';
import Thankyou from './Thankyou';

const NoSuitableTreatment = ({ error, setIsUnsuitable, currentStep }) => {
    const [isChecked, setIsChecked] = useState(false);

  const handleReviewAnswers = () => {
    setIsUnsuitable(false);
  };
    useEffect(() => {
      ScrollToTop();
    }, []);

  return (
    <div className="not-eligible-container my-0 py-0">
    <div className="content-wrapper">
      <h5 className="titles d-flex align-items-center gap-3 justify-content-center"> <div className="error-icon">
        <svg width="40" height="40" viewBox="0 0 40 40">
          <circle cx="20" cy="20" r="19" stroke="currentColor" strokeWidth="2" fill="none"/>
          <path d="M13 13 L27 27 M27 13 L13 27" stroke="currentColor" strokeWidth="2"/>
        </svg>
    </div> Not Eligible for treatment</h5>
      <div className="message-box">
        {/* <p className="main-message">
          Based on our assessment, collection from clinic is not available for this treatment. 
          Please choose home delivery option for a safer and more convenient service.
        </p> */}
        <div className="warning-box">
          <p className="warning-text">
            Did you make a mistake in your consultation? Providing false information can 
            lead to serious harm to your health.
          </p>
        </div>
      </div>
      <div className="action-box">
        <div className="checkbox-wrapper">
          <input 
            type="checkbox" 
            id="agree" 
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="custom-checkbox"
          />
          <label htmlFor="agree" className="checkbox-label">
            I understand and wish to review my options
          </label>
        </div>
        <button 
          className="review-button"
          onClick={handleReviewAnswers}
          disabled={!isChecked}
        >
          Go Back & Review Options
        </button>
        <Link to="/" className="home-link">
          Return to Homepage
        </Link>
      </div>
    </div>
  </div>
  );
};

export default NoSuitableTreatment; 