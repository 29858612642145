import React, { useState, useEffect, useRef } from "react";
import { post, get } from "../../services/ApiService.js";
import TimeSlotList from "./TimeSlots";
import BillingDetail from "./BillingDetail.jsx";
import BookingDatePicker from "./BookingDatePicker.jsx";
import OrderSummary from "./OrderSummary.jsx";
import PaymentComponent from "./PaymentComponent.jsx";
import PageLoader from "../../components/loader/PageLoader";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { NewNavbar, BreadCrumbsNew, NewFooter } from "../../components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";

const Checkout = () => {
  const user = useSelector((state) => state.user.user);
  const { cartItems } = useSelector((state) => state.cart);
  const [serviceCartItems, setServiceCartItems] = useState([]);
  const isBloodDraw = cartItems.some((item) => item.section === "blood-test");
  const istravelClinic = cartItems.some((item) => item.section === "travel-clinic");
  const [form, setForm] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(true);
  const containerRef = useRef(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isTimeSlotErr, setIstimeSlotErr] = useState(false);
  const timeSlotsRef = useRef(null);
  const [filteredTimeSlots, setFilteredTimeSlots] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const [selectedDate, setSelectedDate] = useState( moment().format("DD/MM/YYYY"));
  const [isSubmit, setIsSubmit] = useState(false);
  const [dateSelectLoading, setDateSelectLoading] = useState(false);
  const [isOver18, setIsOver18] = useState(true);
  const [inputErrors, setInputErrors] = useState({});
  const firstErrorRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nineDigitRegex = /^\d{9,}$/;
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: user?.email || "",
    phone: "",
    dob: "",
    gender: "",
    date: "",
    time: "",
  });

  useEffect(() => {
    if (user && user.dob) {
      const selectedDate = new Date(user.dob);
      const today = new Date();
      const age = today.getFullYear() - selectedDate.getFullYear();
      const monthDiff = today.getMonth() - selectedDate.getMonth();
      const over18 = age > 18 || (age === 18 && monthDiff >= 0);
      setIsOver18(over18);
      const finaldDate = new Date(user.dob).toISOString().split("T")[0];
      handleInputChange({ target: { name: "dob", value: finaldDate } });
    }
    if (user && user.name) {
      const nameParts = user.name.trim().split(" ");
      const firstName = nameParts[0] || "";
      const lastName = nameParts.slice(1).join(" ") || "";

      setFormData((prev) => ({
        ...prev,
        firstname: firstName,
        lastname: lastName,
      }));
    }
  }, [user]);

  const servicesItems = async () => {
    const sections = ["blood-test", "travel-clinic", "weight-loss"];

    const filteredItems = sections.map((section) => {
      return cartItems.find((item) => item.section === section);
    });

    const validItems = filteredItems.filter((item) => item !== undefined);

    return validItems;
  };

  useEffect(() => {
    fetchTimeSlots();
  }, [startDate]);

  useEffect(() => {
    fetchVaccineConsultation();
  }, [istravelClinic]);

  const fetchVaccineConsultation = async () => {
    try {
      setIsLoading(true);
      const res = await get("api/travel-clinic/consultation");
      setIsLoading(false);
      if (res.success) {
        let assessmentForm = res.form;
        setForm(assessmentForm);
        setQuestions(assessmentForm.questions);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchTimeSlots = async () => {
    try {
      setDateSelectLoading(true);
      const items = await servicesItems();
      const res = await post("api/booking/time-slots", {
        services: items,
        providerId: 2,
        date: startDate,
      });
      if (res.success && res.slots) {
        setTimeSlots(res.slots);
      } else {
        setTimeSlots([]);
      }
      setDateSelectLoading(false);
    } catch (error) {
      setTimeSlots([]);
      setDateSelectLoading(false);
    }
  };

  const handleDateSelect = async (date) => {
    setStartDate(date);
    setDateSelectLoading(true);
    setIstimeSlotErr(false);
    setSelectedTimeSlot(null);
    setTimeSlots([]);

    const formattedDateUk = moment(date).format("DD/MM/YYYY");
    setSelectedDate(formattedDateUk);
    handleInputChange({ target: { name: "date", value: date } });

    if (timeSlotsRef.current) {
      timeSlotsRef.current.scrollIntoView({ behavior: "smooth" });
    }

  };

  const handleTimeSlotSelect = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setInputErrors({ ...inputErrors, [name]: "" });
  };

  const validateAge = (e) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    const age = today.getFullYear() - selectedDate.getFullYear();
    const monthDiff = today.getMonth() - selectedDate.getMonth();
    const isOver18 = age > 18 || (age === 18 && monthDiff >= 0);
    const formattedDate = selectedDate.toISOString().split("T")[0];
    setIsOver18(isOver18);

    handleInputChange({ target: { name: "dob", value: formattedDate } });
  };

  const validateFormData = () => {
    const errors = {};

    // Check for required fields
    Object.keys(formData).forEach((key) => {
      if (
        !["date", "time", "userToken", "slots"].includes(key) &&
        !formData[key]
      ) {
        errors[key] = "This field is required";
      }
    });

    // Additional validations
    if (formData.email && !emailRegex.test(formData.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (formData.phone && !nineDigitRegex.test(formData.phone)) {
      errors.phone = "Phone number must be at least 9 digits";
    }

    return errors;
  };

  const validateCheckoutForm = () => {
    const newErrors = validateFormData();
    setInputErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      const firstErrorKey = Object.keys(newErrors)[0];
      firstErrorRef.current[firstErrorKey].focus();
      setIsSubmit(false);
      return false;
    }

    if (!isOver18) {
      setIsSubmit(false);
      return false;
    }

    if (!selectedTimeSlot) {
      setIstimeSlotErr(true);
      if (timeSlotsRef.current) {
        timeSlotsRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setIsSubmit(false);
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);

    const newErrors = validateFormData();

    setInputErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      const firstErrorKey = Object.keys(newErrors)[0];
      firstErrorRef.current[firstErrorKey].focus();
      setIsSubmit(false);
      return;
    }

    if (!isOver18) {
      setIsSubmit(false);
      return;
    }

    if (!selectedTimeSlot) {
      setIstimeSlotErr(true);
      if (timeSlotsRef.current) {
        timeSlotsRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setIsSubmit(false);
      return;
    }

    try {
      const res = await post("api/booking/create", formData);
      console.log(res);
      if (res.success) {
        setIsSubmit(false);
      } else {
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error
    } finally {
      setIsSubmit(false);
    }
  };

  const calculateTotals = () => {
    
    if (!cartItems || cartItems.length === 0) return { subtotal: 0, total: 0, totalDiscount: 0 };
  
    let totalDiscount = 0;
    let subtotal = 0;
    const FEE = 30;
    
  
    let bloodTestCount = 0; // Counter to track first blood test
  
    cartItems.forEach((item, index) => {
      let discount = 0;
  
      if (item.section === "blood-test") {
        if (bloodTestCount === 0) {
          discount = 0;
          bloodTestCount++; 
        } else {
          discount = 10; 
        }
      }
  
      const itemSubtotal = Number(item.price) || 0;  
      const itemDiscount = Number(discount) || 0;
  
      subtotal += itemSubtotal;
      totalDiscount += itemDiscount;
    });
  
    const total = subtotal - totalDiscount + (isBloodDraw ? FEE : 0);
  
    return { subtotal, total, totalDiscount };
  };

  const { subtotal, total, totalDiscount } = calculateTotals();

  const breadcrumbs = [
    { label: "Home", path: "https://www.beta.myprivatechemist.com/" },
    { label: "Checkout" },
  ];

  return (
    <>
      <Elements stripe={stripePromise}>
        <main id="content" className="wrapper layout-page">
          <div className="breadcrumnb-img-bg">
            <div className="container">
              <NewNavbar />
              <BreadCrumbsNew breadcrumbs={breadcrumbs} title={"Checkout"} />
            </div>
          </div>
          <div className="container">
            {isLoading && <PageLoader />}
            {cartItems.length > 0 && (
              <div
                className="row"
                style={{ marginTop: "50px", paddingBottom: "50px" }}
              >
                <div className="col-12">
                  <h4 style={{ marginBottom: "40px", marginTop: "40px" }}>
                    Billing Details
                  </h4>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <BillingDetail
                    formData={formData}
                    handleInputChange={handleInputChange}
                    inputErrors={inputErrors}
                    validateAge={validateAge}
                    isOver18={isOver18}
                    firstErrorRef={firstErrorRef}
                  />
                  <h4
                    className=""
                    style={{ marginBottom: "40px", marginTop: "40px" }}
                  >
                    Select Booking Date & Time
                  </h4>
                  {isTimeSlotErr && (
                    <p
                      className="mb-5"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      <i className="fa fa-warning"></i> Please choose time for
                      your appointment
                    </p>
                  )}
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <BookingDatePicker
                        startDate={startDate}
                        handleDateSelect={handleDateSelect}
                        currentDate={new Date()}
                        containerRef={containerRef}
                        isOpen={isOpen}
                      />
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 timeslot-section"
                      ref={timeSlotsRef}
                    >
                      {dateSelectLoading ? (
                        <div
                          className="col-12 d-flex justify-content-center align-items-center"
                          style={{ height: "30vh" }}
                        >
                          <Spinner
                            variant="warning"
                            animation="grow"
                            size="xl"
                            className="mr-2"
                          />
                        </div>
                      ) : (
                        <>
                          <div>
                            <TimeSlotList
                              selectedTimeSlot={selectedTimeSlot}
                              selectedDate={selectedDate}
                              handleTimeSlotSelect={handleTimeSlotSelect}
                              timeSlots={timeSlots}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  {/* <Elements stripe={stripePromise}> */}
                  <OrderSummary
                    handleSubmit={handleSubmit}
                    cartItems={cartItems}
                    subtotal={subtotal}
                    totalDiscount = {totalDiscount}
                    total={total}
                    isSubmit={isSubmit}
                    isBloodDraw={isBloodDraw}
                    filteredTimeSlots={filteredTimeSlots}
                    selectedDate={selectedDate}
                    selectedTimeSlot={selectedTimeSlot}
                    validateCheckoutForm={validateCheckoutForm}
                  />
                    <PaymentComponent
                      formData={formData}
                      selectedDate={selectedDate}
                      selectedTimeSlot={selectedTimeSlot}
                      cartItems={cartItems}
                      total = {total}
                      validateCheckoutForm={validateCheckoutForm}
                      istravelClinic = {istravelClinic}
                      form = {form}
                      questions = {questions}
                      setIsLoading = {setIsLoading}
                    />
  

                </div>
              </div>
            )}
            {cartItems.length == 0 && (
              <div
                className="row p-5"
                style={{ marginTop: "100px", marginBottom: "100px" }}
              >
                <div className="col-lg-2 col-12"></div>
                <div className="col-lg-4 col-12 ">
                  <img
                    src="/assets/images/empty-cart.png"
                    alt=""
                    style={{ width: "250px" }}
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <div>
                    <h2 className="mt-lg-0 mt-10 mb-10">Sorry...</h2>
                    <p className="error-desc metropill mb-12">
                      Your cart is currently empty. We invite you to explore our
                      available services to find the perfect option for you.
                    </p>
                    <Link
                      to={"https://www.beta.myprivatechemist.com"}
                      className="btncust btn-pad w-auto d-inline-block text-decoration-none metropill"
                    >
                      Explore
                    </Link>
                  </div>
                </div>
                <div className="col-lg-2 col-12"></div>
              </div>
            )}
          </div>
          <NewFooter />
        </main>
      </Elements>
    </>
  );
};

export default Checkout;
