import React from "react";
import QuestionInput from "./QuestionInput";
import NoSuitableTreatment from "./NoSuitableTreatment";

const FormQuestion = ({
  current,
  setCurrent,
  setFadeClass,
  formData,
  questions,
  answers,
  explanations,
  onChange,
  onExpChange,
  error,
  setIsUnsuitable,
  isUnsuitable,
  setIsBmi,
  setIsConsultationEnable,
  setIsDeliveryOpEnable,
}) => {
  const question = questions[current];
  const answer = answers[current];

  const handleNext = () => {
    if (current < questions.length - 1) {
      // Go to the next question
      setFadeClass("fade-out");
      setTimeout(() => {
        setCurrent((prev) => prev + 1);
        setFadeClass("fade-in");
      }, 300);
    } else {
      // Complete the form (last question)
      setFadeClass("fade-out");
      setTimeout(() => {
        setIsConsultationEnable(false);
        setIsDeliveryOpEnable(true);
        setFadeClass("fade-in");
      }, 300);
    }
  };

  const handlePrevious = () => {
    if (current === 0 && formData.requiresBMI) {
      setFadeClass("fade-out");
      setTimeout(() => {
        setIsBmi(true);
        setFadeClass("fade-in");
      }, 500);
    } else {
      setFadeClass("fade-out");
      setTimeout(() => {
        setCurrent((prev) => {
          const newCurrent = prev - 1;
          return newCurrent;
        });
        setFadeClass("fade-in");
      }, 500);
    }
  };

  const isNextButtonDisabled = () => {
    const question = questions[current];

    if (!question) {
      console.error("Question not found");
      return true;
    }
    const currentAnswer = answers[current];

    const isAnswered =
      ((question.inputType === "radio" ||
        question.inputType === "select" ||
        question.inputType === "checkbox") &&
        currentAnswer?.options?.length > 0) ||
      (question.inputType === "text" &&
        currentAnswer?.description?.trim().length > 0);

    const hasCorrectOptions = question.options.some(
      (option) => option.isCorrectAnswer === true
    );

    const requiresExplanation =
    (question.inputType !== "text") &&
    currentAnswer?.options?.some(
      (optionId) =>
        question.options.find((option) => option._id === optionId)
          ?.requireExplanation
    );

  const hasExplanation = explanations.some(
    (explanation) =>
      explanation._id === question._id &&
      explanation.text?.trim() !== ""
  );

  if (requiresExplanation && !hasExplanation) {
    return true;
  }

    let hasIncorrectOption = false;

    if (hasCorrectOptions) {
      hasIncorrectOption = currentAnswer?.options?.some(
        (optionId) =>
          question.options.find((option) => option._id === optionId)
            ?.isCorrectAnswer === false
      );
    }

    return !isAnswered || hasIncorrectOption;
  };

  if (isUnsuitable) {
    return (
      <NoSuitableTreatment
        error={error}
        setIsUnsuitable={setIsUnsuitable}
        currentStep={current}
      />
    );
  }

  return (
    <>
      <div className="row g-5">
        <div className="col-12">
          <h5 className="mb-5">{question.title}</h5>
          <p>{question.description}</p>
        </div>
        <QuestionInput
          question={question}
          answer={answer}
          handleAnswerChange={onChange}
        />
      </div>
      {explanations.some((ex) => ex._id === question._id) && (
        <div className="col-12" style={{marginTop:'40px'}}>
            <textarea
              placeholder="Please specify more..."
              className="form-control mt-4"
              rows={5}
              value={
                explanations.find((ex) => ex._id === question._id)?.text || ""
              }
              onChange={(e) => onExpChange(question._id, e.target.value)}
            />
        </div>
      )}
      
      <div className="mt-30 p-0 card-footer bg-transparent border-0 d-flex align-items-center justify-content-between gap-2">
        {(current > 0 || (formData.requiresBMI && current === 0)) && (
          <button
            className="border-0 bg-transparent"
            type="button"
            onClick={() => handlePrevious()}
          >
            <img src="/assets/images/backicon.jpg" width={35} alt="Back" />
          </button>
        )}
        <button
          className={
            isNextButtonDisabled()
              ? "btn-disable w-auto activebg"
              : "btncust w-auto activebg"
          }
          type="button"
          onClick={() => handleNext()}
          disabled={isNextButtonDisabled()}
          style={isNextButtonDisabled() ? { cursor: "not-allowed" } : {}}
        >
          {current < questions.length - 1 ? "Next" : "Next"}
        </button>
      </div>
    </>
  );
};

export default FormQuestion;