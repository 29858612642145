import { Button, Form, message, Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import { MyInput, MyInputDark } from '../../../components/Form'
import { post } from '../../../services/ApiService'
import { LoadingOutlined } from '@ant-design/icons';
import { Alert } from "antd";


const BloodTestContactForm = () => {

    const [form] = Form.useForm();
    const [ isloading, setIsLoading ] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    const handleSubmit = async () => {
        let data= form.getFieldsValue()
        try {
            setIsLoading(true)
            setShowSuccess(false)
            const body = {
                name: data?.name,
                email: data?.email,
                note: data?.note,
            }
            const res = await post(`api/booking/mail/contact`,body);
            if (res.success) {
                setShowSuccess(true)
                setIsLoading(false)
                form.resetFields();
            } else {
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error.message);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
          setShowSuccess(false); 
        }, 3000);
    
        return () => clearTimeout(timer);
      }, [showSuccess]);


  return (
    <div className='pb-20'>
        <div className='d-flex parent-form'>
            <div className='img-left-form'>
                <div
                    style={{backgroundImage: 'url(/assets/icons/form-img.jpg)',backgroundSize:'cover'}}
                    className='pos-leftimg'
                ></div>
            </div>
            <div className='container'>
                <div className='py-md-15 py-10 right-form-cs'>
                    <h4 className='text-white'>
                        Have a question? <br /> Drop us a line!
                    </h4>
                    <Form
                        layout='vertical'
                        form={form}
                        className='pt-md-15 pt-10'
                        onFinish={handleSubmit}
                    >   
                        <div className='row'>
                            <div className='col-md-6'>
                                <MyInputDark 
                                    name='name'
                                    required
                                    message='Please enter name'
                                    placeholder='Your Name*'
                                />
                            </div>
                            <div className='col-md-6'>
                                <MyInputDark 
                                    name='email'
                                    required
                                    message='Please enter email'
                                    placeholder='Your Email*'
                                />
                            </div>
                            <div className='col-md-12'>
                                <MyInputDark 
                                    textArea
                                    name='note'
                                    required
                                    message='Please enter your message'
                                    placeholder='Your Message*'
                                    rows={12}
                                />
                            </div>
                            {showSuccess && (
                                <div className='col-12 mt-2 mb-8'>
                                <Alert message="Your message has been sent successfully. We will get back to you soon." type="success" showIcon />
                            </div>
                            )}

                            <div className='col-md-12 text-center'>

                            <button className='btncust-pink btn-pad w-auto metropill h-auto rounded-0' type='submit' disabled={isloading}>
                                {isloading ? (
                                    <>
                                        <Spin className='pe-3' indicator={<LoadingOutlined id='colorindicator' spin />} size='small' />
                                        Please wait...
                                    </>
                                ) : (
                                    "SEND MESSAGE"
                                )}
                            </button>

                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
  )
}

export {BloodTestContactForm}