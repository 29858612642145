import React from 'react'
import Checkout from './pages/checkout/Checkout';
import Cart from './pages/cart/Cart';
import AppointmentSuccess from './pages/AppointmentSuccess';
import AppointmentCancel from './pages/AppointmentCancel';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import BuildMyPkg from './pages/BuildPackage/BuildMyPkg';
import Consultation from './pages/consultation/Consultation';
import PreviewConsultation from './pages/consultationPreview/PreviewConsultation';
import { HealthConcernPage } from './pages/HealthConcernPage/HealthConcernPage';
import { AllBloodTest } from './pages/BloodTesting/AllBloodTest';
import { BloodTestDetail } from './pages/BloodTesting/BloodTestDetail';
import ScrollTop from './components/ScrollTop';
import { WeightLoss } from './pages/WeightLoss/WeightLoss';
import ProductSelection from './pages/ProductSelection/ProductSelection';

import ProductsGuard from './middleware/ProductGuard';
import { AllTravelClinic } from './pages/TravelClinic/AllTravelClinic';
import { TravelClinicDetail } from './pages/TravelClinic/TravelClinicDetail';
import ExistingUserPage from './pages/existingUserPage';

import PatientLogin from './pages/auth/PatientLogin';
import PatientRegister from './pages/auth/PatientRegister';
import VerifyEmail from './pages/auth/VerifyEmail';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';

import WlConsultation from './pages/consultation/WlConsultation';

import PrivateRoute from './routes/PrivateRoute';
import Thankyou from './pages/consultation/components/Thankyou';

const App = () => {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>
        <Route path='/' element={<BuildMyPkg />} />
        <Route path='/a-z-tests' element={<AllBloodTest />} />
        <Route path='/healthconcern' element={<HealthConcernPage />} />
        <Route path='/blood-tests/:slug' element={<BloodTestDetail />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/cart' element={<Cart />} />
        <Route path="/appointment/success/:bookingId/:userToken" element={<AppointmentSuccess />} />
        <Route path='/appointment/cancel' element={<AppointmentCancel />} />
        <Route path='/build-my-package' element={<BuildMyPkg />} />
        <Route path='/consultation/weight-loss' element={<WlConsultation />} />
        <Route path='/consultation/preview/:id' element={<PreviewConsultation />} />
        <Route path='/weight-loss' element={<WeightLoss />} />
        <Route path="/products" element={<ProductsGuard><ProductSelection /></ProductsGuard>} />
        <Route path='/travel-clinic/vaccinations-by-diseases' element={<AllTravelClinic />} />
        <Route path='/travel-clinic/vaccinations-by-diseases/:slug' element={<TravelClinicDetail />} />
        <Route path='/consultation-weight-loss' element={<ExistingUserPage />} />
        <Route path='/login' element={<PatientLogin />} />
        <Route path='/signup' element={<PatientRegister />} />
        <Route path='/verify-email' element={<VerifyEmail />} />
        <Route path='/forget-password' element={<ForgetPassword />} />
        <Route path='/reset-password/:token' element={<ResetPassword />} />
        <Route path='/wl-consultation' element={<WlConsultation />} />
        <Route path='/thanks' element={<Thankyou />} />


      
      </Routes>
    </BrowserRouter>
  )
}

export default App