import React from 'react'
import { NewNavbar, BreadCrumbsNew, NewFooter } from '../../components';
import { TabAzTest } from './BloodTestcomponents/TabAzTest';
import { useLocation } from "react-router-dom";

const AllBloodTest = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramCategory = searchParams.get("category"); // Retrieves 'general-health'
  
  const breadcrumbs = [
    { label: 'Home', path: 'https://www.beta.myprivatechemist.com/' },
    { label: 'Blood Testing', path: 'https://www.beta.myprivatechemist.com/blood-testing/' },
    { label: 'A-Z Tests' },
  ];


  return (
    <div>
        <div className='breadcrumnb-img-bg'>
          <div className='container'>
            <NewNavbar />
            <BreadCrumbsNew breadcrumbs={breadcrumbs} title={'A-Z Tests'} />
          </div>
        </div>
        <div className='container my-5'>
            <TabAzTest paramCategory={paramCategory} />
        </div>
        <NewFooter />
    </div>
  )
}

export {AllBloodTest}