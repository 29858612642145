import React, { useState } from "react";
import "./index.css";
import { HeaderForm } from "../../pages/consultation/HeaderForm";
import { post } from "../../services/ApiService";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { setUser } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

const PatientLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const { redirectTo, redirectFrom } = useSelector((state) => state.redirection);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await post("api/auth/patient/login", formData);
      if (response.success) {

        notification.success({
            message: "Login Successful",
            description: "You have successfully logged in!",
            placement: "topRight",
        });

        dispatch(setUser(response.user));
        navigate(redirectTo);

      } else {
        notification.error({
            message: "Login Failed",
            description: response.error || "Login failed. Please try again.",
            placement: "topRight",
        });
      }
    } catch (err) {
      notification.error({
        message: "Error",
        description: "An error occurred. Please try again.",
        placement: "topRight", // You can adjust the position
      });
    } finally {
      setLoading(false);
    }
  };

  const onBack = () => {
    navigate(redirectFrom || '/wl-consultation');
  };

  return (
    <div className="min-height">
      <HeaderForm />
      <div className="justify-content-md-center row g-3 m-0">
        <div className="col-12">
          <form className="w-form fade-in" onSubmit={onSubmit}>
            <div className="row g-4">
              <div className="col-12">
                <h5 className="mb-5">Login Here</h5>
                <p className="text-muted">
                    Login to access your consultations, medical history, and checkout services.
                </p>
              </div>
              <div className="col-lg-12 input-section">
                <input
                  type="email"
                  className="input_text "
                  placeholder="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  style={{fontFamily:'metropillthin'}}
                />
              </div>
              <div className="col-lg-12 input-section">
                <input
                  type="password"
                  className="input_text metropill"
                  placeholder="******"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                  style={{fontFamily:'metropillthin'}}

                />
              </div>
              <div className="col-12">
                <Link to={`/forget-password`} style={{textDecoration:'none'}}>Forgot your password?</Link>
              </div>
              <div className="col-12 d-flex justify-content-between align-items-center">
                <button
                  type="button"
                  className="border-0 bg-transparent"
                  onClick={onBack}
                >
                  <img
                    src="/assets/images/backicon.jpg"
                    width={35}
                    alt="Back"
                  />
                </button>
                <button
                  type="submit"
                  className="btncust w-auto activebg cursor-pointer"
                  disabled={loading}
                >
                  {loading ? "Please wait..." : "Login"}
                </button>
              </div>
              <div className="col-12 d-flex justify-content-around align-items-center">
                <p className="mt-4">Don't have an account? <Link to={`/signup`} style={{textDecoration:'none'}}>Signup</Link></p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PatientLogin;
