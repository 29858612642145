import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { post } from '../../services/ApiService';
import { resetBmiValues } from '../../redux/slices/BmiSlice';
import { deleteAllFromCart } from '../../redux/slices/CartSlice';
import { resetConsultation } from '../../redux/slices/ConsultationSlice';
import { resetVaccineConsultation } from '../../redux/slices/vaccineConsultationSlice';
import { clearRedirectTo } from '../../redux/slices/redirectionSlice';
import { logout } from '../../redux/slices/userSlice';
import VaccineAssessmentModal from '../TravelClinic/VaccineAssessmentModal';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

const PaymentComponent = ({formData, selectedDate, selectedTimeSlot, cartItems,total, validateCheckoutForm, istravelClinic, form, questions, setIsLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(total || 0);
  const [errors, setErrors] = useState({});
  const user = useSelector((state) => state.user.user);
  const bmi = useSelector((state) => state.bmi);
  const consultation = useSelector((state) => state.consultation);
  const vaccineConsultation = useSelector(state => state.vaccineConsultation);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const redirectUrl = `${process.env.REACT_APP_BASE_URL}/appointment/confirmation`;
  const [showConsultation, setShowConsultation] = useState(false);
  const handleClose = () => setShowConsultation(false);
  const handleShow = () => setShowConsultation(true);

  useEffect(() => {
    if (istravelClinic) {
      setShowPaymentForm(vaccineConsultation.answers.length > 0 && vaccineConsultation.formId);
    } else {
      setShowPaymentForm(true);
    }
  }, [istravelClinic, vaccineConsultation]);

  const createPaymentMethod = async (cardElement, stripe) => {
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (error) throw error;
      return paymentMethod;
    } catch (error) {
      handleError(error);
      return null;
    }
  };

  const createPaymentIntent = async (pmId) => {
    try {
      
        const body = {
            pmId:pmId,
            amount:amount * 100
        };
        const res = await post(`api/booking/create-payment-intent`, body);
        if(res.success){
            return { clientSecret: res.clientSecret, status: res.status };  
        }else{
            throw new Error("Failed to create payment intent"); 
        }
    } catch (error) {
      handleError(error);
      return null;
    }
  };

  const confirmPayment = async (clientSecret, cardElement, stripe) => {
    try {
      const return_url = "https://shop.myprivatechemist.com/checkout";
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        { 
            payment_method: { card: cardElement },
            return_url:return_url
        }
      );
      if (error) throw error;
      return paymentIntent;
    } catch (error) {
      handleError(error);
      return null;
    }
  };

  const handleCardAction = async (clientSecret, stripe) => {
    try {
      const { error, paymentIntent } = await stripe.handleCardAction(
        clientSecret
      );
      if (error) throw error;
      return paymentIntent;
    } catch (error) {
      handleError(error);
      return null;
    }
  };

    
  const handleError = (error) => {
    console.error("[error]", error);
    const errorCode = error?.code || "";
    switch (errorCode) {
      case "incomplete_number":
        setErrors((prev) => ({ ...prev, cardNumber: error.message }));
        break;
      case "incomplete_expiry":
        setErrors((prev) => ({ ...prev, expiry: error.message }));
        break;
      case "incomplete_cvc":
        setErrors((prev) => ({ ...prev, cvc: "CVC INCOMPLETE" }));
        break;
      default:
        setErrors({ general: error.message });
    }
    setLoading(false);
  };

  const handlePayment = async (event) => {
    event.preventDefault();

    if(!validateCheckoutForm()){
      return;
    }
    setErrors({});
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const paymentMethod = await createPaymentMethod(cardElement, stripe);

    if (paymentMethod) {
      const { clientSecret, status } = await createPaymentIntent(paymentMethod.id);
      let paymentIntent;
      if (status === 'requires_confirmation') {
        paymentIntent = await confirmPayment(clientSecret, cardElement, stripe);
        if (paymentIntent?.status === "requires_action") {
            paymentIntent = await handleCardAction(clientSecret, stripe);
        }
        if(paymentIntent.status === 'succeeded'){
            console.log("Payment succeeded!");
            await createOrder(paymentIntent.id);
        }
      }else{
        console.log('payment sucessed')
      }
    }

    setLoading(false);
  };

  const createOrder = async(paymentIntentId) => {
    try {
        const body = {
            userId:user?._id,
            bookingDate:selectedDate,
            bookingTime: selectedTimeSlot,
            products: cartItems,
            consultation: consultation,
            paymentIntentId,
            billingAddress: formData,
            total:amount,
            bmi:{
              height:bmi?.heightCm,
              weight:bmi?.weightKg,
              score:bmi?.bmiValue,
              category:bmi?.bmiCategory
            },
            vaccineConsultation: vaccineConsultation
            
        }
        const res = await post(`api/booking/create`, body);
        if(res.success){
            clearSession(dispatch);
            navigate(`/booking/confirmation/${res.bookingRef}`);
        }else{
            throw new Error("Failed to Charge payment, please try again"); 
        }
    } catch (error) {
      handleError(error);
      return null;
    }
  }

  const clearSession = (dispatch) => {
    dispatch(resetBmiValues());  
    dispatch(deleteAllFromCart()); 
    dispatch(resetConsultation());
    dispatch(resetVaccineConsultation()); 
  };

  const handleCardNumberChange = (event) => {
    if (event.error) {
      setErrors((prev) => ({ ...prev, cardNumber: event.error.message }));
    } else {
      setErrors((prev) => ({ ...prev, cardNumber: null }));
    }
  };
  const handleExpiryChange = (event) => {
    if (event.error) {
      setErrors((prev) => ({ ...prev, expiry: event.error.message }));
    } else {
      setErrors((prev) => ({ ...prev, expiry: null }));
    }
  };
  const handleCvcChange = (event) => {
    if (event.error) {
      setErrors((prev) => ({ ...prev, cvc: event.error.message }));
    } else {
      setErrors((prev) => ({ ...prev, cvc: null }));
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "18px",
        top:'10px',
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    classes: {
      base: "input_text",
    },
    showIcon: true,
  };


  return (
    <>
    {showPaymentForm ? (
    <>
    <div className="d-flex mt-8 text-center mb-5" style={{ marginTop: '30px !important' }}>
      <div className="col-auto me-2">
        <img className="w-60" src="/assets/images/payments/card1.png" alt='' />
      </div>
      <div className="col-auto me-2">
        <img className="w-70" src="/assets/images/payments/card2.png" alt='' />
      </div>
      <div className="col-auto me-2">
        <img className="w-70" src="/assets/images/payments/card3.png" alt='' />
      </div>
      <div className="col-auto me-2">
        <img className="w-70" src="/assets/images/payments/card4.png" alt='' />
      </div>
      <div className="col-auto me-2">
        <img className="w-70" src="/assets/images/payments/card5.png" alt='' />
      </div>
    </div>
    <form onSubmit={handlePayment}>
      <div id="payment-element mt-4">
        <div className='row'>
          <div className='col-12'>
            <div className="form-group">
              <label className="fw-normal metropill">
                Card Number <span class="text-danger">*</span>
              </label>
              <CardNumberElement
                options={cardElementOptions}
                onChange={handleCardNumberChange}
              />
              {errors.cardNumber && (
                <div className="error-message">
                  <p className="error-text">
                    {errors.cardNumber}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className='col-8 mt-4'>
            <div className="form-group">
              <label className="fw-normal metropill">
                Expiry <span class="text-danger">*</span>
              </label>
              <CardExpiryElement
                options={cardElementOptions}
                onChange={handleExpiryChange}
              />{" "}
              {errors.expiry && (
                <div className="error-message">
                  <p className="error-text">
                    {errors.expiry}
                  </p>{" "}
                </div>
              )}
            </div>
          </div>
          <div className='col-4 mt-4'>
            <div className="form-group">
              <label className="fw-normal metropill">
                CVC <span class="text-danger">*</span>
              </label>
              <CardCvcElement
                options={cardElementOptions}
                onChange={handleCvcChange}
              />{" "}
              {errors.cvc && (
                <div className="error-message">
                  <p className="error-text">
                    {errors.cvc}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {errors.general && <div className="error-message">{errors.general}</div>}

        <button type="submit" className="btn-show w-100 metropill"  disabled={!stripe || loading}>
          {loading ? 'Processing...' : 'PAY'}
        </button>
      </div>
    </form>
    </>
    ):(
      <>
        <h5 className='text-center mt-6 text-danger'>Travel vaccine assessment required!</h5>
        <p className='text-center' style={{fontSize:'13px'}}>Please complete your travel vaccine assessment to proceed with your purchase.</p>
        <button type="button" className="btn-show mt-0 w-100 metropill" onClick={() => (handleShow())}>
          Click here to Start Vaccine Assessment
        </button>
      </>
    )}

    <VaccineAssessmentModal
      showConsultation={showConsultation}
      handleClose={handleClose}
      form = {form}
      questions = {questions}
      setIsLoading={setIsLoading}
    />
    </>
  );
};

export default PaymentComponent;
