import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { notification } from 'antd';
import { HeaderForm } from "./HeaderForm/index.jsx";
import { ScrollToTop } from "../../utils/Helper.js";
import PageLoader from "../../components/loader/PageLoader.jsx";
import { post, get } from "../../services/ApiService.js";
import { Bmi } from "./BmiStep/Bmi.jsx";
import { FormSkeleton } from "./Loader/index.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setConsultationData, saveConsultation } from "../../redux/slices/ConsultationSlice.js";
import PreConsultationAlert from "./components/PreConsultationAlert.jsx";
import FormQuestion from "./components/FormQuestion.jsx";
import DeliveryOption from "./components/DeliveryOption.jsx";
import UploadAttachment from "./components/UploadAttachment.jsx";
import { setUser } from "../../redux/slices/userSlice.js";

const WlConsultation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const user = useSelector((state) => state.user.user);
  const otpRequired = useSelector((state) => state.user.otpRequired); 
  const bmi = useSelector((state) => state.bmi);
  const consultation = useSelector((state) => state.consultation);


  const [fadeClass, setFadeClass] = useState("fade-in");
  const [consultationType, setConsultationType] = useState(consultation.type || "normal");
  const [deliveryType, setDeliveryType] = useState(consultation.DeliveryOption || "");
  const [isConsultationEnable, setIsConsultationEnable] = useState(false);
  const [isDeliveryOpEnable, setIsDeliveryOpEnable] = useState(false);
  const [isAttachementEnable, setIsAttachmentEnable] = useState(false);
  const [current, setCurrent] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [formData, setForm] = useState("");
  const [isBmi, setIsBmi] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(consultation.answers || []);
  const [explanations, setExplanations] = useState(consultation.explanations || []);
  const [errors, setErrors] = useState({});
  const [isUnsuitable, setIsUnsuitable] = useState(false);
  const [attachments, setAttachments] = useState(consultation.attachments || []);
  const [attachmentStep, setAttachmentStep] = useState(1);

  

  useEffect(() => {
    ScrollToTop();
  }, []);

  useEffect(() => {
    if(isAuthenticated){
      if(otpRequired){
        setIsConsultationEnable(false);
        setIsDeliveryOpEnable(false);
        setIsAttachmentEnable(false); 
      }else{
        fetchData(); 
      }
    }else{
      setIsConsultationEnable(false);
      setIsDeliveryOpEnable(false);
      setIsAttachmentEnable(false); 
    }
  }, [isAuthenticated, otpRequired]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const payload = {
        patientId: user?._id
      }
      const res = await post(`api/forms/consultation/weight-loss`, payload);
      setIsLoading(false);
      if (res.success) {
        let assessmentForm = res.form;
        setForm(assessmentForm);
        setQuestions(assessmentForm.questions);
        if (assessmentForm.requiresBMI) {
          setIsBmi(!(bmi.bmiValue && bmi.bmiValue > 0));
        }
        setIsConsultationEnable(true);
        setIsDeliveryOpEnable(false);
        setIsAttachmentEnable(false); 
      } else {
        setForm("");
        setQuestions({});
        setNotFound(true);
      }
    } catch (error) {
      setIsLoading(false);
      setNotFound(true);
      console.error(error.message);
    }
  };

  const handlePatientByEmail = async (email) => {
    try {
      setIsLoading(true);
      const payload = { email }; 
      const res = await post(`api/patients/email-check`, payload);
      setIsLoading(false);
      if (res.success) {
        dispatch(setUser({
          user: res.patient,         
          otpRequired: !res.isNew,
        }));
      } else {
        notification.error({
          message: 'Failed',
          description: 'Internel server error! try again.',
          duration: 4,
          placement: 'topRight',
        });
      }
    } catch (error) {
      setIsLoading(false);
      setNotFound(true);
      console.error(error.message);
    }
  };

  const handleVerifyOtp = async (otp) => {
    try {
      setIsLoading(true);
      const payload = { otp, patientId: user?._id }; 
      const res = await post(`api/auth/verify-otp`, payload);
      console.log(res);
      if (res.success) {
        dispatch(setUser({
          user: res.patient,  
          otpRequired: false, 
        }));
      } else {
        notification.error({
          message: 'Failed',
          description: res.error,
          duration: 4,
          placement: 'topRight',
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: 'Failed',
        description: 'Internel server error! try again.',
        duration: 4,
        placement: 'topRight',
      });
      console.error(error.message);
    }
  };

  const handleAnswer = (selectedOption, writtenText, file) => {
    const question = questions[current];

    if (!question) {
      console.error("Question not found");
      return;
    }

    setErrors((prev) => {
      const updatedErrors = { ...prev };
      delete updatedErrors[current];
      return updatedErrors;
    });

    const newAnswer = answers[current]
      ? { ...answers[current] }
      : {
          _id: question._id,
          options: [],
          description: "",
        };

    // Handle answers based on input type
    if (question.inputType === "radio" || question.inputType === "select") {
      newAnswer.options = [selectedOption];
    } else if (question.inputType === "checkbox") {
      const currentOptions = newAnswer.options || [];
      const isExclusive = question.options.find(
        (opt) => opt._id === selectedOption
      )?.exclusive;

      if (isExclusive) {
        newAnswer.options = [selectedOption];
      } else {
        const filteredOptions = currentOptions.filter(
          (opt) =>
            !question.options.find((option) => option._id === opt)?.exclusive
        );

        if (currentOptions.includes(selectedOption)) {
          newAnswer.options = filteredOptions.filter(
            (opt) => opt !== selectedOption
          );
        } else {
          newAnswer.options = [...filteredOptions, selectedOption];
        }
      }
    } else if (question.inputType === "text") {
      newAnswer.description = writtenText;
    }



    // Update answers state
    setAnswers((prev) => {
      const updatedAnswers = [...prev];
      updatedAnswers[current] = newAnswer;
      return updatedAnswers;
    });

    const hasCorrectOptions = question.options.some(
        (option) => option.isCorrectAnswer === true
    );

    if (hasCorrectOptions) {
        // Check for incorrect options in the answer
        const incorrectOption = newAnswer.options.find(
          (optionId) =>
            question.options.find((option) => option._id === optionId)
              ?.isCorrectAnswer === false
        );
    
        if (incorrectOption) {
          let error = question?.warning
            ? question.warning
            : "Your answer is incorrect. Please check your selection.";
          setErrors((prev) => ({
            ...prev,
            [current]: error,
          }));
          setIsUnsuitable(true);
        } else {
          setErrors((prev) => {
            const updatedErr = { ...prev };
            delete updatedErr[current];
            return updatedErr;
          });
          setIsUnsuitable(false);
        }
    } else {
        setErrors((prev) => {
          const updatedErr = { ...prev };
          delete updatedErr[current];
          return updatedErr;
        });
        setIsUnsuitable(false);
    }

    if (question.inputType !== "text") {
      const requiresExplanation = newAnswer.options.some(
        (optionId) =>
          question.options.find((option) => option._id === optionId)
            ?.requireExplanation
      );
  
      setExplanations((prev) => {
        let updatedExplanations = [...prev];
  
        if (requiresExplanation) {
          const existingExplanation = updatedExplanations.find(
            (ex) => ex._id === question._id
          );
  
          if (!existingExplanation) {
            updatedExplanations.push({ _id: question._id, optionId:selectedOption, text: "" });
          }
        } else {
          updatedExplanations = updatedExplanations.filter(
            (ex) => ex._id !== question._id
          );
        }
  
        return updatedExplanations;
      });
    }

  };

  const handleExplanationChange = (questionId, text) => {
    setExplanations((prev) =>
      prev.map((ex) => (ex._id === questionId ? { ...ex, text } : ex))
    );
  };

  const handleConsultationComplete = () => {
    setIsLoading(true);
    dispatch(
      saveConsultation({
        answers: answers,
        explanations: explanations,
        formId: formData?._id,
        type: consultationType,
        deliveryOption: deliveryType,
        attachments: attachments,
      })
    );
    setIsLoading(false);
    navigate("/products");
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="min-height">
        <HeaderForm />
        <div className="mt-0 container">
          <div className="justify-content-md-center row g-3">
            <div className="col-12">
              <div className={`w-form ${fadeClass}`}>
                {!isConsultationEnable && !isDeliveryOpEnable && !isAttachementEnable && (
                  <PreConsultationAlert
                    setIsConsultationEnable={setIsConsultationEnable}
                    otpRequired = {otpRequired}
                    handlePatientByEmail = {handlePatientByEmail}
                    handleVerifyOtp = {handleVerifyOtp}
                  />
                )}
                {isConsultationEnable && questions.length > 0 && (
                    <>
                    {isBmi ? (
                        <Bmi 
                            setIsBmi = {setIsBmi}
                            fadeClass={fadeClass}
                            setFadeClass={setFadeClass}
                            redirectTo = {`none`}
                        />
                    ):(
                        <FormQuestion
                            current={current}
                            setCurrent={setCurrent}
                            setFadeClass={setFadeClass}
                            formData = {formData}
                            questions = {questions}
                            answers={answers}
                            explanations= {explanations}
                            onChange={handleAnswer}
                            onExpChange = {handleExplanationChange}
                            error={errors[current]}
                            setIsUnsuitable = {setIsUnsuitable}
                            isUnsuitable= {isUnsuitable}
                            setIsBmi = {setIsBmi}
                            setIsConsultationEnable = {setIsConsultationEnable}
                            setIsDeliveryOpEnable = {setIsDeliveryOpEnable}
                        />
                    )}

                    </>
                ) 

                }
                {!isConsultationEnable && isDeliveryOpEnable && (
                    <DeliveryOption 
                        setDeliveryType ={setDeliveryType} 
                        deliveryType = {deliveryType}
                        setIsConsultationEnable = {setIsConsultationEnable}
                        setIsDeliveryOpEnable = {setIsDeliveryOpEnable}
                        setIsAttachmentEnable = {setIsAttachmentEnable}
                        setFadeClass = {setFadeClass}
                        onSubmit = {handleConsultationComplete}
                    />
                )}
                {!isConsultationEnable && !isDeliveryOpEnable && isAttachementEnable && (
                    <UploadAttachment 
                        attachments = {attachments}
                        setAttachments = {setAttachments}
                        currentStep = {attachmentStep}
                        setCurrentStep = {setAttachmentStep}
                        setFadeClass = {setFadeClass}
                        setIsDeliveryOpEnable = {setIsDeliveryOpEnable}
                        setIsAttachmentEnable = {setIsAttachmentEnable}
                        setShowSpinner = {setIsLoading}
                        onSubmit = {handleConsultationComplete}
                    />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WlConsultation;
