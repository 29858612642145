import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  answers: [],  
  explanations: [], 
  formId: null, 
};

const vaccineConsultationSlice = createSlice({
  name: "vaccineConsultation",
  initialState,
  reducers: {
   
    saveVaccineConsultation: (state, action) => {
      const { answers, explanations, formId } = action.payload;
      state.answers = answers;
      state.explanations = explanations;
      state.formId = formId;
    },
    
    resetVaccineConsultation: (state) => {
      state.answers = [];
      state.explanations = [];
      state.formId = null;
    },
  },
});


export const {saveVaccineConsultation, resetVaccineConsultation } = vaccineConsultationSlice.actions;


export default vaccineConsultationSlice.reducer;
