import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Pagination, Typography, message } from "antd";
import { DrawerBiomarker } from "./BiomarkerDrawer";
import TextWithNoRead from "../../../components/TextWithNoRead";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, deleteFromCartById } from "../../../redux/slices/CartSlice";
import PageLoader from "../../../components/loader/PageLoader";
import ManualBookingModal from "./ManualBookingModal";

const { Paragraph, Text } = Typography;

const CardItem = ({
  bloodTests,
  biomarkerData,
  isNotFound,
  isLoad,
  categories,
  activeCategory,
  setActiveCategory,
}) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart?.cartItems) || [];
  const [updatedCartItems, setUpdatedCartItems] = useState(cartItems);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedBiomarker, setSelectedBiomarker] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isAddingCart, setIsAddingCart] = useState(false);
  const [filterOption, setFilterOption] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [isManualVisible, setIsManualVisible] = useState(false);
  const [manaulItem, setManualItem] = useState(null);
  const isManualBooking = (item) => item.requiresManualBooking;

  const tabContentRef = useRef(null);

  useEffect(() => {
    setUpdatedCartItems(cartItems);
  }, [cartItems]);

  const filteredData = bloodTests.filter((item) => {
    // Search Filter
    const matchesSearch = searchValue
      ? item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.uniqueCode.toLowerCase().includes(searchValue.toLowerCase())
      : true;

    // Type Filter
    const matchesType =
      selectedServiceType === "all" ? true : item.type === selectedServiceType;

    // Active Category Filter
    const matchesCategory =
      activeCategory === "0"
        ? true
        : item.category && item.category.length > 0
        ? item.category.includes(activeCategory)
        : false;

    return matchesSearch && matchesType && matchesCategory;
  });

  // Sorting Logic
  const sortedData = [...filteredData].sort((a, b) => {
    if (filterOption === "A to Z") {
      return (a.name || "").localeCompare(b.name || "");
    }
    if (filterOption === "Z to A") {
      return (b.name || "").localeCompare(a.name || "");
    }
    if (filterOption === "High to Low") {
      return (b.price || 0) - (a.price || 0);
    }
    if (filterOption === "Low to High") {
      return (a.price || 0) - (b.price || 0);
    }
    return 0; // No sorting
  });

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a className="metropill color-pre text-decoration-none">Previous</a>
      );
    }
    if (type === "next") {
      return <a className="metropill color-next text-decoration-none">Next</a>;
    }
    return originalElement;
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedBiomarker(null);
    document.body.style.overflow = "auto";
  };

  const handleBiomarkerClick = (item) => {
    const biomarkersToShow =
      item.type === "biomarker" ? [item._id] : item.biomarkers;
    const fetchedBiomarkers = biomarkerData.filter((biomarker) =>
      biomarkersToShow.includes(biomarker._id)
    );
    setSelectedBiomarker(fetchedBiomarkers);
    setDrawerOpen(true);
    document.body.style.overflow = "hidden";
  };

  function truncateText(text, wordLimit) {
    if (text.length > wordLimit) {
      let truncated = text.slice(0, wordLimit);
      const lastSpaceIndex = truncated.lastIndexOf(" "); // Find the last space
      if (lastSpaceIndex !== -1) {
        truncated = truncated.slice(0, lastSpaceIndex); // Remove the incomplete word
      }
      return truncated;
    }
    return text.trim(); // Also trims the input text if it's shorter than the limit
  }

  // Pagination Logic
  const paginatedData = sortedData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const AddingToCart = (item) => {
    try {
      setIsAddingCart(true);
      const product = {
        _id: item._id,
        name: item.name,
        unique_code: item.unique_code,
        price: item.price,
        type: item.type,
        section: "blood-test",
        serviceId: item?.serviceId,
        serviceAdonsId: item?.serviceAdonsId,
      };
      dispatch(addToCart(product));
      message.success("Added to cart");
    } catch (error) {
      console.error(error);
      message.error("Failed to add to cart");
    } finally {
      setIsAddingCart(false);
    }
  };

  const isItemInCart = (itemId) =>
    updatedCartItems.some((item) => item._id === itemId);

  const removeFromCart = (cartItemId) => {
    dispatch(deleteFromCartById(cartItemId));
  };

  const resetFilters = () => {
    setSearchValue("");
    setSelectedServiceType("all");
    setFilterOption("");
    setActiveCategory("0");
    setCurrentPage(1);
    setPageSize(10);
  };

  const handleManualClick = async (item) => {
    setManualItem(item);
    setIsManualVisible(true);
  };

  const handleManualClose = async () => {
    setManualItem(null);
    setIsManualVisible(false);
  };

  return (
    <div className="pad-content pt-13" ref={tabContentRef}>
      <h4 className="h4-cs mb-7 text-center">
        {categories?.map(
          (cat, index) =>
            activeCategory === cat._id && (
              <span key={index} className="d-block">
                {cat.heading || ""}
              </span>
            )
        )}
      </h4>

      <p className="fs-18 metropill text-black text-center">
        {categories?.map(
          (cat, index) =>
            activeCategory === cat._id && (
              <span
                key={index}
                className="fs-18 m-0 metropill text-black text-center"
              >
                <span className="d-block fs-18 m-0 metropill text-black text-center mb-7">
                  {cat.description ||
                    "A comprehensive list of all the blood tests we offer, organised alphabetically. It allows you to easily browse through and search available tests to help select the one that best suits your needs. Use the filter to search by all blood tests or by health categories above. If you can not find a test you are looking for, please contact us - we may be able to offer it!"}
                </span>
                <span className="d-block fs-18 m-0 metropill text-black text-center mb-7">
                  {cat.description_2 ||
                    "A biomarker is a specific indicator in your blood, while a profile combines multiple biomarkers to provide a broader, more comprehensive test."}
                </span>
              </span>
            )
        )}
      </p>

      <div className="row g-4">
        <div className="col-lg-9 col-md-8">
          <div className="input-group search-grp">
            <input
              type="text"
              className="form-control border-0 bg-transparent"
              placeholder="Search For Blood Test"
              aria-describedby="searchfield"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setSelectedServiceType("all"); // Reset selected type to "all"
                setCurrentPage(1); // Reset pagination on search
              }}
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" ||
                  event.code === "Search" ||
                  event.key === "Search"
                ) {
                  event.target.blur(); // This will hide the keyboard on mobile
                }
              }}
            />
            <span
              className="input-group-text bg-transparent border-0"
              id="searchfield"
            >
              <img src="/assets/icons/search-ic.png" width={20} alt="" />
            </span>
          </div>
        </div>
        <div className="col-lg-3 col-md-4">
          <select
            className="form-select filter-select"
            value={filterOption}
            onChange={(e) => {
              setFilterOption(e.target.value);
              setCurrentPage(1); // Reset pagination on sort
            }}
          >
            <option value="">Select Filter</option>
            <option value="A to Z">A to Z</option>
            <option value="Z to A">Z to A</option>
            <option value="High to Low">High to Low</option>
            <option value="Low to High">Low to High</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-md-7">
          <ul className="nav nav-pills mb-3 mt-10 filter-tab">
            <li className="nav-item">
              <button
                className={
                  selectedServiceType === "all" ? "nav-link active" : "nav-link"
                }
                onClick={() => {
                  setSelectedServiceType("all");
                  setCurrentPage(1); // Reset pagination on type change
                }}
              >
                <span className="metropill">All</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={
                  selectedServiceType === "biomarker"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => {
                  setSelectedServiceType("biomarker");
                  setCurrentPage(1); // Reset pagination on type change
                }}
              >
                <span className="metropill">Biomarkers</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={
                  selectedServiceType === "profile"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => {
                  setSelectedServiceType("profile");
                  setCurrentPage(1); // Reset pagination on type change
                }}
              >
                <span className="metropill">Profiles</span>
              </button>
            </li>
          </ul>
        </div>
        <div className="col-md-5">
          <div className="d-flex align-items-center justify-content-lg-end justify-content-center gap-5 mt-10">
            <button
              type="button"
              onClick={resetFilters}
              className="bg-transparent border-0 fs-18 metropill"
            >
              Reset Filters
            </button>
            <Pagination
              className="toppaginationFilter"
              current={currentPage}
              pageSize={pageSize}
              total={filteredData.length}
              onChange={(page, size) => {
                setCurrentPage(page);
                setPageSize(size);
              }}
              pageSizeOptions={["6", "10", "20", "50"]}
              showSizeChanger
            />
          </div>
        </div>
      </div>

      <div className="tab-content">
        <div>
          <div className="row loader-single-cards gap-c my-10">
            {isLoad && <PageLoader />}
            {/* col-1 end */}
            {!isLoad && paginatedData && paginatedData.length > 0 && (
              <>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card-wrap h-100">
                    <div className="card card-az h-50 card-static border-bottom-fff">
                      <div className="card-body">
                        <h6 className="text-white text-center fs-21">
                          Build My Package
                        </h6>
                        <div className="card-body">
                          <p className="ant-typography text-white metrophofont font-p mb-0 fs-14 css-dev-only-do-not-override-ccdg5a">
                            we provide a comprehensive selection of biomarkers
                            and profiles, enabling you to create a personalised
                            blood test package tailored to your health concerns.
                          </p>
                        </div>
                        <div className="card-footer bg-transparent border-0">
                          <div className="d-flex justify-content-center pb-4">
                            <a
                              className="quick-btn border-0 bg-transparent"
                              href={`/build-my-package`}
                              style={{ textDecoration: "none" }}
                            >
                              <span className="metropill">Read More</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end   */}
                    <div className="card card-az h-50 card-static border-top-fff">
                      <div className="card-body">
                        <h6 className="text-white text-center fs-21">
                          Blood Draw Only
                        </h6>
                        <div className="card-body">
                          <p className="ant-typography text-white metrophofont font-p mb-0 fs-14 css-dev-only-do-not-override-ccdg5a">
                            Provides a professional phlebotomy service where a
                            trained specialist collects your blood sample. This
                            service is ideal if you already have a test kit or
                            require a sample for external analysis.
                          </p>
                        </div>
                        <div className="card-footer bg-transparent border-0">
                          <div className="d-flex justify-content-center pb-4">
                            <button className="quick-btn border-0 bg-transparent">
                              <span className="metropill">Book Now</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end   */}
                  </div>
                </div>
                {paginatedData.map((item, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                    <div className="card card-az h-100">
                      <div
                        className="card-header bg-transparent border-0"
                        style={{ height: 100 }}
                      >
                        <NavLink
                          to={`/blood-tests/${item?.slug}`}
                          className="text-decoration-none"
                        >
                          <h6 className="text-white text-center fs-21">
                            {item?.name}
                          </h6>
                        </NavLink>
                      </div>
                      <div className="card-body">
                        <div
                          className={
                            item.type === "profile"
                              ? "cricle-amount bg-green"
                              : "cricle-amount"
                          }
                        >
                          <h4 className="text-white text-center m-0 fs-25">
                            £ {item?.price}
                          </h4>
                        </div>
                        <Paragraph className="text-white metrophofont font-p mb-0 fs-14">
                          {truncateText(item?.shortDescription, 300)}
                        </Paragraph>
                        <div className="d-flex justify-content-center">
                          <NavLink
                            to={`/blood-tests/${item?.slug}`}
                            className="quick-btn text-decoration-none metropill"
                          >
                            READ MORE{" "}
                            <img src="/assets/icons/visible.png" width={14} />
                          </NavLink>
                        </div>
                      </div>
                      <div className="card-footer">
                        <button
                          className="btn-drop justify-content-center"
                          onClick={() =>
                            handleBiomarkerClick(item, selectedServiceType)
                          }
                        >
                          <img
                            src="/assets/icons/drop-b.png"
                            width={22}
                            alt=""
                          />
                          <span className="metropill">
                            {item.type === "biomarker"
                              ? 1
                              : item.biomarkers.length}{" "}
                            BIOMARKER ANALYSED
                          </span>
                        </button>
                        <div className="d-flex justify-content-center pb-4">
                          {isManualBooking(item) ? (
                            <button
                              className="quick-btn border-0 bg-transparent"
                              onClick={() => handleManualClick(item)}
                            >
                              <span className="metropill">
                                QUICK ADD
                                <img
                                  src="/assets/icons/cart-sm.png"
                                  width={14}
                                />
                              </span>
                            </button>
                          ) : (
                            <button
                              className="quick-btn border-0 bg-transparent"
                              disabled={isAddingCart}
                              onClick={() =>
                                isItemInCart(item._id)
                                  ? removeFromCart(item._id)
                                  : AddingToCart(item)
                              }
                            >
                              <span className="metropill">
                                {isAddingCart
                                  ? "PLEASE WAIT..."
                                  : isItemInCart(item._id)
                                  ? "REMOVE FROM CART"
                                  : "QUICK ADD"}
                                <img
                                  src="/assets/icons/cart-sm.png"
                                  width={14}
                                />
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        {!isLoad && filteredData.length > 5 && (
          <div className="py-5 p-bottom">
            <Pagination
              itemRender={itemRender}
              current={currentPage}
              total={filteredData.length}
              pageSize={pageSize}
              onChange={(page, size) => {
                setCurrentPage(page);
                setPageSize(size);
                window.scrollTo({
                  top: 1000,
                  behavior: "smooth", // Optional: Smooth scrolling
                });
              }}
              pageSizeOptions={["6", "10", "20", "50"]}
              showSizeChanger
            />
          </div>
        )}
      </div>

      <DrawerBiomarker
        visible={drawerOpen}
        onClose={closeDrawer}
        biomarkerData={selectedBiomarker}
      />
      <ManualBookingModal
        isVisible={isManualVisible}
        onClose={handleManualClose}
        item={manaulItem}
      />
    </div>
  );
};

export { CardItem };
