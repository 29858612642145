import React, { useState, useEffect } from "react";
import { NewNavbar, BreadCrumbsNew, NewFooter } from "../../components";
import { useLocation } from "react-router-dom";
import { get } from "../../services/ApiService";
import PageLoader from "../../components/loader/PageLoader";
import { message } from "antd";
import { addToCart, deleteFromCartById } from "../../redux/slices/CartSlice";
import { useSelector, useDispatch } from "react-redux";

const AllTravelClinic = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart?.cartItems) || [];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [data, setData] = useState([]);
  const [isAddingCart, setIsAddingCart] = useState(false);

  const breadcrumbs = [
    { label: "Home", path: "https://www.beta.myprivatechemist.com/" },
    {
      label: "Travel Clinic",
      path: "https://www.beta.myprivatechemist.com/travel-clinic/",
    },
    { label: "Vaccinations by Diseases" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsNotFound(false);

      try {
        const res = await get("api/travel-clinic");
        if (res.success) {
          setData(res.data);
        } else {
          setData([]);
          setIsNotFound(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
        setIsNotFound(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredData = data.filter((item) => {
    const matchesSearch = searchValue
      ? item.name.toLowerCase().includes(searchValue.toLowerCase())
      : true;

    return matchesSearch;
  });

  const sortedData = [...filteredData].sort((a, b) => {
    switch (filterOption) {
      case "A to Z":
        return (a.name || "").localeCompare(b.name || "");
      case "Z to A":
        return (b.name || "").localeCompare(a.name || "");
      case "High to Low":
        return (b.price || 0) - (a.price || 0);
      case "Low to High":
        return (a.price || 0) - (b.price || 0);
      default:
        return 0;
    }
  });

  const AddingToCart = (item) => {
    try {
      setIsAddingCart(true);
      const product = {
        _id: item._id,
        name: item.name,
        unique_code: "",
        price: item.price,
        type: "",
        section: "travel-clinic",
        serviceId: item?.serviceId,
        serviceAdonsId: "",
      };
      dispatch(addToCart(product));
      message.success("Added to cart");
    } catch (error) {
      console.error(error);
      message.error("Failed to add to cart");
    } finally {
      setIsAddingCart(false);
    }
  };

  const isItemInCart = (itemId) =>
    cartItems.some((item) => item._id === itemId); // Use cartItems directly

  const removeFromCart = (cartItemId) => {
    dispatch(deleteFromCartById(cartItemId));
  };

  return (
    <>
      <div className='breadcrumnb-img-bg breadcrumnb-img-bg-new'>
        <div className='container'>
          <NewNavbar />
          <BreadCrumbsNew breadcrumbs={breadcrumbs} title={'Vaccinations by Diseases'} />
        </div>
      </div>
      <div className="container my-5">
        <div className="pt-travel mb-12">
          <h4 className="h4-cs mb-7 text-center">
            <span className="d-block">
              Safeguard Your Travels with Travel Vaccinations.
            </span>
          </h4>
          <p className="fs-18 metropill text-black text-center mb-0">
            <span className="d-block fs-18 mx-0 mt-0 metropill text-black text-center">
              These essential immunisations protect you from preventable
              diseases like hepatitis, rabies, and typhoid fever, which can be
              present in certain destinations. Invest in your health and peace
              of mind with travel vaccinations for a worry-free adventure.
            </span>
          </p>
        </div>
        <div className="row g-4">
          <div className="col-lg-9 col-md-8">
            <div className="input-group search-grp">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                placeholder="Search For vaccine"
                aria-label="Search for vaccine"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.target.blur();
                  }
                }}
              />
              <span className="input-group-text bg-transparent border-0">
                <img
                  src="/assets/icons/search-ic.png"
                  width={20}
                  alt="Search"
                />
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <select
              className="form-select filter-select"
              value={filterOption}
              onChange={(e) => setFilterOption(e.target.value)}
              aria-label="Filter options"
            >
              <option value="">Select Filter</option>
              <option value="A to Z">A to Z</option>
              <option value="Z to A">Z to A</option>
              <option value="High to Low">High to Low</option>
              <option value="Low to High">Low to High</option>
            </select>
          </div>
        </div>
        <div className="row loader-single-cards gap-c mt-11 mb-21 travel-row">
          {isLoading ? (
            <PageLoader />
          ) : isNotFound ? (
            <div className="text-center">No data found.</div>
          ) : (
            sortedData.map((item, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6 mt-0" key={index}>
                <div className="card card-az h-100">
                  <div className="card-header bg-transparent border-0">
                    <a
                      className="text-decoration-none"
                      href="/travel-clinic-detail"
                    >
                      <h6 className="text-center">
                        <a
                          className="text-decoration-none  text-white"
                          href={`/travel-clinic/vaccinations-by-diseases/${item.slug}`}
                        >
                          {item.name}
                        </a>
                      </h6>
                    </a>
                  </div>
                  <div className="card-body">
                    <div className="cricle-amount">
                      <h4 className="text-white text-center m-0 fs-25">
                        £ {item.price} / dose
                      </h4>
                    </div>
                    <div className="ant-typography text-white metrophofont font-p mb-0 fs-14 css-ccdg5a">
                      <ul className="list-unstyled metrophofont-list ps-0 text-center">
                        <li>
                          <span>+</span> {item.points.point1}
                        </li>
                        <li>
                          <span>+</span> {item.points.point2}
                        </li>
                        <li>
                          <span>+</span> {item.points.point3}
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center">
                      <a
                        className="quick-btn text-decoration-none metropill"
                        href={`/travel-clinic/vaccinations-by-diseases/${item.slug}`}
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button
                      className="btn-drop justify-content-center w-auto"
                      disabled={isAddingCart}
                      onClick={() =>
                        isItemInCart(item._id)
                          ? removeFromCart(item._id)
                          : AddingToCart(item)
                      }
                    >
                      <span className="metropill">
                        {isAddingCart
                          ? "PLEASE WAIT..."
                          : isItemInCart(item._id)
                            ? "REMOVE FROM CART"
                            : "QUICK ADD +"
                        }
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export { AllTravelClinic };