import React from "react";
import { Link } from "react-router-dom";
import "./Component.css";

const Header = () => {
  return (
    <>

      <nav className="navbar navbar-expand-lg navbar-dark" style={{borderBottom: '1px solid #cac9c9'}}>
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <Link className="navbar-brand" href="#">
          <img className="light-mode-img" src="/logo.png" width={179} height={48} />

          </Link>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link active navLink" aria-current="page" href="#">Blood Testing</Link>
              </li>
               <li className="nav-item">
                <Link className="nav-link navLink" href="https://www.beta.myprivatechemist.com/travel-clinic/">Travel Clinic</Link>
              </li>
              {/*<li className="nav-item">
                <Link className="nav-link navLink" href="#">Treatment Medication</Link>
              </li> */}

            </ul>
          </div>
        </div>
      </nav>


    </>
  );
};

export default Header;
