import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ScrollToTop } from "../../../utils/Helper";
import uploadIcon from "../../../assets/images/id-example.jpg";
import bodyIcon from "../../../assets/images/full-body-example.jpg"; // Add a new icon for the second image
import { CameraOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { post } from "../../../services/ApiService";

const UploadAttachment = ({attachments, setAttachments, currentStep, setCurrentStep, setFadeClass, setIsDeliveryOpEnable, setIsAttachmentEnable, setShowSpinner, onSubmit}) => {
  const [stream, setStream] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [isFromCamera, setIsFromCamera] = useState(false);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    ScrollToTop();
  }, []);

  useEffect(() => {
    if (showCamera && videoRef.current && stream) {
      videoRef.current.srcObject = stream;
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play();
      };
    }
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [showCamera, stream]);

  const base64ToBlob = (base64, type = "image/png") => {
    const byteString = atob(base64.split(",")[1]);
    const arrayBuffer = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      arrayBuffer[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type });
  };

  const startCamera = async () => {
    try {
      const cameraStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
      });
      setStream(cameraStream);
      setShowCamera(true);
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
    setShowCamera(false);
  };

  const takePicture = async () => {
    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const base64Data = canvas.toDataURL("image/png");
      const blob = base64ToBlob(base64Data);
      await handleUpload(blob);
      stopCamera();
    }
  };

  const handleUpload = async (file) => {
    setShowSpinner(true);
    const formDataObj = new FormData();
    formDataObj.append("file", file);
    try {
      const res = await post("api/home/file-upload", formDataObj, true);
      setShowSpinner(false);
      if (res.success) {
        setIsFromCamera(false);
        setAttachments((prev) => [...prev, res.file]);
      } else {
        console.error("File upload failed:", res.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setShowSpinner(false);
    }
  };

  const handleRemoveFile = async (index) => {
    try {
      setShowSpinner(true);
      const res = await post("api/home/file-remove", { filename: attachments[index] });
      setShowSpinner(false);
      if (res.success) {
        setAttachments((prev) => prev.filter((_, i) => i !== index));
      } else {
        console.error("Error removing file:", res.error);
      }
    } catch (error) {
      console.error("Error in file removal:", error);
    }
  };

  const handleNext = () => {
    if (currentStep === 1) {
      setFadeClass("fade-out");
      setTimeout(() => {
        setCurrentStep(2);
        setFadeClass("fade-in");
      }, 300);
    } else {
        onSubmit();
    }
  };

  const handleBack = () => {
    if (currentStep === 2) {
        setFadeClass("fade-out");
        setTimeout(() => {
          setCurrentStep(1);
          setFadeClass("fade-in");
        }, 300);
    } else {
      setIsDeliveryOpEnable(true);
      setIsAttachmentEnable(false);
    }
  };

  const isNextDisabled = !attachments[currentStep - 1];

  return (
    <>
      <div className="row g-5">
        <div className="col-lg-12">
          <h5 className="mb-5">
            {currentStep === 1 ? "ID Verification" : "Body Verification"}
          </h5>
        </div>
        <div className="col-lg-12">
          <div className="verification-options d-flex gap-4 justify-content-center flex-wrap">
            <div className="upload-option-wrap">
              <div className="top-section-img">
                <img src={currentStep === 1 ? uploadIcon : bodyIcon} alt="Upload" className="mb-3" />
                <div>

                  {currentStep === 1 && (
                    <>
                    <p>To confirm it`s you, please take a <strong>selfie</strong> of yourself holding your passport, driving licence, or national identity card <strong>next to your face</strong></p>
                    </>
                  )}
                  {currentStep === 2 && (
                    <>
                        <p>The last image we need is one of your <strong>full, front-facing profile, from head to toe</strong>. As injectable weight loss treatments are only suitable for people with a BMI over 27, our clinicians will review this photo to confirm before prescribing. </p>
                        <p>You can be fully clothed, we just ask that you wear clothes that make your <strong>body shape visible</strong>, such as leggings and a fitted vest or t-shirt.</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="width-drag w-100">
              {showCamera ? (
                <div
                  className="camera-container"
                  style={{
                    width: "100%",
                    aspectRatio: "16/9",
                    position: "relative",
                    backgroundColor: "#000",
                  }}
                >
                  <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    muted
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      left: 0,
                      top: 0,
                    }}
                  />
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      left: "0",
                      right: "0",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Button onClick={takePicture} type="primary">
                      Take Photo
                    </Button>
                    <Button onClick={stopCamera}>Cancel</Button>
                  </div>
                </div>
              ) : attachments[currentStep - 1] ? (
                <div className="preview-img">
                  <button
                    className="remove-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRemoveFile(currentStep - 1);
                    }}
                  >
                    <svg
                      fillRule="evenodd"
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="close"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                    </svg>
                  </button>
                  <img
                    src={`${process.env.REACT_APP_API_ENDPOINT}/uploads/${attachments[currentStep - 1]}`}
                    alt=""
                  />
                  {isFromCamera && (
                    <div className="preview-actions">
                      <Button className="retake-btn" onClick={startCamera}>
                        <CameraOutlined />
                        <span>Retake Photo</span>
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="ver-box d-flex w-100">
                  <div className="upload-option wrap-option-up">
                    <div className="dashed-border p-4 rounded-3 cursor-pointer">
                      <input
                        type="file"
                        id={`file-${currentStep}`}
                        className="d-none"
                        accept="image/*"
                        onChange={(e) => handleUpload(e.target.files[0])}
                      />
                      <label htmlFor={`file-${currentStep}`} className="cursor-pointer">
                        <svg
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="inbox"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0060.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
                        </svg>
                        <p className="m-0 metropill">Upload Image</p>
                      </label>
                    </div>
                  </div>
                  <div className="selfie-option wrap-option-up">
                    <div className="dashed-border p-4 rounded-3 cursor-pointer">
                      <button
                        type="button"
                        className="border-0 bg-transparent"
                        onClick={startCamera}
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="camera"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M864 248H728l-32.4-90.8a32.07 32.07 0 00-30.2-21.2H358.6c-13.5 0-25.6 8.5-30.1 21.2L296 248H160c-44.2 0-80 35.8-80 80v456c0 44.2 35.8 80 80 80h704c44.2 0 80-35.8 80-80V328c0-44.2-35.8-80-80-80zm8 536c0 4.4-3.6 8-8 8H160c-4.4 0-8-3.6-8-8V328c0-4.4 3.6-8 8-8h186.7l17.1-47.8 22.9-64.2h250.5l22.9 64.2 17.1 47.8H864c4.4 0 8 3.6 8 8v456zM512 384c-88.4 0-160 71.6-160 160s71.6 160 160 160 160-71.6 160-160-71.6-160-160-160zm0 256c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z"></path>
                        </svg>
                        <p className="m-0 metropill">Take Selfie</p>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-30 p-0 card-footer bg-transparent border-0 d-flex align-items-center justify-content-between gap-2">
          <button
            type="button"
            className="border-0 bg-transparent"
            onClick={handleBack}
          >
            <img src="/assets/images/backicon.jpg" width={35} alt="Back" />
          </button>
          <button
            type="button"
            className={
                isNextDisabled
                  ? "btn-disable w-auto activebg"
                  : "btncust w-auto activebg"
            }
            onClick={handleNext}
            disabled={isNextDisabled}
            style={isNextDisabled ? { cursor: "not-allowed" } : {}}
          >
            {currentStep === 1 ? "Next" : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
};

export default UploadAttachment;