import React from "react";
import { useSelector } from "react-redux";

const HeaderForm = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const otpRequired = useSelector((state) => state.user.otpRequired);
  return (
    <div className="py-7 px-4 mb-0 mt-6 text-center header-form-wrap">
      <img src="/logo.png" width={150} />
      {isAuthenticated && !otpRequired && (
      <div className="profile-wrap-consult">
        <div className="dropdown">
          <button
            className="dropdown-toggle metropill"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src="/assets/images/login.png" style={{ width: "30px", filter: 'invert(1)' }} />
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item metropill" href="#">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
      )}

    </div>
  );
};

export { HeaderForm };
