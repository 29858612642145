import React from "react";
import "./index.css";
import { HeaderForm } from "../../pages/consultation/HeaderForm";

const NoticeNewStep = () => {
  return (
    <div className="min-height">
      <HeaderForm />
      <div className="justify-content-md-center row g-3 m-0">
        <div className="col-12">
          <form className="w-form fade-in">
            <div className="row g-4">
              <div className="col-12">
                <h5 className="mb-5">Thankyou for coming back!</h5>
                <p>
                  We`ll now ask you a few questions to assess your eligibility
                  and personalise your treatment.
                </p>
              </div>

              <div className="col-12 d-flex justify-content-between align-items-center">
                <button
                  type="button"
                  className="border-0 bg-transparent"

                >
                  <img
                    src="/assets/images/backicon.jpg"
                    width={35}
                    alt="Back"
                  />
                </button>
                <button
                  type="submit"
                  className="btncust w-auto activebg cursor-pointer"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NoticeNewStep;
