import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  redirectTo: null,  // Stores the URL the user should be redirected to after login
  redirectFrom: null, // Stores the previous URL the user came from, for back button functionality
};

const redirectionSlice = createSlice({
  name: 'redirection',
  initialState,
  reducers: {
    // Set both redirectTo and redirectFrom
    setRedirectTo: (state, action) => {
      state.redirectTo = action.payload.redirectTo;
      state.redirectFrom = action.payload.redirectFrom || null; // Optional if not provided
    },
    
    // Clear both redirectTo and redirectFrom
    clearRedirectTo: (state) => {
      state.redirectTo = null;
      state.redirectFrom = null;
    },
  },
});

export const { setRedirectTo, clearRedirectTo } = redirectionSlice.actions;
export default redirectionSlice.reducer;
