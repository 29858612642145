import React, { useEffect, useState } from "react";
import "./index.css";
import { get } from "../../../services/ApiService.js";
import { Link } from "react-router-dom";
import BookingModal from "./BookingModal.jsx";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const Suggestion = ({
  data,
  biomaker,
  selectedItems,
  resultRef,
  AddingToCart,
  isAddingCart,
  isRequiresManualBooking,
  ManualBooking,
  confirmLoading,
  isMailSent,
  setIsMailSent,
}) => {
  const tableData = [];
  const [biomarkersList, setBiomarkersList] = useState([]);
  const FEE = 30;
  const cartItems = useSelector((state) => state.cart?.cartItems) || [];
  const isCartEmpty =
    cartItems.length === 0 ||
    cartItems.every((item) => item.section !== "blood-test");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isManualBooking =
    data.some((item) => item.requiresManualBooking) ||
    biomaker.some((item) => item.requiresManualBooking);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const calculateTotals = () => {
    // Merge data and biomarker into one array
    const allItems = [...data, ...biomaker];

    if (!allItems || allItems.length === 0) return { subtotal: 0, total: 0, totalDiscount: 0 };

    let totalDiscount = 0;
    let subtotal = 0;
    const FEE = 30;

    let isFirstItem = isCartEmpty; // If cart is empty, first item gets no discount

    allItems.forEach((item, index) => {
      let discount = 0;
      let itemPrice = Number(item.price) || 0;

      // Apply discount logic based on whether it's the first item or not
      if (!isFirstItem) {
        discount = 10; // Apply discount to all items after the first one
        // itemPrice -= discount; 
      } else if (index !== 0) {
        discount = 10; // Apply discount to all items except the first one
        // itemPrice -= discount; // Reduce item price by £10
      } else {
        isFirstItem = false; // The first item is not discounted
      }

      subtotal += itemPrice;
      totalDiscount += discount;
    });

    // Ensure `isBloodDraw` is defined before using it, defaulting to false
    const total = subtotal - totalDiscount + FEE;

    return { subtotal, total, totalDiscount };
  };


  const { subtotal, total, totalDiscount } = calculateTotals();

  const fetchBiomarkers = async () => {
    try {
      const res = await get("api/blood-tests/biomarkers");
      if (res.success) {
        setBiomarkersList(res.biomarkers);
      } else {
        setBiomarkersList([]);
      }
    } catch (error) {
      setBiomarkersList([]);
    }
  };

  useEffect(() => {
    fetchBiomarkers();
  }, []);

  //   useEffect(() => {
  //     if (resultRef && resultRef.current) {
  //       resultRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //     }
  //   }, [resultRef]);

  return (
    <div className="mt-3 px-lg-4">
      <div className="row g-5">
        {
          <div className="col-lg-12">
            <div className="card border-0 border-main rounded-4 shadow-c">
              <div className="card-body">
                <p className="metropill text-black fw-normal fs-14 mt-4">
                  Our Recommendation
                </p>
                {[...data, ...biomaker].map((item, index) => {
                  const duplicate = tableData.find(
                    (tableItem) => tableItem.key === item._id
                  );
                  if (duplicate) return null;
                  const {
                    _id,
                    name,
                    biomarkers,
                    additionalInformation,
                    price,
                    description,
                    requiresManualBooking,
                  } = item;

                  // Determine price and discount based on conditions
                  const displayPrice =
                    index === 0 && isCartEmpty
                      ? price
                      : Math.max(price - 10, 0);

                  const isDiscountApplied = displayPrice < price;

                  // For biomarker elements, handle them separately when `biomarkers` property exists
                  const biomarkerElements = biomarkers
                    ? biomarkers.map((biomarkerId, bioIndex) => {
                      const itemExist = selectedItems.find(
                        (i) => i.id === biomarkerId._id
                      );

                      const bioName = biomarkerId.name;

                      return (
                        <div
                          className={`${itemExist ? "tag-btnpro" : "tag-btn"
                            } text-decoration-none text-capitalize`}
                          key={bioIndex}
                          style={{ cursor: "default" }}
                        >
                          {bioName || "Unknown Biomarker"}
                        </div>
                      );
                    })
                    : null;



                  return (
                    <>
                      <div className="d-flex align-items-center justify-content-between flex-column flex-md-row align-items-center">
                        <div className="mt-5">
                          <h5 className="fw-semibold text-center text-md-start">
                            {name}
                          </h5>
                        </div>
                        <h4 className="text-center">
                          {isDiscountApplied && (
                            <sup style={{ fontSize: "15px", color: "#666363" }}>
                              <del className="del-line-color">£ {price}</del>
                            </sup>
                          )}
                          <span
                            className="fw-semibold"
                            style={{ fontSize: "25px" }}
                          >
                            <small>£{displayPrice}</small>
                          </span>
                        </h4>
                      </div>
                      <div className="col-12">
                        {requiresManualBooking && (
                          <p
                            className="metropill text-danger"
                            style={{ fontSize: "12px" }}
                          >DUE TO THE SAMPLE REQUIREMENTS ON THIS TEST, PLEASE CLICK BELOW TO SEND US AN EMAIL FOR BOOKING AVAILABILITY.</p>
                        )}
                      </div>
                      <div className="mt-5 d-flex align-items-center gap-4 flex-wrap">
                        {biomarkerElements}
                      </div>
                      <hr />
                    </>
                  );
                })}

                <div className="row total-row mb-4">
                  <div className="col-lg-6 col-md-6 col-sm-12"></div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="cart-totals">
                      {/* <div className="row total-row">
                        <div className="col-5 totel-left-heading">
                          {" "}
                          <span>Subtotal</span>
                        </div>
                        <div className="col-7 totel-right-content">
                          <span className="fs-18"> £ {subtotal}</span>{" "}
                        </div>
                      </div> */}
                      <div className="row total-row">
                        <div className="col-5 totel-left-heading">
                          <span>Blood Draw Fee</span>{" "}
                        </div>
                        <div className="col-7 totel-right-content">
                          <span className="fs-18">£ {FEE}</span>{" "}
                        </div>
                      </div>
                      <div className="row total-row">
                        <div className="col-5 totel-left-heading">
                          {" "}
                          <span>Discount</span>
                        </div>
                        <div className="col-7 totel-right-content">
                          <span className="fs-18"> - £ {totalDiscount}</span>{" "}
                        </div>
                      </div>


                      <div className="row total-row">
                        <div className="col-5 totel-left-heading">
                          <span className="fw-bold">Total</span>{" "}
                        </div>
                        <div className="col-7 totel-right-content">
                          {" "}
                          <b className=" text-dark fs-18 fw-bold">£ {total}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 pt-5">
                  {isManualBooking ? (
                    <>
                      <p
                        className="metropill text-danger"
                        style={{
                          color: "#333",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        Due to the requirements for one or more of your tests, the sample must reach the lab in a timely manner after taking your blood. Please click below to email this package to our team. Once received, we will reach out to schedule your appointment according to the sample requirements.
                      </p>
                      <button
                        type="button"
                        className={`${isMailSent ? "btn-gray" : "btn-pink"
                          } w-100`}
                        onClick={showModal}
                        disabled={isMailSent}
                      >
                        {isMailSent ? "Email Sent" : "Send Email"}
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="btn-pink w-100"
                      onClick={AddingToCart}
                      disabled={isAddingCart}
                    >
                      {isAddingCart && (
                        <LoadingOutlined className="colorload" />
                      )}{" "}
                      Add to cart
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <BookingModal
        isVisible={isModalVisible}
        onClose={handleCancel}
        ManualBooking={ManualBooking}
        confirmLoading={confirmLoading}
        selectedItems = {selectedItems}
      />
    </div>
  );
};

export { Suggestion };
