import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  user: null,
  isAuthenticated: false,
  otpRequired: false,
};

// Slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Action to set user data
    setUser: (state, action) => {
      state.user = action.payload.user;  
      state.isAuthenticated = true;
      state.otpRequired = action.payload.otpRequired; 
    },
    // Action to log out the user
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.otpRequired = false; 
    },
  },
});

// Export actions and reducer
export const { setUser, logout } = userSlice.actions;
export const userReducer = userSlice.reducer;
