import React, { useState } from "react";
import "./index.css";

const Thankyou = () => {
  return (
    <div>
      <div className="row g-5">
        <div className="col-lg-12">
          <h5 className="mb-5">
          Thank You For Order!
          </h5>
          <div className="d-flex align-items-center gap-3 justify-content-center mb-5">
            <h6 className="mb-0">Order ID</h6> <span className="metropill">1234567890</span>
          </div>
          <p>Your order has been successful. Please check your inbox or your junk folder for further details.</p>
          <hr />
          <div className="d-flex align-items-center gap-3 justify-content-between my-5">
            <h6 className="mb-0">Product Title:</h6>  <span className="metropill">Product 1 - 52124</span>
          </div>
          <div className="d-flex align-items-center gap-3 justify-content-between mb-5">
          <h6 className="mb-0">Product Price:</h6>  <span className="metropill">£52.20</span>
          </div>
          <div className="d-flex align-items-center gap-3 justify-content-between mb-5">
            <h6 className="mb-0">Shipping Address</h6> <span className="metropill">Uk, London, 123, 123, 123</span>
          </div>
          <div className="d-flex align-items-center gap-3 justify-content-between mb-5">
            <h6 className="mb-0">Shipping Method</h6> <span className="metropill">Free</span>
          </div>
          <div className="d-flex align-items-center gap-3 justify-content-between mb-5">
            <h6>Expected Delivery Date</h6> <span className="metropill">29/05/2025</span>
          </div>
          <hr />
          <div className="d-flex align-items-center gap-3 justify-content-between mb-5">
            <h5 className="mb-0"><b>Total</b></h5> <span className="metropill">£854.00</span>
          </div>
        </div>
     
      </div>
      <div className="mt-30 p-0 card-footer bg-transparent border-0 d-flex align-items-center justify-content-center gap-2">
        <a className="btncust w-auto activebg m-auto">Back to Home</a>
      </div>
    </div>
  );
};

export default Thankyou;
