import React, { useState, useEffect } from "react";
import { Switch, Input, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setBmiValues, bmiData } from "../../../redux/slices/BmiSlice";
import BmiBar from "./BmiBar";
import "./bmiStyle.css";

const Bmi = ({ setIsBmi, fadeClass, setFadeClass, redirectTo }) => {
  const dispatch = useDispatch();
  const bmiState = useSelector(bmiData);
  const navigate = useNavigate();
  console.log("bmi:",bmiState);

  const [heightUnit, setHeightUnit] = useState(bmiState.heightUnit || "imperial");
  const [weightUnit, setWeightUnit] = useState(bmiState.weightUnit || "metric");
  const [heightFeet, setHeightFeet] = useState(bmiState.heightFeet || "0");
  const [heightInches, setHeightInches] = useState(bmiState.heightInches || "0");
  const [heightCentimeters, setHeightCentimeters] = useState(bmiState.heightCm || "0");
  const [heightError, setHeightError] = useState("");
  const [heightMaxError, setHeightMaxError] = useState("");

  const [weightStones, setWeightStones] = useState(bmiState.weightStones || "0");
  const [weightPounds, setWeightPounds] = useState(bmiState.weightPounds || "0");
  const [weightKilograms, setWeightKilograms] = useState(bmiState.weightKg || "0");
  const [weightError, setWeightError] = useState("");
  const [weightMaxError, setWeightMaxError] = useState("");

  const [bmiCategory, setBmiCategory] = useState(bmiState.bmiCategory || "");
  const [bmiValue, setBmiValue] = useState(bmiState.bmiValue || 0);

  const MAX_HEIGHT_CM = 250;
  const MAX_WEIGHT_KG = 350;

  // Convert height from feet and inches to cm
  const convertHeightToCm = (feet, inches) => {
    if (feet === "" || inches === "") return 0;
    const totalInches = parseFloat(feet) * 12 + parseFloat(inches);
    return totalInches * 2.54;
  };

  // Convert height from cm to feet and inches
  const convertHeightToFeetAndInches = (cm) => {
    if (cm === "" || isNaN(cm)) return [0, 0];
    const totalInches = cm / 2.54;
    const feet = Math.floor(totalInches / 12);
    const inches = Math.round(totalInches % 12);
    return [feet, inches];
  };

  // Convert weight from stones and pounds to kg
  const convertWeightToKg = (stones, pounds) => {
    if (stones === "" || pounds === "") return 0;
    const totalPounds = parseFloat(stones) * 14 + parseFloat(pounds);
    return totalPounds * 0.453592;
  };

  // Convert weight from kg to stones and pounds
  const convertWeightToStonesAndPounds = (kg) => {
    if (kg === "" || isNaN(kg)) return [0, 0];
    const totalPounds = kg / 0.453592;
    const stones = Math.floor(totalPounds / 14);
    const pounds = Math.round(totalPounds % 14);
    return [stones, pounds];
  };

  // Validate height input
  const validateHeight = () => {
    let isValid = true;
    setHeightError("");
    setHeightMaxError("");

    if (heightUnit === "imperial") {
      const heightCm = convertHeightToCm(heightFeet, heightInches);
      if (heightCm > MAX_HEIGHT_CM) {
        setHeightMaxError(`Height cannot exceed ${MAX_HEIGHT_CM} cm.`);
        isValid = false;
      }
    } else {
      if (parseFloat(heightCentimeters) > MAX_HEIGHT_CM) {
        setHeightMaxError(`Height cannot exceed ${MAX_HEIGHT_CM} cm.`);
        isValid = false;
      }
    }
    return isValid;
  };

  // Validate weight input
  const validateWeight = () => {
    let isValid = true;
    setWeightError("");
    setWeightMaxError("");

    if (weightUnit === "imperial") {
      const weightKg = convertWeightToKg(weightStones, weightPounds);
      if (weightKg > MAX_WEIGHT_KG) {
        setWeightMaxError(`Weight cannot exceed ${MAX_WEIGHT_KG} kg.`);
        isValid = false;
      }
    } else {
      if (parseFloat(weightKilograms) > MAX_WEIGHT_KG) {
        setWeightMaxError(`Weight cannot exceed ${MAX_WEIGHT_KG} kg.`);
        isValid = false;
      }
    }
    return isValid;
  };

  useEffect(() => {
    const validHeight = validateHeight();
    const validWeight = validateWeight();

    if (!validHeight || !validWeight) {
      setBmiCategory(null);
      setBmiValue(0);
      return;
    }

    let heightCm = 0;
    if (heightUnit === "imperial") {
      heightCm = convertHeightToCm(heightFeet, heightInches);
    } else {
      heightCm = parseFloat(heightCentimeters) || 0;
    }

    let weightKg = 0;
    if (weightUnit === "imperial") {
      weightKg = convertWeightToKg(weightStones, weightPounds);
    } else {
      weightKg = parseFloat(weightKilograms) || 0;
    }

    const bmi = calculateBMI(heightCm, weightKg);
    setBmiValue(bmi);
    const category = getBmiCategory(bmi);
    setBmiCategory(category);
  }, [
    heightFeet,
    heightInches,
    heightCentimeters,
    weightStones,
    weightPounds,
    weightKilograms,
  ]);

  // Toggle height unit
  const handleHeightToggle = (checked) => {
    setHeightUnit(checked ? "imperial" : "metric");
    validateHeight();
  };

  // Handle changes in height inputs
  const handleFeetChange = (e) => {
    const value = e.target.value;
    setHeightFeet(value);
    if (value === "") {
      updateHeightInCm("", heightInches);
    } else {
      updateHeightInCm(value, heightInches);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };

  const handleInchesChange = (e) => {
    const value = e.target.value;
    setHeightInches(value);
    if (value === "") {
      updateHeightInCm(heightFeet, "");
    } else {
      updateHeightInCm(heightFeet, value);
    }
  };

  const handleCentimetersChange = (e) => {
    const value = e.target.value;
    setHeightCentimeters(value);
    if (value === "") {
      updateHeightInImperial("");
    } else {
      updateHeightInImperial(value);
    }
  };

  // Update height in cm or imperial units
  const updateHeightInCm = (feet, inches) => {
    const cm = feet && inches ? convertHeightToCm(feet, inches) : 0;
    setHeightCentimeters(cm.toFixed(1));
    validateHeight();
  };

  const updateHeightInImperial = (cm) => {
    const [feet, inches] = cm
      ? convertHeightToFeetAndInches(parseFloat(cm))
      : [0, 0];
    setHeightFeet(feet);
    setHeightInches(inches);
    validateHeight();
  };

  // Toggle weight unit
  const handleWeightToggle = (checked) => {
    setWeightUnit(checked ? "imperial" : "metric");
    validateWeight();
  };

  // Handle changes in weight inputs
  const handleStonesChange = (e) => {
    const value = e.target.value;
    setWeightStones(value);
    if (value === "") {
      updateWeightInKg("", weightPounds);
    } else {
      updateWeightInKg(value, weightPounds);
    }
  };

  const handlePoundsChange = (e) => {
    const value = e.target.value;
    setWeightPounds(value);
    if (value === "") {
      updateWeightInKg(weightStones, "");
    } else {
      updateWeightInKg(weightStones, value);
    }
  };

  const handleKilogramsChange = (e) => {
    const value = e.target.value;
    setWeightKilograms(value);
    if (value === "") {
      updateWeightInImperial("");
    } else {
      updateWeightInImperial(value);
    }
  };

  // Update weight in kg or imperial units
  const updateWeightInKg = (stones, pounds) => {
    const kg = stones && pounds ? convertWeightToKg(stones, pounds) : 0;
    setWeightKilograms(kg.toFixed(1));
    validateWeight();
  };

  const updateWeightInImperial = (kg) => {
    const [stones, pounds] = kg
      ? convertWeightToStonesAndPounds(parseFloat(kg))
      : [0, 0];
    setWeightStones(stones);
    setWeightPounds(pounds);
    validateWeight();
  };

  // Calculate BMI
  const calculateBMI = (heightCm, weightKg) => {
    if (heightCm === 0 || weightKg === 0) return 0;
    const heightM = heightCm / 100;
    return weightKg / (heightM * heightM);
  };

  // Determine BMI category
  const getBmiCategory = (bmi) => {
    if (bmi < 18.5) {
      return "Underweight";
    } else if (bmi >= 18.5 && bmi < 25) {
      return "Healthy";
    } else if (bmi >= 25 && bmi < 30) {
      return "Overweight";
    } else {
      return "Obese";
    }
  };

  // Remove "0" on focus if it's the default value
  const handleFocus = (e, setState) => {
    if (e.target.value === "0") {
      setState("");
    }
  };

  // Add "0" if input is empty on blur
  const handleBlur = (e, setState) => {
    if (e.target.value === "" && e.target.value !== "0") {
      setState("0");
    }
  };

  const handleNext = (e) => {

    e.preventDefault();

    // Convert height to centimeters if it's in imperial units
    let finalHeightCm = heightCentimeters;
    if (heightUnit === "imperial") {
      finalHeightCm = convertHeightToCm(heightFeet, heightInches);
    }

    // Convert weight to kilograms if it's in imperial units
    let finalWeightKg = weightKilograms;
    if (weightUnit === "imperial") {
      finalWeightKg = convertWeightToKg(weightStones, weightPounds);
    }

    dispatch(
      setBmiValues({
        heightUnit,
        weightUnit,
        heightCm: finalHeightCm,
        heightFeet,
        heightInches,
        weightKg: finalWeightKg,
        weightStones,
        weightPounds,
        bmiCategory,
        bmiValue,
      })
    );
    
    setFadeClass("fade-out");

    setTimeout(() => {
      if (redirectTo && redirectTo !== "none") {
        navigate(`/${redirectTo}`);
      }
      setIsBmi(false);
      setFadeClass("fade-in");
    }, 500);
  };

  return (
    <form className={`w-form w-form-bmi  ${fadeClass}`}>
      <section className="bmi-section-main my-0">
        <section className="consultation-bmi-section">
          <section className="bmi-header">
            <p className="bmi-title">Let us know a bit about you.</p>
            <p className="bmi-desc metropill" style={{ fontSize: "18px" }}>
              We need your height and weight to calculate your Body Mass Index
              (BMI).
            </p>
          </section>
          <section className="bmi-inputs-height">
            <div className="row">
              <div className="col-4 text-start text-dark">Height</div>
              <div className="col-8 text-end">
                <Switch
                  className="bmi-switch"
                  checkedChildren="ft/in"
                  unCheckedChildren="cm"
                  checked={heightUnit === "imperial"}
                  onChange={handleHeightToggle}
                />
              </div>
            </div>
            {heightUnit === "imperial" ? (
              <div className="row">
                <div className="col-6">
                  <Form.Item
                    validateStatus={
                      heightError && heightFeet === "" ? "error" : ""
                    }
                    help={
                      heightError && heightFeet === ""
                        ? "Please enter feet."
                        : ""
                    }
                  >
                    <div className="bmi-input">
                      <div className="floating-label">
                        <Input
                          placeholder="Feet"
                          type="number"
                          value={heightFeet}
                          onChange={handleFeetChange}
                          // onBlur={() => {
                          //   if (!heightFeet) setHeightError("Please enter feet.");
                          //   else setHeightError("");
                          // }}
                          onKeyDown={handleKeyDown}
                          onFocus={(e) => handleFocus(e, setHeightFeet)}
                          onBlur={(e) => handleBlur(e, setHeightFeet)}
                          min={0}
                          pattern="^\d+(\.\d{1,2})?$"
                        />
                        <label>Feet</label>
                      </div>
                    </div>
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item
                    validateStatus={
                      heightError && heightInches === "" ? "error" : ""
                    }
                    help={
                      heightError && heightInches === ""
                        ? "Please enter inches."
                        : ""
                    }
                  >
                    <div className="bmi-input">
                      <div className="floating-label">
                        <Input
                          placeholder="Inches"
                          type="number"
                          value={heightInches}
                          onChange={handleInchesChange}
                          onKeyDown={handleKeyDown}
                          onFocus={(e) => handleFocus(e, setHeightInches)}
                          onBlur={(e) => handleBlur(e, setHeightInches)}
                          min={0}
                          pattern="^\d+(\.\d{1,2})?$"
                        />
                        <label>Inches</label>
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    validateStatus={
                      heightError && heightCentimeters === "" ? "error" : ""
                    }
                    help={
                      heightError && heightCentimeters === ""
                        ? "Please enter centimeters."
                        : ""
                    }
                  >
                    <div className="bmi-input">
                      <div className="floating-label">
                        <Input
                          placeholder="Centimeters"
                          type="number"
                          value={heightCentimeters}
                          onChange={handleCentimetersChange}
                          onKeyDown={handleKeyDown}
                          onFocus={(e) => handleFocus(e, setHeightCentimeters)}
                          onBlur={(e) => handleBlur(e, setHeightCentimeters)}
                          min={0}
                          pattern="\d*"
                        />
                        <label>Centimeters</label>
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </div>
            )}
            {heightMaxError && <p className="text-danger">{heightMaxError}</p>}
          </section>
          {/* <div className="divider" /> */}
          <section className="bmi-inputs-weight">
            <div className="row">
              <div className="col-4 text-start text-dark">Weight</div>
              <div className="col-8 text-end">
                <Switch
                  className="bmi-switch"
                  checkedChildren="st/lbs"
                  unCheckedChildren="kg"
                  checked={weightUnit === "imperial"}
                  onChange={handleWeightToggle}
                />
              </div>
            </div>
            {weightUnit === "imperial" ? (
              <div className="row">
                <div className="col-6">
                  <Form.Item
                    validateStatus={
                      weightError && weightStones === "" ? "error" : ""
                    }
                    help={
                      weightError && weightStones === ""
                        ? "Please enter stones."
                        : ""
                    }
                  >
                    <div className="bmi-input">
                      <div className="floating-label">
                        <Input
                          placeholder="Stones"
                          type="number"
                          value={weightStones}
                          onChange={handleStonesChange}
                          onKeyDown={handleKeyDown}
                          onFocus={(e) => handleFocus(e, setWeightStones)}
                          onBlur={(e) => handleBlur(e, setWeightStones)}
                          min={0}
                          pattern="\d*"
                        />
                        <label>Stones</label>
                      </div>
                    </div>
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item
                    validateStatus={
                      weightError && weightPounds === "" ? "error" : ""
                    }
                    help={
                      weightError && weightPounds === ""
                        ? "Please enter pounds."
                        : ""
                    }
                  >
                    <div className="bmi-input">
                      <div className="floating-label">
                        <Input
                          placeholder="Pounds"
                          type="number"
                          value={weightPounds}
                          onChange={handlePoundsChange}
                          onKeyDown={handleKeyDown}
                          onFocus={(e) => handleFocus(e, setWeightPounds)}
                          onBlur={(e) => handleBlur(e, setWeightPounds)}
                          min={0}
                          pattern="\d*"
                        />
                        <label>Pounds</label>
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    validateStatus={weightError === "" ? "" : "error"}
                    help={weightError === "" ? "" : weightError}
                  >
                    <div className="bmi-input">
                      <div className="floating-label">
                        <Input
                          placeholder="Kilograms"
                          type="number"
                          value={weightKilograms}
                          onChange={handleKilogramsChange}
                          onKeyDown={handleKeyDown}
                          onFocus={(e) => handleFocus(e, setWeightKilograms)}
                          onBlur={(e) => handleBlur(e, setWeightKilograms)}
                          min={0}
                          pattern="\d*"
                        />
                        <label>Kilograms</label>
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </div>
            )}
            {weightMaxError && <p className="text-danger">{weightMaxError}</p>}
          </section>

          <section className="bmi-result" style={{ marginTop: "10px" }}>
            <section style={{ paddingTop: "30px" }}>
              <p style={{ fontSize: "20px", color: "black" }}>
                BMI = <span className="metropill"> {bmiValue.toFixed(1)} </span>
              </p>
              {bmiCategory && bmiCategory !== null && (
                <p className="bme">
                  Your category is:{" "}
                  <span className="text-dark">{bmiCategory}</span>
                </p>
              )}
              <BmiBar bmiValue={bmiValue} />
            </section>
            {bmiValue === 0 || bmiValue === 0.0 ? (
              <div className="error-section p-30">
                <p
                  className="p-0 m-0 mbb-10"
                  style={{ fontSize: "18px", color: "#ff000096" }}
                >

                </p>
                <p className="p-0 m-0 metropill">
                Your BMI offers a basic insight into your weight category. For tailored advice and professional medical solutions, arrange an online consultation with a myprivatechemist consultant.
                </p>
              </div>
            ) : (
              <>
                {bmiCategory === "Underweight" && (
                  <div className="error-section p-30">
                    <p
                      className="p-0 m-0 mbb-10"
                      style={{ fontSize: "18px", color: "#ff000096" }}
                    >
                    </p>
                    <p className="p-0 m-0 metropill">
                      Your BMI offers a basic insight into your weight category. For tailored advice and professional medical solutions, arrange an online consultation with a Slimn consultant.
                    </p>
                  </div>
                )}
                {bmiCategory === "Healthy" && (
                  <div className="error-section p-30">
                    <p
                      className="p-0 m-0 mbb-10"
                      style={{ fontSize: "18px", color: "#ff000096" }}
                    >
                    </p>
                    <p className="p-0 m-0 metropill">
                    Keeping a balanced weight through proper nutrition and regular exercise can significantly reduce the risk of various health problems.
                    </p>
                  </div>
                )}
                {(bmiCategory === "Overweight" || bmiCategory === "Obese") && (
                  <div className="success-section p-30">
                    <p
                      className="p-0 m-0 mbb-10"
                      style={{ fontSize: "18px", color: "#357550" }}
                    >
                    </p>
                    <p className="p-0 m-0 metropill">Obesity can elevate the risk of developing weight-related health issues. Take the first step towards better health with a detailed assessment—book an online consultation today.</p>
                  </div>
                )}
              </>
            )}
          </section>
          <section className="d-flex justify-content-end">
            {(bmiCategory === "Overweight" ||
              (bmiCategory === "Obese" && bmiValue > 30)) && (
              <button
                className="btn-pink w-20 m-4 btncust-pink"
                onClick={(e) => handleNext(e)}
              >
                <span>Next</span>
              </button>
            )}
          </section>
        </section>
      </section>
    </form>
  );
};

export { Bmi };
