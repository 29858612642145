import React, { useState, useRef } from "react";
import "./index.css";
import { HeaderForm } from "../../pages/consultation/HeaderForm";
import { Link } from "react-router-dom";

const OtpPage = ({ handleVerifyOtp }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    // Allow only a single digit (if the value is a number and length is 1)
    if (value.match(/[0-9]/) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input if current one is filled and not the last input
      if (index < 3 && value !== "") {
        inputRefs.current[index + 1].focus();
      }

      // Verify OTP once all 4 digits are entered
      if (newOtp.every((digit) => digit !== "")) {
        verifyOtp(newOtp.join(""));
      }
    }
  };


  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = ""; 
      setOtp(newOtp); 
      inputRefs.current[index].focus();
    }
  };

  // Handle OTP verification
  const verifyOtp = async (otp) => {
    console.log("Verifying OTP:", otp);
    handleVerifyOtp(otp);
  };

  return (
    <>
      <div className="col-12">
        <form className="w-form fade-in">
          <div className="row g-4">
            <div className="col-12">
              <h5 className="mb-5 text-center">OTP Verification</h5>
              <p className="text-muted text-center">
                Please enter the OTP sent to your email address.
              </p>
            </div>
            <div className="col-lg-12 input-section">
              <div className="input-section-otp">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text" // Change to text to allow editing the digit
                    maxLength={1}
                    className="input_text otp-input"
                    name={`otp${index + 1}`}
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)} // Assign reference
                    style={{ fontFamily: "metropillthin" }}
                  />
                ))}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default OtpPage;
