import React, { useState } from "react";
import "./index.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { post } from "../../../services/ApiService";
import OtpPage from "../../auth/Otp";

const PreConsultationAlert = ({ setIsConsultationEnable, otpRequired, handlePatientByEmail, handleVerifyOtp }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");


  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email.trim()) return;
    handlePatientByEmail(email);
  };

  if (otpRequired) return <OtpPage handleVerifyOtp = {handleVerifyOtp} />

  return (
    <>
      
      <div className="row g-5">
        <div className="col-lg-12">
          <div className="d-flex">
            <h5>Let's Begin Your Weight Loss Journey!</h5>
          </div>
          <p>
            We ask for your email to check if you've had a consultation with us before.
            If you're a returning patient, we'll continue from where you left off.
            If you're new, we'll create a profile to provide personalised guidance.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-12 mt-4 input-section">
            <input
              type="email"
              className="input_text"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={handleChange}
              required
              style={{ fontFamily: "metropillthin" }}
            />
          </div>
        </div>
        <div className="mt-2 p-0 card-footer bg-transparent border-0 d-flex align-items-center justify-content-between gap-2">
          <button
            className={`btncust w-100 activebg ${isLoading ? "btn-disabled" : ""}`}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Please wait..." : "Continue"}
          </button>
        </div>
      </form>
      </div>

    </>
  );
};

export default PreConsultationAlert;
