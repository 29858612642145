import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
  },
  reducers: {
    addToCart(state, action) {
      const product = action.payload;
      if (!state.cartItems) state.cartItems = []; 

      const cartItem = state.cartItems.find((item) => item._id === product._id);

      if (!cartItem) {
        state.cartItems.push({
          ...product,
          quantity: 1,
          cartItemId: uuidv4(),
        });
      } else {
        cartItem.quantity += 1;
      }
    },

    deleteFromCart(state, action) {
      state.cartItems = state.cartItems.filter(
        (item) => item.cartItemId !== action.payload
      );
    },
    deleteFromCartById(state, action) {
      state.cartItems = state.cartItems.filter(
        (item) => item._id !== action.payload
      );
    },

    deleteAllFromCart(state) {
      state.cartItems = [];
    },

    addBulkToCart(state, action) {
      if (!state.cartItems) state.cartItems = [];

      const { products } = action.payload;

      products.forEach((product) => {
        const cartItem = state.cartItems.find((item) => item._id === product._id);

        if (!cartItem) {
          state.cartItems.push({
            ...product,
            quantity: product.quantity || 1,
            cartItemId: uuidv4(),
          });
        } else {
          cartItem.quantity += product.quantity || 1;
        }
      });
    },
  },
});


// ✅ Export actions and reducer
export const { addToCart, deleteFromCart, deleteAllFromCart, addBulkToCart, deleteFromCartById } = cartSlice.actions;
export default cartSlice.reducer;
