import React, { useState, useEffect } from 'react'
import './index.css'
import { Link } from 'react-router-dom';
import DrawerComponent from './Drawer';
import { get } from '../../../services/ApiService';
import TextWithReadMore from '../../../components/TextWithReadMore';

const CardsComponent = ({ selectedServiceType, setSearchValue, searchValue, handleTypeChange, isRecommending }) => {



  return (
    <div className='pt-lg-17 pt-10'>
      <div className='row g-3'>
        <div className='col-lg-12'>
          <div>
            <h4 className='h4-cs mb-7 text-center'>
              {
                !isRecommending ? 'Build My Package' : 'Your Package'
              }

            </h4>
            <p className='fs-18 mb-13 metropill text-black text-center'>
              Explore our full range of biomarkers and profiles to create a personalised blood test package. Click 'Build My Package,' and our system will intelligently group your selected tests into the most suitable and cost-effective combination. This ensures you receive the most comprehensive results while avoiding unnecessary costs.
            </p>
          </div>
          <div>
            {
              !isRecommending &&
              <>
                <div className='mt-10'>
                  <div className="input-group search-grp">
                    <input type="text" className="form-control border-0 bg-transparent"
                      placeholder='Search Here' aria-describedby='searchfield'
                      value={searchValue}
                      onChange={(event) => setSearchValue(event.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" || event.code === "Search" || event.key === "Search") {
                          event.target.blur(); // This will hide the keyboard on mobile
                        }
                      }}
                    />
                    <span className="input-group-text bg-transparent border-0 " id="searchfield">
                      <img src="/assets/icons/search-ic.png" width={20} alt="" />
                    </span>
                  </div>
                </div>
                <ul className="nav nav-pills mb-3 mt-10 filter-tab">
                  <li className="nav-item">
                    <button className={selectedServiceType === 'all' ? "nav-link active" : "nav-link"}
                      onClick={() => handleTypeChange('all')}
                    >
                      <span className='metropill'>All</span>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button className={selectedServiceType === 'biomarker' ? "nav-link active" : "nav-link"}
                      onClick={() => handleTypeChange('biomarker')}
                    >
                      <span className='metropill'>Biomarkers</span>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button className={selectedServiceType === 'profile' ? "nav-link activeg" : "nav-link"}
                      onClick={() => handleTypeChange('profile')}
                    >
                      <span className='metropill'>Profiles</span>
                    </button>
                  </li>
                </ul>
              </>
            }
          </div>

        </div>

        <hr />

      </div>
    </div>


  )
}

export { CardsComponent }