import React, { useState, useEffect, useRef } from "react";
import { post, del } from "../../services/ApiService.js";
import Navbar from "../../components/Navbar/index.jsx";
import { BreadCrumb } from "../../components/Navbar/breadCrumb.js";
import Footer from "../../components/Footer.jsx";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, message, Popconfirm } from "antd";
import {
  BreadCrumbsNew,
  NewFooter,
  NewNavbar,
} from "../../components/index.jsx";
import { useDispatch, useSelector } from "react-redux";
import { deleteFromCart } from "../../redux/slices/CartSlice.js";

const Cart = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);
  const { cartItems } = useSelector((state) => state.cart);
  const isBloodDraw = cartItems.some((item) => item.section === "blood-test");
  const dispatch = useDispatch();

  const breadcrumbs = [
    { label: "Home", path: "https://www.beta.myprivatechemist.com/" },
    { label: "Cart" },
  ];

  const confirmRemove = (cartItemId) => {
    if (cartItemId) {
      dispatch(deleteFromCart(cartItemId));
    }
  };


  const cancel = () => {};

  const calculateTotals = () => {
    
    if (!cartItems || cartItems.length === 0) return { subtotal: 0, total: 0, totalDiscount: 0 };
  
    let totalDiscount = 0;
    let subtotal = 0;
    const FEE = 30;
    
  
    let bloodTestCount = 0; // Counter to track first blood test
  
    cartItems.forEach((item, index) => {
      let discount = 0;
  
      if (item.section === "blood-test") {
        if (bloodTestCount === 0) {
          discount = 0;
          bloodTestCount++; 
        } else {
          discount = 10; 
        }
      }
  
      const itemSubtotal = Number(item.price) || 0;  
      const itemDiscount = Number(discount) || 0;
  
      subtotal += itemSubtotal;
      totalDiscount += itemDiscount;
    });
  
    const total = subtotal - totalDiscount + (isBloodDraw ? FEE : 0);
  
    return { subtotal, total, totalDiscount };
  };

  const { subtotal, total, totalDiscount } = calculateTotals();
  

  return (
    <>
      {/* <Header /> */}
      <main id="content" className="wrapper layout-page">
        <div className="breadcrumnb-img-bg">
          <div className="container">
            <NewNavbar />
            <BreadCrumbsNew breadcrumbs={breadcrumbs} title={"Cart"} />
          </div>
        </div>
        <div className="container">
          {cartItems && cartItems.length > 0 && (
            <>
              <div className="cart" style={{ marginTop: "70px" }}>
                <table className="table cart-table">
                  <thead className="table-header">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Product</th>
                      <th scope="col">Qty</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item, index) => {


                      return (
                        <tr key={index}>
                          <td className="text-center">
                            <Popconfirm
                              title="Delete"
                              description="Are you sure?"
                              onConfirm={() => confirmRemove(item.cartItemId)}
                              onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Link>
                                <img
                                  src="/assets/icons/close-b.png"
                                  width={13}
                                />
                              </Link>
                            </Popconfirm>
                          </td>
                          <td>
                            <div className="product-name">{item.name}</div>
                          </td>
                          <td className="">1</td>
                          <td className="theme-font fs-18">£{item.price}</td>{" "}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row total-row mb-4">
                <div className="col-lg-6 col-md-6 col-sm-12"></div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="cart-totals">
                    <h4>Cart totals</h4>
                    <div className="row total-row">
                      <div className="col-4 totel-left-heading">
                        {" "}
                        <span>Subtotal</span>
                      </div>
                      <div className="col-8 totel-right-content">
                        <span className="fs-18">
                          {" "}
                          £{subtotal}
                        </span>{" "}
                      </div>
                    </div>
                    {isBloodDraw && (
                      <div className="row total-row">
                        <div className="col-4 totel-left-heading">
                          <span>Blood Draw Fee</span>{" "}
                        </div>
                        <div className="col-8 totel-right-content">
                          <span className="fs-18">£ 30</span>{" "}
                        </div>
                      </div>
                    )}
                    <div className="row total-row">
                      <div className="col-4 totel-left-heading">
                        {" "}
                        <span>Discount</span>
                      </div>
                      <div className="col-8 totel-right-content">
                        <span className="fs-18"> - £ {totalDiscount}</span>{" "}
                      </div>
                    </div>
                    <div className="row total-row">
                      <div className="col-4 totel-left-heading">
                        <span>Total</span>{" "}
                      </div>
                      <div className="col-8 totel-right-content">
                        {" "}
                        <b className="text-dark">£ {total}</b>
                      </div>
                    </div>
                    <div className="row text-start">
                      <Link
                        to={"/checkout"}
                        className="btn-show w-100 btn-checkout metropill"
                        style={{ textDecoration: "none" }}
                      >
                        PROCEED TO CHECKOUT
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {cartItems.length == 0 && (
            <div
              className="row p-5"
              style={{ marginTop: "100px", marginBottom: "100px" }}
            >
              <div className="col-lg-2 col-12"></div>
              <div className="col-lg-4 col-12 ">
                <img
                  src="/assets/images/empty-cart.png"
                  alt=""
                  style={{ width: "250px" }}
                />
              </div>
              <div className="col-lg-4 col-12">
                <div>
                  <h2 className="mt-lg-0 mt-10 mb-10">Sorry...</h2>
                  <p className="error-desc metropill mb-12">
                    Your cart is currently empty. We invite you to explore our
                    available services to find the perfect option for you.
                  </p>
                  <Link
                    to={"https://www.beta.myprivatechemist.com"}
                    className="btncust btn-pad w-auto d-inline-block text-decoration-none metropill"
                  >
                    Explore
                  </Link>
                </div>
              </div>
              <div className="col-lg-2 col-12"></div>
            </div>
          )}
        </div>

        <NewFooter />
      </main>
    </>
  );
};

export default Cart;
