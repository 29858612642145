import React from 'react';
import { HeaderForm } from '../../pages/consultation/HeaderForm';
import './index.css';

const ConsultationCheck = ({ consultation, setConsultation, onNext }) => {
  const handleNext = () => {
    if (consultation === 'yes') {
      // Navigate to NoticeNewStep
      onNext('notice');
    } else {
      // Navigate to SignUpForm
      onNext('signup');
    }
  };

  return (
    <div className="min-height">
      <HeaderForm />
      <div className="justify-content-md-center row g-3 m-0">
        <div className="col-12">
          <form className="w-form fade-in">
            <div className="row g-5">
              <div className="col-lg-12">
                <h5 className="mb-5">Please confirm if you have had a consultation with us before?</h5>
                <p>If you have previously had a consultation with us, there is no need to provide it again. We will use the details from your previous consultation for this order.</p>
              </div>
              <div className="col-lg-12">
                <input 
                  type="radio" 
                  id="option-yes" 
                  name="consultation" 
                  value="yes"
                  checked={consultation === 'yes'}
                  onChange={(e) => setConsultation(e.target.value)} 
                  className='d-none'
                />
                <label 
                  className={`padc bullet w-100 d-flex align-items-center gap-3 rounded-3 ${
                    consultation === 'yes' ? 'active-check' : ''
                  }`}
                  htmlFor="option-yes"
                >
                  <div className="label-cs"></div>
                  <span className="metropill">Yes, I have consulted before</span>
                </label>
              </div>
              <div className="col-lg-12">
                <input 
                  type="radio" 
                  id="option-no" 
                  name="consultation" 
                  value="no"
                  checked={consultation === 'no'}
                  onChange={(e) => setConsultation(e.target.value)} 
                  className='d-none'
                />
                <label 
                  className={`padc bullet w-100 d-flex align-items-center gap-3 rounded-3 ${
                    consultation === 'no' ? 'active-check' : ''
                  }`}
                  htmlFor="option-no"
                >
                  <div className="label-cs"></div>
                  <span className="metropill">No, start a new consultation</span>
                </label>
              </div>
            </div>
            <div className="mt-30 p-0 card-footer bg-transparent border-0 d-flex align-items-center justify-content-between gap-2">
              <button 
                className="btn-disables w-auto activebg btncust w-auto activebg" 
                type="button" 
                disabled={!consultation} 
                style={{ cursor: consultation ? 'pointer' : 'not-allowed' }}
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConsultationCheck; 