import React, { useState } from "react";
import "./index.css";
import { HeaderForm } from "../../pages/consultation/HeaderForm";
import { post } from "../../services/ApiService";
import { Link, useParams, useNavigate } from "react-router-dom";
import { notification } from "antd";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams(); // Assuming the token is passed via URL params
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors };

    switch (name) {
      case "newPassword":
        newErrors.newPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value)
          ? ""
          : "Password must be at least 8 characters with one letter & one number.";
        break;

      case "confirmPassword":
        newErrors.confirmPassword = value === formData.newPassword ? "" : "Passwords do not match.";
        break;

      default:
        break;
    }

    setErrors(newErrors);
  };

  const isFormValid = () => {
    return Object.values(errors).every((err) => err === "") &&
           Object.values(formData).every((val) => val !== "");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formData.newPassword !== formData.confirmPassword) {
      notification.error({
        message: "Error",
        description: "Passwords do not match. Please try again.",
        placement: "topRight",
      });
      setLoading(false);
      return;
    }

    try {
      const response = await post("api/auth/reset-password", {
        token,
        newPassword: formData.newPassword,
      });

      if (response.success) {
        notification.success({
          message: "Password Reset Successful",
          description: "Your password has been successfully reset.",
          placement: "topRight",
        });
        navigate('/login');
      } else {
        notification.error({
          message: "Error",
          description: response.error || "Failed to reset password. Please try again.",
          placement: "topRight",
        });
      }
    } catch (err) {
      notification.error({
        message: "Error",
        description: "An error occurred. Please try again.",
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const onBack = () => {
    window.history.back();
  };

  return (
    <div className="min-height">
      <HeaderForm />
      <div className="justify-content-md-center row g-3 m-0">
        <div className="col-12">
          <form className="w-form fade-in" onSubmit={onSubmit}>
            <div className="row g-4">
              <div className="col-12">
                <h5 className="mb-5">Reset Password</h5>
                <p className="text-muted">
                  Enter your new password below to reset your account password.
                </p>
              </div>
              <div className="col-lg-12">
                <input
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                  required
                  style={{ fontFamily: "metropillthin" }}
                />
                {errors.newPassword && <p className="text-danger error-text">{errors.newPassword}</p>}
              </div>
              <div className="col-lg-12">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                  style={{ fontFamily: "metropillthin" }}
                />
                {errors.confirmPassword && <p className="text-danger error-text">{errors.confirmPassword}</p>}
              </div>
              <div className="col-12 d-flex justify-content-between align-items-center">
                <button
                  type="button"
                  className="border-0 bg-transparent"
                  onClick={onBack}
                >
                  <img src="/assets/images/backicon.jpg" width={35} alt="Back" />
                </button>
                <button
                  type="submit"
                  className={`${isFormValid() ? 'btncust activebg cursor-pointer' : 'btn-gray'} w-auto`}
                  disabled={!isFormValid() || loading}
                >
                  {loading ? "Please wait..." : "Reset Password"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
