import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  answers: [],  
  explanations: [], 
  formId: null, 
  type: null,
  deliveryOption:null,
  attachments:[],
  isBefore:null
};

const consultationSlice = createSlice({
  name: "consultation",
  initialState,
  reducers: {
   
    setConsultationData: (state, action) => {
      const { answers, explanations, formId } = action.payload;
      state.answers = answers;
      state.explanations = explanations;
      state.formId = formId;
    },

    saveConsultation: (state, action) => {
      const { answers, explanations, formId, type, deliveryOption, attachments, isBefore } = action.payload;
      state.answers = answers;
      state.explanations = explanations;
      state.formId = formId;
      state.type = type;
      state.deliveryOption = deliveryOption;
      state.attachments = attachments;
      state.isBefore = isBefore;
    },
    
    resetConsultation: (state) => {
      state.answers = [];
      state.explanations = [];
      state.formId = null;
      state.type= null;
      state.deliveryOption= null;
      state.attachments= [];
    },
  },
});

// Export actions for use in dispatching
export const {saveConsultation, setConsultationData, resetConsultation } = consultationSlice.actions;

// Export the reducer
export default consultationSlice.reducer;
