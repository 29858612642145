import React from 'react'
import { Form } from 'antd'
import { MyInput } from '../../Form';
import { NavLink } from 'react-router-dom';

const SendMessageForm = () => {

    const [form] = Form.useForm();

  return (
    <div className='pad-t-5 pb-15'>
        <div className='row gap-c'>
            <div className='col-lg-12'>
                <div className='text-center'>
                    <h1 className="fs-h1-mbl">
                        Still unsure of what test you need
                    </h1>
                    <p className="fs-18 metropill text-black mb-4">
                        contact one of our healthcare staff to help you choose the right test for you.
                    </p>
                    <NavLink to={'tel:0203 987 9202'} className='text-decoration-none text-black'>
                        <div className='d-flex align-items-center gap-2 justify-content-center'>
                            <img src='/assets/icons/call.png' width={15} />
                            <span className='metropill fw-normal'>0203 987 9202</span>
                        </div>
                    </NavLink>
                </div>
            </div>    
            <div className='col-lg-12'>    
                <Form
                    layout='vertical'
                    form={form}
                >   
                    <div className='row'>
                        <div className='col-md-6'>
                            <MyInput 
                                name='name'
                                required
                                message='Please enter name'
                                placeholder='Name *'
                            />
                        </div>
                        <div className='col-md-6'>
                            <MyInput 
                                name='email'
                                required
                                message='Please enter email'
                                placeholder='Email *'
                            />
                        </div>
                        <div className='col-md-12'>
                            <MyInput 
                                textArea
                                name='note'
                                rows={6}
                            />
                        </div>
                        <div className='col-md-12'>
                            <button className='btncust btn-pad w-auto metropill'>
                                SEND YOUR MESSAGE
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
  )
}

export {SendMessageForm}