import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { saveVaccineConsultation } from "../../redux/slices/vaccineConsultationSlice";

function VaccineAssessmentModal({ showConsultation, handleClose, form, questions, setIsLoading }) {
  const dispatch = useDispatch();
  const vaccineConsultation = useSelector(state => state.vaccineConsultation);
  const [answers, setAnswers] = useState(vaccineConsultation.answers || []);
  const [explanations, setExplanations] = useState(vaccineConsultation.explanations || []);
  const [errors, setErrors] = useState({});


  const handleAnswer = (questionId, optionId, text) => {
    const question = questions.find(q => q._id === questionId);
    if (!question) return;

    const current = questions.findIndex(q => q._id === questionId);

    setErrors((prev) => {
      const updatedErrors = { ...prev };
      delete updatedErrors[current];
      return updatedErrors;
    });

    const newAnswer = answers[current]
      ? { ...answers[current] }
      : {
          _id: question._id,
          options: [],
          description: "",
        };

    // Handle answers based on input type
    if (question.inputType === "radio" || question.inputType === "select") {
      newAnswer.options = [optionId];
    } else if (question.inputType === "checkbox") {
      const currentOptions = newAnswer.options || [];
      const isExclusive = question.options.find(
        (opt) => opt._id === optionId
      )?.exclusive;

      if (isExclusive) {
        newAnswer.options = [optionId];
      } else {
        const filteredOptions = currentOptions.filter(
          (opt) =>
            !question.options.find((option) => option._id === opt)?.exclusive
        );

        if (currentOptions.includes(optionId)) {
          newAnswer.options = filteredOptions.filter(
            (opt) => opt !== optionId
          );
        } else {
          newAnswer.options = [...filteredOptions, optionId];
        }
      }
    } else if (question.inputType === "text") {
      newAnswer.description = text;
    }

    // Update answers state
    setAnswers((prev) => {
      const updatedAnswers = [...prev];
      updatedAnswers[current] = newAnswer;
      return updatedAnswers;
    });

    const hasCorrectOptions = question.options.some(
      (option) => option.isCorrectAnswer === true
    );

    if (hasCorrectOptions) {
      const incorrectOption = newAnswer.options.find(
        (optId) =>
          question.options.find((option) => option._id === optId)
            ?.isCorrectAnswer === false
      );
  
      if (incorrectOption) {
        let error = question?.warning
          ? question.warning
          : "Your answer is incorrect. Please check your selection.";
        setErrors((prev) => ({
          ...prev,
          [current]: error,
        }));
      } else {
        setErrors((prev) => {
          const updatedErr = { ...prev };
          delete updatedErr[current];
          return updatedErr;
        });
      }
    } else {
      setErrors((prev) => {
        const updatedErr = { ...prev };
        delete updatedErr[current];
        return updatedErr;
      });
    }

    if (question.inputType !== "text") {
      const requiresExplanation = newAnswer.options.some(
        (optId) =>
          question.options.find((option) => option._id === optId)
            ?.requireExplanation
      );
  
      setExplanations((prev) => {
        let updatedExplanations = [...prev];
  
        if (requiresExplanation) {
          const existingExplanation = updatedExplanations.find(
            (ex) => ex._id === question._id
          );
  
          if (!existingExplanation) {
            updatedExplanations.push({ _id: question._id, text: "" });
          }
        } else {
          updatedExplanations = updatedExplanations.filter(
            (ex) => ex._id !== question._id
          );
        }
  
        return updatedExplanations;
      });
    }
  };

  const handleExplanationChange = (questionId, text) => {
    setExplanations(prev => {
      const updated = [...prev];
      const existingIndex = updated.findIndex(ex => ex._id === questionId);
      
      if (existingIndex >= 0) {
        updated[existingIndex] = { ...updated[existingIndex], text };
      } else {
        updated.push({ _id: questionId, text });
      }
      
      return updated;
    });
  };

  const renderQuestionInput = (question, index) => {
    const answer = answers[index] || { options: [], description: "" };
    const questionError = errors[index];
    
    switch (question.inputType) {
      case "radio":
        return (
          <div className="mb-10">
            <h6>{question.title}</h6>
            <div>
              {question.options.map((option) => (
                <div className="form-checks mt-3" key={option._id}>
                  <input
                    type="radio"
                    name={`question-${question._id}`}
                    id={`option-${option._id}`}
                    className="input-section op-0"
                    checked={answer.options.includes(option._id)}
                    onChange={() => handleAnswer(question._id, option._id,'')}
                  />
                  <label className={`padc bullet w-100 d-flex align-items-center gap-3 ${answer.options.includes(option._id) ? 'active-check' : ''}`}>
                    <div className={`label-cs`}></div>
                    <span className="metropill">{option.optionText}</span>
                  </label>
                </div>
              ))}
            </div>
            {questionError && <div className="text-danger mt-2">{renderErrorSection()}</div>}
          </div>
        );
      
      case "checkbox":
        return (
          <div className="mb-10">
            <h6>{question.title}</h6>
            <div>
              {question.options.map((option) => (
                <div className="form-checks mt-3" key={option._id}>
                  <input
                    type="checkbox"
                    name={`question-${question._id}`}
                    id={`option-${option._id}`}
                    className="input-section op-0"
                    checked={answer.options.includes(option._id)}
                    onChange={() => handleAnswer(question._id, option._id,'')}
                  />
                  <label className={`padc bullet w-100 d-flex align-items-center gap-3 ${answer.options.includes(option._id) ? 'active-check' : ''}`}>
                    <div className={`label-check flex-shrink-0`}></div>
                    <span className="metropill">{option.optionText}</span>
                  </label>
                </div>
              ))}
            </div>
            {questionError && <div className="text-danger mt-2">{renderErrorSection()}</div>}
          </div>
        );
      
      case "text":
        return (
          <div className="mb-10">
            <h6>{question.title}</h6>
            <input
              type="text"
              className="input_text metropill"
              placeholder={question.title}
              value={answer.description || ""}
              onChange={(e) => handleAnswer(question._id, null, e.target.value)}
            />
          </div>
        );
      
      case "select":
        return (
          <div className="mb-10">
            <h6>{question.title}</h6>
            <select
              className="form-select metropill"
              value={answer.options[0] || ""}
              onChange={(e) => handleAnswer(question._id, e.target.value)}
            >
              <option value="">Select an option</option>
              {question.options.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.optionText}
                </option>
              ))}
            </select>
            {questionError && <div className="text-danger mt-2">{renderErrorSection()}</div>}
          </div>
        );
      
      default:
        return null;
    }
  };

  const renderExplanationField = (question, index) => {
    if (question.inputType === "text") return null;
    
    const answer = answers[index] || { options: [] };
    const requiresExplanation = question.options.some(
      opt => answer.options.includes(opt._id) && opt.requireExplanation
    );
    
    if (!requiresExplanation) return null;
    
    const explanation = explanations.find(ex => ex._id === question._id) || { text: "" };
    
    return (
      <div className="mb-10">
        <label className="form-label ">Please specify</label>
        <textarea
          className="input_text metropill"
          rows="3"
          value={explanation.text}
          placeholder="Type here..."
          onChange={(e) => handleExplanationChange(question._id, e.target.value)}
        ></textarea>
      </div>
    );
  };

  const renderErrorSection = () => {
    return (
      <div className="alert alert-danger mt-3">
        <h5>This vaccine may not be suitable for you</h5>
        <p>Please contact us for further advice:</p>
        <ul className="mb-0">
          <li>Email: <a href="mailto:info@myprivatechemist.com">info@myprivatechemist.com</a></li>
          <li>Phone: <a href="tel:02039879202">020 3987 9202</a></li>
        </ul>
      </div>
    );
  };

  const isNextButtonDisabled = () => {
    return questions.some((question, index) => {
      const currentAnswer = answers[index];
  
      if (!question) {
        console.error(`Question at index ${index} not found`);
        return true;
      }
  
      const isAnswered =
        ((question.inputType === "radio" ||
          question.inputType === "select" ||
          question.inputType === "checkbox") &&
          Array.isArray(currentAnswer?.options) &&
          currentAnswer.options.length > 0) ||
        (question.inputType === "text" &&
          typeof currentAnswer?.description === "string" &&
          currentAnswer.description.trim().length > 0);
  
      // Check if the question has correct options
      const hasCorrectOptions = question.options?.some(
        (option) => option.isCorrectAnswer === true
      );
  
      // Check if explanation is required for selected options
      const requiresExplanation =
        question.inputType !== "text" &&
        currentAnswer?.options?.some((optionId) =>
          question.options.find((option) => option._id === optionId)
            ?.requireExplanation
        );
  
      // Check if explanation is provided
      const hasExplanation = explanations?.some(
        (explanation) =>
          explanation._id === question._id &&
          typeof explanation.text === "string" &&
          explanation.text.trim().length > 0
      );
  
      // If an explanation is required but not provided, disable Next
      if (requiresExplanation && !hasExplanation) {
        return true;
      }
  
      // Check for incorrect selections
      let hasIncorrectOption = false;
      if (hasCorrectOptions) {
        hasIncorrectOption = currentAnswer?.options?.some(
          (optionId) =>
            question.options.find((option) => option._id === optionId)
              ?.isCorrectAnswer === false
        );
      }
      return !isAnswered || hasIncorrectOption;
    });
  };

  const handleConsultationComplete = () => {
    setIsLoading(true); 
    dispatch(
      saveVaccineConsultation({
        answers: answers,
        explanations: explanations,
        formId: form?._id,
      })
    );

    setTimeout(() => {
      handleClose(); 
      setIsLoading(false); 
    }, 500); 
  };
  
  

  return (
    <Modal
      show={showConsultation}
      className=""
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Travel vaccine risk assessment</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "500px", overflowY: "auto", padding: "" }}>
        <div >
        {questions.map((question, index) => {
          return (
            <React.Fragment key={question._id}>
              {renderQuestionInput(question, index)}
              {renderExplanationField(question, index)}
            </React.Fragment>
          );
        })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btncust w-auto activebg cursor-pointer btncust-light me-5"
          onClick={handleClose}
        >
          Close
        </button>

        <button
          className={`w-auto activebg cursor-pointer m-0 ${isNextButtonDisabled() ? 'btn-disable' : 'btncust'}`}
          onClick={() => {
            handleConsultationComplete();
          }}
          disabled={isNextButtonDisabled()}
          style={{cursor:isNextButtonDisabled() ? 'not-allowed':'pointer'}}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default VaccineAssessmentModal;